import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";
import { BiCategory } from "react-icons/bi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { LuUserSquare2 } from "react-icons/lu";
import { AiTwotoneShopping } from "react-icons/ai";
import { FaFingerprint } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { TextField } from "@mui/material";
import StepComponent from "./StepComponent";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  seller_Update,
  update_registation_status,
  update_seller_details,
} from "../../../redux/Athentication/AuthSlice";

const Step_three = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const { seller_details } = useSelector((state) => state.authentication);

  const [address, setaddress] = useState("");
  const [addressError, setaddressError] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);
  const [address_Preview, setaddress_Preview] = useState(false);
  const [registationErrorr, setregistationErrorr] = useState("");

  useEffect(() => {
    if (buttonPress === true) {
      if (address === "") {
        setaddressError("Please enter your shop address");
      } else {
        setaddressError("");
      }
    }
  }, [buttonPress, address]);

  const updateSellerClick = async (e) => {
    e.preventDefault();

    setbuttonPress(true);

    if (address === "") {
      setaddressError("Please enter your shop address");
    } else {
      setaddressError("");
    }

    if (address !== "") {
      const formData = {
        shopAddress: address,
        shop_form_status: 3,
        id: seller_details._id,
      };

      const update_seller = await dispatch(seller_Update(formData));
      if (update_seller.payload.success) {
        dispatch(update_registation_status(0));
        dispatch(update_seller_details(""));
        navigate("/login");
        setregistationErrorr(
          "Seller registation process is done successfull. Please contact for admin approvel @8447282606"
        );
        addToast("Seller registation process done successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
      }
    }
  };

  return (
    <div
      style={{
        margin: "auto",
        maxWidth: "800px",
        paddingLeft: "15px",
        paddingRight: "15px",
        marginTop: "15px",
      }}
      className="mobi_margin_only"
    >
      <div className="mobile_logo_login">
        <div >
          <img onClick={()=>navigate("/")} src="/assets/images/ew10.png" />
        </div>
        <h4>Seller Panel</h4>
      </div>
      <StepComponent />
      <div>
        <h3 style={{ fontSize: "20px" }}>Hello, {seller_details.shopName}</h3>
        <h5>Mobile & Email Verification</h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <IoIosPhonePortrait color="#000" />
            </div>
            <span
              style={{ color: "#000", fontWeight: "700", paddingLeft: "10px" }}
            >
              +91 {seller_details.mobile}
            </span>
          </div>
          <div className="verifiedcontainer">
            <h6 style={{ color: "#009900" }}>Verification</h6>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div>
              <HiOutlineMailOpen color="#000" />
            </div>
            <span
              style={{ color: "#000", fontWeight: "400", paddingLeft: "10px" }}
            >
              {seller_details.email}
            </span>
          </div>
          <div className="verifiedcontainer">
            <h6 style={{ color: "#009900" }}>Verification</h6>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: "20px",
            borderTop: "1px solid #ccc",
            paddingTop: "10px",
          }}
        >
          <h5 className="looktext3">ID Verification</h5>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <LuUserSquare2 color="#000" />
          </div>
          <span
            style={{ color: "#000", fontWeight: "700", paddingLeft: "10px" }}
          >
            {seller_details.shopGst}
          </span>
        </div>
        <div className="verifiedcontainer">
          <h6 style={{ color: "#009900" }}>Verification</h6>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: "20px",
            borderTop: "1px solid #ccc",
            paddingTop: "10px",
          }}
        >
          <h5 className="looktext3">Shop Display Name</h5>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <LuUserSquare2 color="#000" />
          </div>
          <span
            style={{ color: "#000", fontWeight: "700", paddingLeft: "10px" }}
          >
            {seller_details.shopDisplayName}
          </span>
        </div>
        <div className="verifiedcontainer">
          <h6 style={{ color: "#009900" }}>Verification</h6>
        </div>
      </div>
      <div>
        <h5 style={{ fontSize: "16px" }}>Business Details</h5>
        <div style={{ marginTop: "10px" }}>
          <TextField
            label="shop Address *"
            className="textfield"
            type="text"
            id="text"
            error={addressError !== "" ? true : false}
            helperText={addressError}
            style={{ width: "100%" }}
            value={address}
            onChange={(e) => setaddress(e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
        </div>
        {/* <div style={{ display: "flex" }}>
          <AiTwotoneShopping color="#000" style={{ paddingTop: "20px" }} />
          <div style={{ maxWidth: "150px", paddingLeft: "10px" }}>
            <p>Business Name Ajit Kumar</p>
            <p style={{ color: "rgb(0, 102, 204)" }}>Edit</p>
          </div>
        </div> */}
      </div>

      <div>
        {seller_details.shop_form_status === 3 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCheckCircle color="green" />
            <h6
              style={{ color: "green", fontWeight: "600", paddingLeft: "5px" }}
            >
              Seller registation process is done successfull. Please contact for
              admin approvel @8447282606
            </h6>
          </div>
        )}
      </div>

      <div>
        {seller_details.shop_form_status !== 3 && (
          <div
            className="submitcontainer"
            onClick={(e) => updateSellerClick(e)}
            style={{
              cursor: "pointer",
            }}
          >
            <span className="submittext"> Submit </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step_three;
