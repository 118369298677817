// Redux action to save data to localStorage
export const saveToLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Could not store data in localStorage', error);
    }
};

// Redux action to load data from localStorage
export const loadFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) return undefined;
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error('Could not load data from localStorage', error);
        return undefined;
    }
};
