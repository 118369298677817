import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BeautySupercat from "../supercategory/BeautySupercat";
import GrocerySupercat from "../supercategory/GrocerySupercat";
import MedicienSupercat from "../supercategory/MedicienSupercat";
import HomeneedsSupercategory from "../supercategory/HomeneedsSupercategory";
import ClothingSuperCategory from "../supercategory/ClothingSuperCategory";
import ElectronicSupercategory from "../supercategory/ElectronicSupercategory";
import FootwearSupercategory from "../supercategory/FootwearSupercategory";
import GamesandToysSuperCategory from "../supercategory/GamesandToysSuperCategory";

const ProductDiscription = ({
  get_product_description_data,
  product_description_buttonPress,
  supercategory,
  resetield,
}) => {
  const renderSwitch = (param) => {
    switch (param) {
      case "Clothing": {
        return (
          <>
            <ClothingSuperCategory
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Beauty": {
        return (
          <>
            <BeautySupercat
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Electronics": {
        return (
          <>
            <ElectronicSupercategory
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Grocery": {
        return (
          <>
            <GrocerySupercat
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Medicines": {
        return (
          <>
            <MedicienSupercat
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Home and furniture": {
        return (
          <>
            <HomeneedsSupercategory
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }
      case "Footwear": {
        return (
          <>
            <FootwearSupercategory
               getPropDetails={get_product_description_data}
               buttonPress={product_description_buttonPress}
               resetield={resetield}
            />
          </>
        );
      }
      case "Toys & games": {
        return (

          <>
            <GamesandToysSuperCategory
              getPropDetails={get_product_description_data}
              buttonPress={product_description_buttonPress}
              resetield={resetield}
            />
          </>
        );
      }

      default:
        return <>{/* <ClothingSupercat /> */}</>;
    }
  };
  return <>{renderSwitch(supercategory)}</>;
};

export default ProductDiscription;
