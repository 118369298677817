import React from "react";
import All_Sections from "../component/seller_home_layout/All_Sections";
import FooterRegPart from "./returnOrder/FooterRegPart";
import Footerseller from "./footer/Footerseller";

const Seller_Home = () => {
  return (
    <>
      <All_Sections />
      <FooterRegPart />
      <Footerseller/>
    </>
  );
};

export default Seller_Home;
