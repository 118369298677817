import "./App.css";
import "./css/Home.css";
import "./css/Listing_Management.css";
import "./css/Seller_Home.css";
import "./css/Dashbord.css";
import "./css/Add_product.css";
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  useLocation,
} from "react-router-dom";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Seller_Registation from "./pages/registation/Seller_Registation";
import Seller_login from "./pages/Seller_login";
import Product_Listing_Management from "./pages/product_listing/Product_Listing_Management";
import Dashbord from "./pages/Home/Dashbord";
import Main_Layout from "./component/layout/Main_Layout";
import Seller_Home_page from "./component/seller_home_layout/Seller_Home_page";
import Seller_Home from "./pages/Seller_Home";
import AddProduct_Page from "./pages/product_listing/AddProduct_Page";
import AddProduct_page2 from "./pages/product_listing/components/AddProduct_page2";
import Active_Orders from "./pages/Active_Orders";
import Add_Products from "./pages/Add_Products";

import {
  getBooked_orders,
  getCancelled_orders,
  getDeliveried_orders,
  getDispached_orders,
  getOut_for_delivery_orders,
  getPacked_orders,
  get_seller_earn
} from "./redux/order/OrderSlice";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getCategorytag } from "./redux/categoryTags/CategoryTagsSlice";
import { getProduct } from "./redux/product/productSlice";
import OrderDetails from "./pages/OrderDetails";
import ReturnOrders from "./pages/ReturnOrders";
import ApprovadPage from "./pages/returnOrder/ApprovadPage";
import SalesReport from "./pages/SalesReport";
import Seller_OTP from "./pages/Seller_OTP";
import AddCategory from "./pages/category/AddCategory";
import AddSubCategory from "./pages/subCategory/AddSubCategory";
import AddCategoryTags from "./pages/category_tag/AddCategoryTags";

function App() {

  const dispatch = useDispatch();

  const { isAuth, login_Data } = useSelector((store) => store.authentication);
  const { producttotal, isproducttotal_Available, productLoading } = useSelector((store) => store.product);
  const {
    booked_orders,
    isbooked_ordersLoading,
    isbooked_orders_Available,
    packed_orders,
    ispacked_ordersLoading,
    ispacked_orders_Available,
    dispached_orders,
    isdispached_ordersLoading,
    isdispached_orders_Available,
    out_for_delivery_orders,
    isout_for_delivery_ordersLoading,
    isout_for_delivery_orders_Available,
    deliveried_orders,
    isdeliveried_ordersLoading,
    isdeliveried_orders_Available,
    cancelled_orders,
    iscancelled_ordersLoading,
    iscancelled_orders_Available,
  } = useSelector((store) => store.order);

  useEffect(() => {
    if (isAuth === true) {
      // if (deliveried_orders.length > 0) {
      //   dispatch(get_seller_earn());
      // }
      if (!isbooked_orders_Available || booked_orders.length === 0) {
        dispatch(getBooked_orders(login_Data.shopId));
      }
      if (!ispacked_orders_Available || packed_orders.length === 0) {
        dispatch(getPacked_orders(login_Data.shopId));
      }
      if (!isdispached_orders_Available || dispached_orders.length === 0) {
        dispatch(getDispached_orders(login_Data.shopId));
      }
      if (!isout_for_delivery_orders_Available || out_for_delivery_orders.length === 0) {
        dispatch(getOut_for_delivery_orders(login_Data.shopId));
      }
      if (!isdeliveried_orders_Available || deliveried_orders.length === 0) {
        dispatch(getDeliveried_orders(login_Data.shopId));
      }

      if (!iscancelled_orders_Available || cancelled_orders.length === 0) {
        dispatch(getCancelled_orders(login_Data.shopId));
      }
      if (!isproducttotal_Available || producttotal.length === 0) {
        dispatch(getProduct(login_Data.shopId));
      }
    }
  }, [isAuth])

  useEffect(() => {
    if (isAuth === true) {
      const timeout = setTimeout(() => {
        if (isbooked_ordersLoading) {
          dispatch(getBooked_orders(login_Data.shopId));
        }
        if (ispacked_ordersLoading) {
          dispatch(getPacked_orders(login_Data.shopId));
        }
        if (isdispached_ordersLoading) {
          dispatch(getDispached_orders(login_Data.shopId));
        }
        if (isout_for_delivery_ordersLoading) {
          dispatch(getOut_for_delivery_orders(login_Data.shopId));
        }
        if (isdeliveried_ordersLoading) {
          dispatch(getDeliveried_orders(login_Data.shopId));
          // dispatch(get_seller_earn());
        }
        if (iscancelled_ordersLoading) {
          dispatch(getCancelled_orders(login_Data.shopId));
        }
        if (productLoading) {
          dispatch(getProduct(login_Data.shopId));
        }
      }, 20000);
    }
    dispatch(getSuperCategory())
    dispatch(getCategory())
    dispatch(getSubCategory())
    dispatch(getBrand())
    dispatch(getCategorytag())

  }, [isAuth])



  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/registation" element={<Seller_Registation />} />
          <Route path="/login" element={<Seller_login />} />
          <Route path="/otp" element={<Seller_OTP />} />
          <Route path="/" exact element={<Seller_Home_page />}>
            <Route index element={<Seller_Home />} />
          </Route>
          <Route path="/seller" exact element={<Main_Layout />}>
            <Route index element={<Dashbord />} />
            <Route
              path="/seller/my-listings"
              element={<Product_Listing_Management />}
            />
            <Route
              path="/seller/new-product"
              element={<Add_Products />}
            />
            {/* --------------orders----------- */}
            <Route
              path="/seller/active-orders"
              element={<Active_Orders />}
            />



            <Route
              path="/seller/return-orders"
              element={<ReturnOrders />}
            />
            <Route
              path="/seller/order-details/:orderid"
              element={<OrderDetails />}
            />

            {/* --------------reports----------- */}
            <Route
              path="/seller/reports/sales-report"
              element={<SalesReport />}
            />

            {/* --------------category----------- */}
            <Route
              path="/seller/category/add-category"
              element={<AddCategory />}
            />

            {/* --------------sub category----------- */}
            <Route
              path="/seller/add-sub-category"
              element={<AddSubCategory />}
            />

            {/* --------------category tag----------- */}
            <Route
              path="/seller/add-category-tags"
              element={<AddCategoryTags />}
            />
          </Route>
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
