import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  seller_orders: localStorage.getItem("seller_orders")
    ? JSON.parse(localStorage.getItem("seller_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  isSeller_ordersLoading: true,
  isSeller_orders_Available: localStorage.getItem("seller_orders")
    ? true
    : false,

  booked_orders: localStorage.getItem("booked_orders")
    ? JSON.parse(localStorage.getItem("booked_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Booked_orders: 0,

  isbooked_ordersLoading: true,
  isbooked_orders_Available: localStorage.getItem("booked_orders")
    ? true
    : false,

  packed_orders: localStorage.getItem("packed_orders")
    ? JSON.parse(localStorage.getItem("packed_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Packed_orders: 0,

  ispacked_ordersLoading: true,
  ispacked_orders_Available: localStorage.getItem("packed_orders")
    ? true
    : false,

  dispached_orders: localStorage.getItem("dispached_orders")
    ? JSON.parse(localStorage.getItem("dispached_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Dispached_orders: 0,

  isdispached_ordersLoading: true,
  isdispached_orders_Available: localStorage.getItem("dispached_orders")
    ? true
    : false,

  out_for_delivery_orders: localStorage.getItem("out_for_delivery_orders")
    ? JSON.parse(localStorage.getItem("out_for_delivery_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Out_for_delivery_orders: 0,

  isout_for_delivery_ordersLoading: true,
  isout_for_delivery_orders_Available: localStorage.getItem(
    "out_for_delivery_orders"
  )
    ? true
    : false,

  deliveried_orders: localStorage.getItem("deliveried_orders")
    ? JSON.parse(localStorage.getItem("deliveried_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Deliveried_orders: 0,
  ews_commission: 6,
  total_seller_earn: 0,

  isdeliveried_ordersLoading: true,
  isdeliveried_orders_Available: localStorage.getItem("deliveried_orders")
    ? true
    : false,

  cancelled_orders: localStorage.getItem("cancelled_orders")
    ? JSON.parse(localStorage.getItem("cancelled_orders")).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
    : [],

  total_Cancelled_orders: 0,

  iscancelled_ordersLoading: true,
  iscancelled_orders_Available: localStorage.getItem("cancelled_orders")
    ? true
    : false,

  active_Order_steps: 0,
};

export const getSeller_Orders = createAsyncThunk(
  "order/getSeller_Orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderbyshopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getBooked_orders = createAsyncThunk(
  "order/getBooked_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderrecieved-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getPacked_orders = createAsyncThunk(
  "order/getPacked_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderprocessing-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getDispached_orders = createAsyncThunk(
  "order/getDispached_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderdispatched-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getOut_for_delivery_orders = createAsyncThunk(
  "order/getOut_for_delivery_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/Orderoutfordelivery-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getDeliveried_orders = createAsyncThunk(
  "order/getDeliveried_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/Orderdelivered-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getCancelled_orders = createAsyncThunk(
  "order/getCancelled_orders",
  async (orderid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/Ordercancelled-by-shopid/${orderid}`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/${formData.orderid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not Updated");
    }
  }
);

const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    set_active_Order_steps(state, action) {
      state.active_Order_steps = action.payload;
    },

    setBooked_ordersProccessUpdate(state, action) {
      state.booked_orders = state.booked_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.packed_orders = [...state.packed_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "booked_orders",
        JSON.stringify(state.booked_orders)
      );
      localStorage.setItem(
        "packed_orders",
        JSON.stringify(state.packed_orders)
      );
    },

    setPacked_ordersProccessUpdate(state, action) {
      state.packed_orders = state.packed_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.dispached_orders = [...state.dispached_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "packed_orders",
        JSON.stringify(state.packed_orders)
      );
      localStorage.setItem(
        "dispached_orders",
        JSON.stringify(state.dispached_orders)
      );
    },
    setDispached_ordersProccessUpdate(state, action) {
      state.dispached_orders = state.dispached_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.out_for_delivery_orders = [
        ...state.out_for_delivery_orders,
        action.payload,
      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      localStorage.setItem(
        "dispached_orders",
        JSON.stringify(state.dispached_orders)
      );
      localStorage.setItem(
        "out_for_delivery_orders",
        JSON.stringify(state.out_for_delivery_orders)
      );
    },
    setOut_for_delivery_ordersProccessUpdate(state, action) {
      state.out_for_delivery_orders = state.out_for_delivery_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.deliveried_orders = [
        ...state.deliveried_orders,
        action.payload,
      ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      localStorage.setItem(
        "out_for_delivery_orders",
        JSON.stringify(state.out_for_delivery_orders)
      );
      localStorage.setItem(
        "deliveried_orders",
        JSON.stringify(state.deliveried_orders)
      );
    },

    setBooked_ordersCancelUpdate(state, action) {
      state.booked_orders = state.booked_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.cancelled_orders = [...state.cancelled_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "booked_orders",
        JSON.stringify(state.booked_orders)
      );
      localStorage.setItem(
        "cancelled_orders",
        JSON.stringify(state.cancelled_orders)
      );
    },
    setPacked_ordersCancelUpdate(state, action) {
      state.packed_orders = state.packed_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.cancelled_orders = [...state.cancelled_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "packed_orders",
        JSON.stringify(state.packed_orders)
      );
      localStorage.setItem(
        "cancelled_orders",
        JSON.stringify(state.cancelled_orders)
      );
    },
    setDispach_ordersCancelUpdate(state, action) {
      state.dispached_orders = state.dispached_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.cancelled_orders = [...state.cancelled_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "dispached_orders",
        JSON.stringify(state.dispached_orders)
      );
      localStorage.setItem(
        "cancelled_orders",
        JSON.stringify(state.cancelled_orders)
      );
    },
    setOut_For_deliveryCancelUpdate(state, action) {
      state.out_for_delivery_orders = state.out_for_delivery_orders.filter(
        (order) => order._id !== action.payload._id
      );
      state.cancelled_orders = [...state.cancelled_orders, action.payload].sort(
        (a, b) => (a.createdAt > b.createdAt ? -1 : 1)
      );
      localStorage.setItem(
        "out_for_delivery_orders",
        JSON.stringify(state.out_for_delivery_orders)
      );
      localStorage.setItem(
        "cancelled_orders",
        JSON.stringify(state.cancelled_orders)
      );
    },
    get_seller_earn(state, action) {
      for (let index = 0; index < state.deliveried_orders.length; index++) {
        state.total_Deliveried_orders =
          Number(state.total_Deliveried_orders) +
          Number(state.deliveried_orders[index].Netpayable);
      }
      const remaining_percentage = 100 - state.ews_commission;
      state.total_seller_earn =
        state.total_Deliveried_orders * (remaining_percentage / 100);
      // let get_eran = 0;
      // for (let index = 0; index < state.deliveried_orders.length; index++) {
      //   get_eran =
      //     Number(get_eran) + Number(state.deliveried_orders[index].Netpayable);
      // }
      // state.total_Deliveried_orders = get_eran;
      // const remaining_percentage = 100 - state.ews_commission;
      // state.total_seller_earn =
      //   state.total_Deliveried_orders * (remaining_percentage / 100);
    },
    setReset_Order(state, action) {
      state.out_for_delivery_orders = [];
      state.cancelled_orders = [];
      state.booked_orders = [];
      state.packed_orders = [];
      state.dispached_orders = [];
      state.deliveried_orders = [];
      state.total_seller_earn = 0;
      state.total_Deliveried_orders = 0;

      localStorage.setItem(
        "deliveried_orders",
        JSON.stringify(state.deliveried_orders)
      );
      localStorage.setItem(
        "dispached_orders",
        JSON.stringify(state.dispached_orders)
      );
      localStorage.setItem(
        "booked_orders",
        JSON.stringify(state.booked_orders)
      );
      localStorage.setItem(
        "packed_orders",
        JSON.stringify(state.packed_orders)
      );
      localStorage.setItem(
        "out_for_delivery_orders",
        JSON.stringify(state.out_for_delivery_orders)
      );
      localStorage.setItem(
        "cancelled_orders",
        JSON.stringify(state.cancelled_orders)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeller_Orders.pending, (state) => {
        state.isSeller_ordersLoading = true;
      })
      .addCase(getSeller_Orders.fulfilled, (state, action) => {
        state.seller_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );

        localStorage.setItem(
          "seller_orders",
          JSON.stringify(state.seller_orders)
        );
        state.isSeller_ordersLoading = false;
        state.isSeller_orders_Available = true;
      })
      .addCase(getSeller_Orders.rejected, (state) => {
        state.isSeller_ordersLoading = true;
      });
    builder
      .addCase(getBooked_orders.pending, (state) => {
        state.isbooked_ordersLoading = true;
      })
      .addCase(getBooked_orders.fulfilled, (state, action) => {
        state.booked_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );

        for (let index = 0; index < state.booked_orders.length; index++) {
          state.total_Booked_orders =
            Number(state.total_Booked_orders) +
            Number(state.booked_orders[index].Netpayable);
        }
        localStorage.setItem(
          "booked_orders",
          JSON.stringify(state.booked_orders)
        );
        state.isbooked_ordersLoading = false;
        state.isbooked_orders_Available = true;
      })
      .addCase(getBooked_orders.rejected, (state) => {
        state.isbooked_ordersLoading = true;
      });
    builder
      .addCase(getPacked_orders.pending, (state) => {
        state.ispacked_ordersLoading = true;
      })
      .addCase(getPacked_orders.fulfilled, (state, action) => {
        state.packed_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );
        for (let index = 0; index < state.packed_orders.length; index++) {
          state.total_Packed_orders =
            Number(state.total_Packed_orders) +
            Number(state.packed_orders[index].Netpayable);
        }
        localStorage.setItem(
          "packed_orders",
          JSON.stringify(state.packed_orders)
        );
        state.ispacked_ordersLoading = false;
        state.ispacked_orders_Available = true;
      })
      .addCase(getPacked_orders.rejected, (state) => {
        state.ispacked_ordersLoading = true;
      });
    builder
      .addCase(getDispached_orders.pending, (state) => {
        state.isdispached_ordersLoading = true;
      })
      .addCase(getDispached_orders.fulfilled, (state, action) => {
        state.dispached_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );
        for (let index = 0; index < state.dispached_orders.length; index++) {
          state.total_Dispached_orders =
            Number(state.total_Dispached_orders) +
            Number(state.dispached_orders[index].Netpayable);
        }
        localStorage.setItem(
          "dispached_orders",
          JSON.stringify(state.dispached_orders)
        );
        state.isdispached_ordersLoading = false;
        state.isdispached_orders_Available = true;
      })
      .addCase(getDispached_orders.rejected, (state) => {
        state.isdispached_ordersLoading = true;
      });
    builder
      .addCase(getOut_for_delivery_orders.pending, (state) => {
        state.isout_for_delivery_ordersLoading = true;
      })
      .addCase(getOut_for_delivery_orders.fulfilled, (state, action) => {
        state.out_for_delivery_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );

        for (
          let index = 0;
          index < state.out_for_delivery_orders.length;
          index++
        ) {
          state.total_Out_for_delivery_orders =
            Number(state.total_Out_for_delivery_orders) +
            Number(state.out_for_delivery_orders[index].Netpayable);
        }

        localStorage.setItem(
          "out_for_delivery_orders",
          JSON.stringify(state.out_for_delivery_orders)
        );
        state.isout_for_delivery_ordersLoading = false;
        state.isout_for_delivery_orders_Available = true;
      })
      .addCase(getOut_for_delivery_orders.rejected, (state) => {
        state.isout_for_delivery_ordersLoading = true;
      });
    builder
      .addCase(getDeliveried_orders.pending, (state) => {
        state.isdeliveried_ordersLoading = true;
      })
      .addCase(getDeliveried_orders.fulfilled, (state, action) => {
        state.deliveried_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );
        // for (let index = 0; index < state.deliveried_orders.length; index++) {
        //   state.total_Deliveried_orders =
        //     Number(state.total_Deliveried_orders) +
        //     Number(state.deliveried_orders[index].Netpayable);
        // }
        let get_eran = 0;
        for (let index = 0; index < state.deliveried_orders.length; index++) {
          get_eran =
            Number(get_eran) +
            Number(state.deliveried_orders[index].Netpayable);
        }
        state.total_Deliveried_orders = get_eran;
        const remaining_percentage = 100 - state.ews_commission;
        state.total_seller_earn =
          state.total_Deliveried_orders * (remaining_percentage / 100);

        localStorage.setItem(
          "deliveried_orders",
          JSON.stringify(state.deliveried_orders)
        );
        state.isdeliveried_ordersLoading = false;
        state.isdeliveried_orders_Available = true;
      })
      .addCase(getDeliveried_orders.rejected, (state) => {
        state.isdeliveried_ordersLoading = true;
      });
    builder
      .addCase(getCancelled_orders.pending, (state) => {
        state.iscancelled_ordersLoading = true;
      })
      .addCase(getCancelled_orders.fulfilled, (state, action) => {
        state.cancelled_orders = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );

        for (let index = 0; index < state.cancelled_orders.length; index++) {
          state.total_Cancelled_orders =
            Number(state.total_Cancelled_orders) +
            Number(state.cancelled_orders[index].Netpayable);
        }

        localStorage.setItem(
          "cancelled_orders",
          JSON.stringify(state.cancelled_orders)
        );
        state.iscancelled_ordersLoading = false;
        state.iscancelled_orders_Available = true;
      })
      .addCase(getCancelled_orders.rejected, (state) => {
        state.iscancelled_ordersLoading = true;
      });
  },
});
export const {
  set_active_Order_steps,
  setBooked_ordersProccessUpdate,
  setPacked_ordersProccessUpdate,
  setDispached_ordersProccessUpdate,
  setOut_for_delivery_ordersProccessUpdate,
  setBooked_ordersCancelUpdate,
  setPacked_ordersCancelUpdate,
  setDispach_ordersCancelUpdate,
  setOut_For_deliveryCancelUpdate,
  setReset_Order,
  get_seller_earn,
} = OrderSlice.actions;
export default OrderSlice.reducer;
