import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    fieldStatustotal: localStorage.getItem("fieldStatustotal")
        ? JSON.parse(localStorage.getItem("fieldStatustotal")).sort()
        : [],
    fieldStatuShow: localStorage.getItem("fieldStatustotal")
        ? JSON.parse(localStorage.getItem("fieldStatustotal")).filter((data) => data.show === true).sort()
        : [],
    isLoading: true,
    fieldLoading: true,
    delfieldLoading: true,
};

export const getFieldStatus = createAsyncThunk("field/getFieldStatus", async (thunkAPI) => {
    try {
        const url = `${Baseurl}/api/v1/fieldstatus/all`;
        const resp = await axios(url);
        return resp.data.fields;
    } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
    }
});

export const PostField = createAsyncThunk(
    "field/PostField",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/fieldstatus/new`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);
export const getOneFieldStatus = createAsyncThunk(
    "field/getOneFieldStatus",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/fieldstatus/one-field-status`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

export const FieldUpdate = createAsyncThunk(
    "field/FieldUpdate",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/fieldstatus/${formData.brandid}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

export const FieldDelete = createAsyncThunk(
    "field/FieldDelete",
    async (id, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/fieldstatus/${id}`;
            const resp = await axios.delete(url, id, config);
            const myreturn = {
                success: resp.data.success,
                id: id,
            };
            return myreturn;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

const FieldStatusSlice = createSlice({
    name: "fieldStatus",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFieldStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFieldStatus.fulfilled, (state, action) => {
                state.fieldStatustotal = action.payload.sort();
                state.fieldStatuShow = state.fieldStatustotal.filter((data) => data.show === true);
                localStorage.setItem("fieldStatustotal", JSON.stringify(state.fieldStatustotal));
                state.isLoading = false;
                state.fieldLoading = false;
            })
            .addCase(getFieldStatus.rejected, (state) => {
                state.isLoading = true;
            });
        builder
            .addCase(PostField.pending, (state) => {
                state.fieldLoading = true;
            })
            .addCase(PostField.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldStatustotal = [...state.fieldStatustotal, action.payload.field].sort();
                    state.fieldStatuShow = state.fieldStatustotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldStatustotal", JSON.stringify(state.fieldStatustotal));
                }
                state.fieldLoading = false;
            })
            .addCase(PostField.rejected, (state) => {
                state.fieldLoading = true;
            });

        builder
            .addCase(FieldUpdate.pending, (state) => {
                state.fieldLoading = true;
            })
            .addCase(FieldUpdate.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldStatustotal = state.fieldStatustotal.filter(
                        (brand) => brand._id !== action.payload.brand._id
                    );
                    state.fieldStatustotal = [...state.fieldStatustotal, action.payload.field];
                    state.fieldStatuShow = state.fieldStatustotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldStatustotal", JSON.stringify(state.fieldStatustotal));
                }
                state.fieldLoading = false;
            })
            .addCase(FieldUpdate.rejected, (state) => {
                state.fieldLoading = true;
            });
        builder
            .addCase(FieldDelete.pending, (state) => {
                state.delfieldLoading = true;
            })
            .addCase(FieldDelete.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldStatustotal = state.fieldStatustotal.filter(
                        (brand) => brand._id !== action.payload.id
                    ).sort();
                    state.fieldStatuShow = state.fieldStatustotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldStatustotal", JSON.stringify(state.fieldStatustotal));
                }
                state.delfieldLoading = false;
            })
            .addCase(FieldDelete.rejected, (state) => {
                state.delfieldLoading = true;
            });
    },

});

export const {
} = FieldStatusSlice.actions;
export default FieldStatusSlice.reducer;