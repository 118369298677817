import React from "react";
import { Steps, Button, Affix } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Step_3_Product_info from "./add_products/Step_3_Product_info";
import Step_1_BasicDetails from "./add_products/Step_1_BasicDetails";
import Step_2_Add_Brand from "./add_products/Step_2_Add_Brand";
import { useDispatch, useSelector } from "react-redux";
import { productPost, setReset_data } from "../redux/product/productSlice";
import { useToasts } from "react-toast-notifications";

const Add_Products = () => {
  const navigate = useNavigate();
  const {
    add_prduct_steps,
    product_information,
    product_basic_details,
    product_description,
    product_Images,
    product_options,
  } = useSelector((store) => store.product);

  const { isAuth, login_Data } = useSelector((store) => store.authentication);

  const renderSwitch = (param) => {
    switch (param) {
      case 1: {
        return (
          <>
            <Step_1_BasicDetails />
          </>
        );
      }
      case 2: {
        return (
          <>
            <Step_2_Add_Brand />
          </>
        );
      }
      case 3: {
        return (
          <>
            <Step_3_Product_info />
          </>
        );
      }

      default:
        return (
          <>
            <Step_1_BasicDetails />
          </>
        );
    }
  };

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const product_UploadeClick = async (e) => {
    e.preventDefault();

    if (
      add_prduct_steps === 3 &&
      product_information !== "" &&
      product_description !== "" &&
      product_Images.length !== 0 &&
      product_options.length !== 0 &&
      product_basic_details !== ""
    ) {
      let multicheck = false;
      if (product_options.length > 1) {
        multicheck = true;
      }

      let finaloption = [];

      for (let index = 0; index < product_options.length; index++) {
        let option_element = product_options[index];
        option_element = {
          ...option_element,
          thumbnail: product_Images[0],
          slider: [...product_Images],
        };
        finaloption = [...finaloption, option_element];
      }
      let Clothing = "";
      let Beauty = "";
      let Electronics = "";
      let Grocery = "";
      let Medicines = "";
      let Home_and_furniture = "";
      let Footwear = "";
      let Toys_games = "";

      if (product_basic_details.superCategory === "Clothing") {
        Clothing = product_description;
      } else if (product_basic_details.superCategory === "Beauty") {
        Beauty = product_description;
      } else if (product_basic_details.superCategory === "Electronics") {
        Electronics = product_description;
      } else if (product_basic_details.superCategory === "Grocery") {
        Grocery = product_description;
      } else if (product_basic_details.superCategory === "Medicines") {
        Medicines = product_description;
      } else if (product_basic_details.superCategory === "Home and furniture") {
        Home_and_furniture = product_description;
      } else if (product_basic_details.superCategory === "Footwear") {
        Footwear = product_description;
      } else if (product_basic_details.superCategory === "Toys & games") {
        Toys_games = product_description;
      }

      const formData = {
        name: product_information.product_name,
        slugUrl: product_information.slugurl,
        superCategoryId: product_basic_details.superCategoryId,
        superCategory: product_basic_details.superCategory,
        category: product_basic_details.category,
        categoryId: product_basic_details.categoryId,
        SubCategory: product_basic_details.SubCategory,
        SubCategoryId: product_basic_details.SubCategoryId,
        CategoryTag: product_basic_details.CategoryTag,
        CategoryTagId: product_basic_details.CategoryTagId,
        PrimeryTag: product_basic_details.CategoryTag,
        PrimeryTagId: product_basic_details.CategoryTagId,
        multi: multicheck,
        packSize: finaloption,

        shopName: login_Data.shopDisplayName,
        shopNumber: login_Data.mobile,
        shopEmail: login_Data.email,
        shopId: login_Data.shopId,

        metaKeyword: product_information.product_name,
        metaDesc: product_information.product_name,

        description: product_information.description,
        specification: product_information.specification,
        Dimension: product_information.Dimension,
        manufacturing_company: product_information.manufacturing_company,
        cover_in_warranty: product_information.cover_in_warranty,
        return_days: product_information.return_days,
        
        clothing: Clothing,
        electronics: Electronics,
        medicine: Medicines,
        beauty: Beauty,
        footwear: Footwear,
      };

      const data = await dispatch(productPost(formData));

      if (data.payload.success) {
        addToast("Product Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        dispatch(setReset_data());
        navigate("/seller/my-listings");
      }
    }
  };

  return (
    <>
      <div
        className="step_add_products"
        style={{
          background: "#fff",
        }}
      >
        <div className="step_container">
          <Steps
            size="small"
            current={add_prduct_steps - 1}
            items={[
              {
                title: "SELECT VERTICAL",
              },
              {
                title: "SELECT BRAND",
              },
              {
                title: "ADD PRODUCT INFO",
              },
            ]}
          />
        </div>
        <div className="step_butn">
          {add_prduct_steps === 3 &&
          product_information !== "" &&
          product_description !== "" &&
          product_Images.length !== 0 &&
          product_options.length !== 0 &&
          product_basic_details !== "" ? (
            <Button type="primary" onClick={(e) => product_UploadeClick(e)}>
              Add Product
            </Button>
          ) : (
            <>
              <Button type="primary" disabled={true}>
                Add Product
              </Button>
            </>
          )}
        </div>
      </div>

      {renderSwitch(add_prduct_steps)}
    </>
  );
};

export default Add_Products;
