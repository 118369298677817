import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { BiCategory } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  seller_Sms_OTP,
  signin,
  update_registation_status,
  update_seller_details,
  validateMobile,
} from "../redux/Athentication/AuthSlice";
import FooterRegPart from "./returnOrder/FooterRegPart";

const Seller_OTP = () => {
  const { isAuth } = useSelector((state) => state.authentication);
  const [buttonPress, setbuttonPress] = useState(false);

  const [login_error, setlogin_error] = useState("");
  const [login_error_colour, setlogin_error_colour] = useState("red");

  const [mobile, setMobile] = useState("");
  const [mobile_confirm, setMobile_confirm] = useState("");
  const [mobileError, setMobileError] = useState("");

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (isAuth === true) {
      navigate("/seller");
    }
  }, [isAuth]);

  useEffect(() => {
    if (buttonPress === true) {
      if (mobile_confirm.length !== 6) {
        setMobileError("otp must should be a 6 digit code");
      } else {
        setMobileError("");
      }
    }
  }, [buttonPress, mobile_confirm]);

  const mobileValideteChange = async (e) => {
    let inputValue = e.replace(/\D/g, "");
    setMobile(inputValue);
    if (inputValue.length !== 6) {
      setMobileError("otp must should be a 6 digit code");
      setMobile_confirm("");
    } else {
      setMobileError("");
      setMobile_confirm(inputValue);
    }
  };

  const loginSellerClick = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    if (mobile_confirm.length !== 6) {
      setMobileError("otp must should be a 6 digit code");
    } else {
      setMobileError("");
    }

    if (mobile_confirm.length === 6 && location.state.OTP === mobile_confirm) {
      const mobileValidate = await dispatch(validateMobile(location.state.mobile));
      console.log(mobileValidate.payload, "mobileValidate.payload.");

      if (mobileValidate.payload.success) {
        const sellect_data = mobileValidate.payload.shop;
        if (
          sellect_data.shopStatusText === "Not Approve" &&
          sellect_data.shop_form_status <= 2
        ) {
          dispatch(update_registation_status(sellect_data.shop_form_status));
          dispatch(update_seller_details(sellect_data));
          navigate("/registation");
        } else {
          if (
            sellect_data.shopStatusText === "Approve" &&
            sellect_data.shop_form_status === 3
          ) {
            const seller_login = {
              ...sellect_data,
              isAuth: true,
            };
            dispatch(signin(seller_login));
            navigate("/seller");
          } else {
            setlogin_error(
              "Approval verification pending, Please contact admin for approval @8447282606"
            );
            setlogin_error_colour("green");
          }
        }
      } else {
        setlogin_error("Please check your login credentials");
        setlogin_error_colour("red");
      }
    } else {
      setMobileError("OTP not matched");
    }
  };

  return (
    <>
      <div className="mobile_logo_login">
        <div>
          <img onClick={() => navigate("/")} src="/assets/images/ew10.png" />
        </div>
        <h4>Seller Panel</h4>
      </div>
      <div>
        <div
          style={{
            margin: "auto",
            maxWidth: "800px",
            paddingLeft: "15px",
            // paddingRight: "15px",
          }}
          className="mobi_margin_only"
        >
          <form autocomplete="off">
            <div style={{ marginTop: "20px" }}>
              <TextField
                label="Enter OTP"
                className="textfield"
                type="text"
                id="text"
                style={{ width: "100%" }}
                error={mobileError !== "" ? true : false}
                helperText={mobileError}
                onChange={(e) => mobileValideteChange(e.target.value)}
                value={mobile}
                autocomplete="off"
              />
            </div>

            <div style={{ marginTop: "15px" }}>
              <h5 className="looktext2">
                By continuing, I agree to EWShopping{" "}
                <strong style={{ color: "#0066cc" }}>
                  <Link
                    style={{ textDecoration: "none", color: "#0066cc" }}
                    to="https://ewshopping.com/TermsUse"
                    target="blank"
                  >
                    Terms of use
                  </Link>{" "}
                </strong>
                &{" "}
                <strong style={{ color: "#0066cc" }}>
                  <Link
                    style={{ textDecoration: "none", color: "#0066cc" }}
                    to="https://ewshopping.com/PrivacyPolicy"
                    target="blank"
                  >
                    Privacy Policy{" "}
                  </Link>
                </strong>
              </h5>
            </div>

            <div>
              {login_error !== "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaCheckCircle color={login_error_colour} />
                  <h6
                    style={{
                      color: { login_error_colour },
                      fontWeight: "600",
                      paddingLeft: "5px",
                    }}
                  >
                    {login_error}
                  </h6>
                </div>
              )}
            </div>
            <div className="resistercontainer1">
              <div
                className="registercontainer"
                onClick={(e) => loginSellerClick(e)}
                style={{
                  cursor: "pointer",
                }}
              >
                <h5 className="registertext">Verify OTP</h5>
                <FaArrowRightLong color="#fff" />
              </div>
              <span className="link_registered">
                <span style={{ color: "#000" }}>You are new Seller ?</span>
                <span
                  onClick={() => {
                    navigate("/registation");
                    dispatch(update_registation_status(0));
                  }}
                >
                  click here.
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
      <FooterRegPart />
    </>
  );
};

export default Seller_OTP;
