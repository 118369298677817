import React, { useState } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { AudioOutlined, DownOutlined } from "@ant-design/icons";
import { IoSearch } from "react-icons/io5";
import {
  Input,
  Tooltip,
  Dropdown,
  Space,
  Button,
  Flex,
  Radio,
  ConfigProvider,
} from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Delivered_Order_reports from "./sales_reports/Delivered_Order_reports";

const SalesReport = () => {
  const dispatch = useDispatch();
  const { deliveried_orders, total_seller_earn } = useSelector(
    (store) => store.order
  );

  const [search, setSearch] = useState("");

  const text = <span>prompt text</span>;
  return (
    <>
      <div className="listing_header">
        <div className="listing_header_left">
          <div className="list_header_left_top">
            <p>
              Home
              <span>
                <MdOutlineChevronRight />
              </span>
            </p>
            <span className="header_name">My Sales Report</span>
          </div>
        </div>
        <div className="listing_header_right">
          <Input
            placeholder="Search By Order ID"
            size="large"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={
              <Tooltip>
                <IoSearch
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              </Tooltip>
            }
            className="header-search"
          />
        </div>
      </div>
      <div className="main_screen">
        <div className="radio_laptop">
          <div className="">
            <Radio.Group defaultValue="a" size="large">
              <Radio.Button value="e" className="radio_buttons_listing ">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">{deliveried_orders.length}</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Complited Orders</span>
              </Radio.Button>
            </Radio.Group>
            <Radio.Group defaultValue="a" size="large" className="">
              <Radio.Button value="e" className="radio_buttons_listing ">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">{total_seller_earn}</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Total Sales</span>
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <Delivered_Order_reports search={search} />
      </div>
    </>
  );
};

export default SalesReport;
