import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  producttotal: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal")).sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    )
    : [],
  // pending_products: localStorage.getItem("producttotal")
  //   ? JSON.parse(localStorage.getItem("producttotal")).filter((data)=>data)
  //   : [],
  isproducttotal_Available: localStorage.getItem("producttotal")
    ? true
    : false,

  mobileimage: "",
  desktopimage: "",
  isproductmobileimageLoading: true,
  isproductdeskimageLoading: true,
  isLoading: true,
  productLoading: true,
  delproductLoading: true,

  add_prduct_steps: localStorage.getItem("add_prduct_steps")
    ? JSON.parse(localStorage.getItem("add_prduct_steps"))
    : 1,

  product_information: localStorage.getItem("product_information")
    ? JSON.parse(localStorage.getItem("product_information"))
    : "",
  product_basic_details: localStorage.getItem("product_basic_details")
    ? JSON.parse(localStorage.getItem("product_basic_details"))
    : "",
  product_brand: localStorage.getItem("product_brand")
    ? JSON.parse(localStorage.getItem("product_brand"))
    : "",
  product_description: localStorage.getItem("product_description")
    ? JSON.parse(localStorage.getItem("product_description"))
    : "",
  product_Images: localStorage.getItem("product_Images")
    ? JSON.parse(localStorage.getItem("product_Images"))
    : [],
  product_options: localStorage.getItem("product_options")
    ? JSON.parse(localStorage.getItem("product_options"))
    : [],
};

export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (shopid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/productByShopId-limit/${shopid}`;
      const resp = await axios(url);
      return resp.data.product;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const productPost = createAsyncThunk(
  "product/productPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);
export const productUpdate = createAsyncThunk(
  "product/productUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/${formData.productid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);
export const productDelete = createAsyncThunk(
  "product/productDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);
export const productImages = createAsyncThunk(
  "product/productImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/product/productimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);
export const validateProductSlugUrl = createAsyncThunk(
  "product/validateProductSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    }
    try {
      const url = `${Baseurl}/api/v1/product/verify-slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const packsizeImages = createAsyncThunk(
  "product/packsizeImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/product/packsize-images`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const getOneFieldStatus = createAsyncThunk(
  "product/getOneFieldStatus",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/fieldstatus/one-field-status`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("field Not create");
    }
  }
);


const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct_basic_details(state, action) {
      state.product_basic_details = action.payload;
      localStorage.setItem("product_basic_details", JSON.stringify(state.product_basic_details));
    },
    setProduct_brand(state, action) {
      state.product_brand = action.payload;
      localStorage.setItem("product_brand", JSON.stringify(state.product_brand));
    },
    setProduct_information(state, action) {
      state.product_information = action.payload;
      localStorage.setItem("product_information", JSON.stringify(state.product_information));
    },
    setProduct_description(state, action) {
      state.product_description = action.payload;
      localStorage.setItem("product_description", JSON.stringify(state.product_description));
    },
    setProduct_options(state, action) {
      state.product_options = action.payload;
      localStorage.setItem("product_options", JSON.stringify(state.product_options));
    },
    setAdd_prduct_steps(state, action) {
      state.add_prduct_steps = action.payload;
      localStorage.setItem("add_prduct_steps", JSON.stringify(state.add_prduct_steps));
    },
    setAdd_prduct_images(state, action) {
      state.product_Images = action.payload;
      localStorage.setItem("product_Images", JSON.stringify(state.product_Images));
    },
    setReset_data(state, action) {
      state.product_Images = [];
      state.product_basic_details = "";
      state.product_brand = "";
      state.product_information = "";
      state.product_description = "";
      state.product_options = [];
      state.add_prduct_steps = 1;
      localStorage.setItem("add_prduct_steps", JSON.stringify(state.add_prduct_steps));
      localStorage.setItem("product_options", JSON.stringify(state.product_options));
      localStorage.setItem("product_description", JSON.stringify(state.product_description));
      localStorage.setItem("product_information", JSON.stringify(state.product_information));
      localStorage.setItem("product_brand", JSON.stringify(state.product_brand));
      localStorage.setItem("product_Images", JSON.stringify(state.product_Images));
      localStorage.setItem("product_basic_details", JSON.stringify(state.product_basic_details));
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.producttotal = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1
        );
        // state.subcategoryShow = state.producttotal.filter((data) => data.status === true);
        localStorage.setItem("producttotal", JSON.stringify(state.producttotal));
        state.productLoading = false;
        state.isproducttotal_Available = true;
      })
      .addCase(getProduct.rejected, (state) => {
        state.productLoading = true;
      });
    builder
      .addCase(productPost.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(productPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.producttotal = [...state.producttotal, action.payload.product];
          localStorage.setItem("producttotal", JSON.stringify(state.producttotal));
        }
        state.productLoading = false;
      })
      .addCase(productPost.rejected, (state) => {
        state.productLoading = true;
      });
  },
});
export const {
  setProduct_information,
  setProduct_description,
  setProduct_options,
  setProduct_basic_details,
  setProduct_brand,
  setAdd_prduct_steps,
  setAdd_prduct_images,
  setReset_data
} = ProductSlice.actions;
export default ProductSlice.reducer;