import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Button, Flex, Modal, Timeline, Input, Typography } from "antd";
import {
  setDispach_ordersCancelUpdate,
  setDispached_ordersProccessUpdate,
  updateOrder,
} from "../redux/order/OrderSlice";

const { TextArea } = Input;
const { Title } = Typography;

const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [orderinfo, setOrderinfo] = useState("");
  const [orderid, setOrderid] = useState("");
  const [order_Loading, setorder_Loading] = useState(true);

  const [cancelModel, setcancelModel] = useState(false);
  const [timeLineModel, settimeLineModel] = useState(false);
  const [ProcedModel, setProcedModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonLoadingLoading, setbuttonLoadingLoading] = useState(false);

  const [time_line, settime_line] = useState("");
  const [time_line_error, settime_line_error] = useState("");

  const [transportId, settransportId] = useState("");
  const [transportId_error, settransportId_error] = useState("");
  const [partnername, setpartnername] = useState("");
  const [partnername_error, setpartnername_error] = useState("");
  const [partnerNumber, setpartnerNumber] = useState("");
  const [partnerNumber_error, setpartnerNumber_error] = useState("");

  const [button_press, setbutton_press] = useState(false);

  const [timelineData, setTimelineData] = useState([]);

  const data = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];

  useEffect(() => {
    if (params.orderid) {
      const get_order = async () => {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${params.orderid}`;
        const orderFetch = await axios.get(url);
        if (orderFetch.data.success === true) {
          const order = orderFetch.data.order;
          setOrderid(order._id.slice(18));
          setOrderinfo(order);
          setorder_Loading(false);
          setTimelineData([...order.remark.OrderDispatchedRemark]);
        }
      };
      get_order();
    }
  }, [params.orderid]);

  useEffect(() => {
    if (button_press === true) {
      if (time_line === "") {
        settime_line_error("required");
      } else {
        settime_line_error("");
      }
      if (transportId === "") {
        settransportId_error("required");
      } else {
        settransportId_error("");
      }
      if (partnername === "") {
        setpartnername_error("required");
      } else {
        setpartnername_error("");
      }
      if (partnerNumber === "") {
        setpartnerNumber_error("required");
      } else {
        setpartnerNumber_error("");
      }
    }
  }, [button_press, time_line, transportId, partnername, partnerNumber]);

  const cancelOrderClick = async () => {
    setbuttonLoadingLoading(true);
    const form_data = {
      orderid: orderinfo._id,
      OrderStatus: 0,
      OrderStatusText: "Order Cancelled",
    };
    const orderStatus = await dispatch(updateOrder(form_data));
    if (orderStatus.payload.success === true) {
      const order = orderStatus.payload.order;
      await dispatch(setDispach_ordersCancelUpdate(order));
      setOrderid(order._id.slice(18));
      setOrderinfo(order);
      setTimelineData([...order.remark.OrderDispatchedRemark]);
      setbuttonLoadingLoading(false);
      setcancelModel(false);
    }
  };
  const proceedOrderClick = async () => {
    setbuttonLoadingLoading(true);

    if (transportId === "") {
      settransportId_error("required");
    } else {
      settransportId_error("");
    }
    if (partnername === "") {
      setpartnername_error("required");
    } else {
      setpartnername_error("");
    }
    if (partnerNumber === "") {
      setpartnerNumber_error("required");
    } else {
      setpartnerNumber_error("");
    }

    if (transportId !== "" && partnername !== "" && partnerNumber !== "") {
      const form_data = {
        orderid: orderinfo._id,
        DeliveryPersonID: transportId,
        DeliveryPersonName: partnername,
        DeliveryPersonMobile: partnerNumber,
        OrderStatus: 4,
        OrderStatusText: "Out For Delivery",
      };
      const orderStatus = await dispatch(updateOrder(form_data));
      if (orderStatus.payload.success === true) {
        const order = orderStatus.payload.order;
        await dispatch(setDispached_ordersProccessUpdate(order));
        setOrderid(order._id.slice(18));
        setOrderinfo(order);
        setTimelineData([...order.remark.OrderDispatchedRemark]);
        setbuttonLoadingLoading(false);
        setProcedModel(false);
      }
    } else {
      setbuttonLoadingLoading(false);
    }
  };

  const addTime_lineClick = async () => {
    setbutton_press(true);
    if (time_line === "") {
      settime_line_error("required");
    } else {
      settime_line_error("");
    }

    if (time_line !== "") {
      let timeline_array = [];
      timeline_array = orderinfo.remark.OrderDispatchedRemark;
      timeline_array = [...timeline_array, time_line];

      const form_data = {
        orderid: orderinfo._id,
        remark: {
          ...orderinfo.remark,
          OrderDispatchedRemark: timeline_array,
        },
      };

      const orderStatus = await dispatch(updateOrder(form_data));
      if (orderStatus.payload.success === true) {
        const order = orderStatus.payload.order;
        setOrderid(order._id.slice(18));
        setOrderinfo(order);
        setTimelineData([...order.remark.OrderDispatchedRemark]);
        settimeLineModel(false);
        settime_line("");
      }
    }
  };

  const deleteTimelineClick = async (index) => {
    let timeline_array = timelineData.filter(
      (item, item_index) => item_index !== index
    );

    const form_data = {
      orderid: orderinfo._id,
      remark: {
        ...orderinfo.remark,
        OrderDispatchedRemark: timeline_array,
      },
    };

    const orderStatus = await dispatch(updateOrder(form_data));
    if (orderStatus.payload.success === true) {
      const order = orderStatus.payload.order;
      setOrderid(order._id.slice(18));
      setOrderinfo(order);
      setTimelineData([...order.remark.OrderDispatchedRemark]);
    }
  };
  const closeModel = () => {
    setcancelModel(false);
    setProcedModel(false);
    settimeLineModel(false);
    setbutton_press(false);
    settime_line_error("");
    settransportId("");
    settransportId_error("");
    setpartnername("");
    setpartnername_error("");
    setpartnerNumber("");
    setpartnerNumber_error("");
  };

  return (
    <>
      {order_Loading === false && (
        <>
          <Fragment>
            <div className="content-wrapper ">
              <div className="card m-4">
                <div className="card-footer d-flex justify-content-between">
                  <h5>Order Details</h5>
                </div>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      OrderId:
                    </Form.Label>
                    <Form.Label
                      className="text-danger px-2"
                      style={{ textTransform: "uppercase" }}
                    >
                      {orderid}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Order Status:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.OrderStatusText}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Customer Name:
                    </Form.Label>
                    <Form.Label
                      className="text-danger px-2"
                      style={{ textTransform: "uppercase" }}
                    >
                      {orderinfo.UserName}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Customer Mobile Number:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.UserMobile}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Shipping Address:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.Address}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Order Received Date:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {moment(orderinfo.createdAt).format("MMM DD, YYYY")}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Estimated Delivered By:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {moment(orderinfo.ExpectedDelDate).format("MMM DD, YYYY")}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Total Amount:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.TotalAmount}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Amount Saved:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.Saving}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Net Payable:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.Netpayable}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Logistics Id:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.DeliveryPersonID}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Logistics Partner Name:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.DeliveryPersonName}
                    </Form.Label>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Logistics Partner Number:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.DeliveryPersonMobile}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Product Name:
                    </Form.Label>
                    <Form.Label className="text-danger px-2">
                      {orderinfo.ProductName}
                    </Form.Label>
                  </Col>
                </Row>
                {orderinfo.OrderStatus === 3 && (
                  <Row className="g-2  m-2">
                    <Col md>
                      <Flex gap="small" wrap>
                        <Button
                          type="primary"
                          onClick={(e) => {
                            setcancelModel(true);
                          }}
                        >
                          Cancel Order
                        </Button>
                        <Button
                          onClick={(e) => {
                            setProcedModel(true);
                          }}
                        >
                          Procced Order
                        </Button>

                        <Button
                          type="primary"
                          onClick={(e) => {
                            settimeLineModel(true);
                          }}
                        >
                          Add Timeline
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                )}
              </div>

              {orderinfo.OrderStatus === 3 && (
                <div className="card m-4">
                  <div
                    style={{
                      width: "100%",
                      padding: "2vh 4vh",
                    }}
                    className=" m-auto"
                  >
                    {timelineData &&
                      timelineData.map((item, index) => (
                        <div className="timeline_box" key={index}>
                          <p>
                            {index + 1}) {item}
                          </p>
                          <Button onClick={() => deleteTimelineClick(index)}>
                            Delete
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </Fragment>

          <Modal
            title="Order Cancel"
            open={cancelModel}
            onOk={cancelOrderClick}
            confirmLoading={confirmLoading}
            onCancel={closeModel}
            zIndex={99999}
            footer={[
              <Button key="back" onClick={closeModel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={buttonLoadingLoading}
                onClick={cancelOrderClick}
              >
                Submit
              </Button>,
            ]}
          >
            <p>
              Do you want to cancel{" "}
              <span style={{ textTransform: "uppercase" }}>{orderid}</span> this
              OrderId
            </p>
          </Modal>
          <Modal
            title="Order Out For Delivery"
            open={ProcedModel}
            onOk={proceedOrderClick}
            confirmLoading={confirmLoading}
            onCancel={closeModel}
            zIndex={99999}
            footer={[
              <Button key="back" onClick={closeModel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={buttonLoadingLoading}
                onClick={proceedOrderClick}
              >
                Submit
              </Button>,
            ]}
          >
            <p>
              Do you want to out for delivery{" "}
              <span style={{ textTransform: "uppercase" }}>{orderid}</span> this
              OrderId
            </p>
            <Title level={5} className="my-1">
              Transport Number
            </Title>
            <Input
              status={transportId_error !== "" ? "error" : ""}
              suffix={transportId_error !== "" ? <MdOutlineErrorOutline /> : ""}
              type="text"
              placeholder="enter transport number"
              value={transportId}
              onChange={(e) => settransportId(e.target.value)}
            />
            <Title level={5} className="my-1">
              Partner Name
            </Title>
            <Input
              status={partnername_error !== "" ? "error" : ""}
              suffix={partnername_error !== "" ? <MdOutlineErrorOutline /> : ""}
              type="text"
              placeholder="enter partner name"
              value={partnername}
              onChange={(e) => setpartnername(e.target.value)}
            />
            <Title level={5} className="my-1">
              Partner Number
            </Title>
            <Input
              status={partnerNumber_error !== "" ? "error" : ""}
              suffix={
                partnerNumber_error !== "" ? <MdOutlineErrorOutline /> : ""
              }
              type="text"
              placeholder="enter partner number"
              value={partnerNumber}
              minLength={10}
              maxLength={10}
              onChange={(e) =>
                setpartnerNumber(e.target.value.replace(/\D/g, ""))
              }
            />
          </Modal>
          <Modal
            title="Add Timeline"
            open={timeLineModel}
            onOk={addTime_lineClick}
            confirmLoading={confirmLoading}
            onCancel={closeModel}
            zIndex={99999}
            footer={[
              <Button key="back" onClick={closeModel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={buttonLoadingLoading}
                onClick={addTime_lineClick}
              >
                Submit
              </Button>,
            ]}
          >
            <>
              <TextArea
                placeholder="enter timeline"
                status={time_line_error !== "" ? "error" : ""}
                suffix={time_line_error !== "" ? <MdOutlineErrorOutline /> : ""}
                value={time_line}
                onChange={(e) => settime_line(e.target.value)}
              />
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default OrderDetails;
