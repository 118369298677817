import React from "react";
import BookedOrders from "./BookedOrders";
import PackedOrders from "./PackedOrders";
import DispachedOrders from "./DispachedOrders";
import Out_For_Delivery from "./Out_For_Delivery";
import { useSelector } from "react-redux";
import DeliverdOrders from "./DeliverdOrders";

const Acrive_Order_Steps = ({ search }) => {
  const { active_Order_steps } = useSelector((state) => state.order);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <BookedOrders search={search} />;
      case 1:
        return <PackedOrders search={search} />;
      case 2:
        return <DispachedOrders search={search} />;
      case 3:
        return <Out_For_Delivery search={search} />;
      case 4:
        return <DeliverdOrders search={search} />;
      default:
        return <BookedOrders search={search} />;
        break;
    }
  };

  return <>{renderSwitch(active_Order_steps)}</>;
};

export default Acrive_Order_Steps;
