import React, { useEffect } from "react";
import Header from "./Header";
import All_Sections from "./All_Sections";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Seller_Home_page = () => {
  const { isAuth } = useSelector((state) => state.authentication);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth === true) {
      navigate("/seller");
    }
  }, [isAuth]);

  return (
    <>
      <div className="container-fluid-lg">
        <div>
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Seller_Home_page;
