
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    categoryTagTotal: localStorage.getItem("categoryTagTotal")
        ? JSON.parse(localStorage.getItem("categoryTagTotal"))
        : [],
    categoryTagShow: localStorage.getItem("categoryTagTotal")
        ? JSON.parse(localStorage.getItem("categoryTagTotal")).filter((data) => data.status === true).sort()
        : [],

    mobileimage: "https://res.cloudinary.com/duezqhb2e/image/upload/v1716870395/ctegoryTags/MobileImage/xrjltpqqsx5f3tz61zkh.jpg",
    desktopimage: "https://res.cloudinary.com/duezqhb2e/image/upload/v1716870395/ctegoryTags/MobileImage/xrjltpqqsx5f3tz61zkh.jpg",
    isCatTagmobileImageLoading: false,
    iscattagDeskImageLoading: true,
    isLoading: true,
    CategoryTagLoading: true,
    deleteCattagLoading: true,
    checkSlugurl: true,
};

export const getCategorytag = createAsyncThunk(
    "categorytag/getCategorytag",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/categorytag/allforadmin`;
            const resp = await axios(url);

            return resp.data.ctegoryTags;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);

export const CategoryTagPost = createAsyncThunk(
    "categorytag/CategoryTagPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/categorytag/new`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("categorytag Not create");
        }
    }
);

export const CategoryTagImages = createAsyncThunk(
    "categorytag/CategoryTagImages",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/categorytag/categorytagimages`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("subcategoryimages Not create");
        }
    }
);
export const validateSlugUrl = createAsyncThunk(
    "categorytag/validateSlugUrl",
    async (slugurl, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/categorytag/slugurl/${slugurl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

export const CategoryTagUpdate = createAsyncThunk(
    "categorytag/CategoryTagUpdate",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/categorytag/${formData.cattagid}`;
            const resp = await axios.put(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("categorytag Not create");
        }
    }
);

export const CategoryTagDelete = createAsyncThunk(
    "categorytag/CategoryTagDelete",
    async (id, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/categorytag/${id}`;
            const resp = await axios.delete(url, id, config);
            const myreturn = {
                success: resp.data.success,
                id: id,
            }
            return myreturn;
        } catch (error) {
            return thunkAPI.rejectWithValue("categorytag Not create");
        }
    }
);


const CategoryTagSlice = createSlice({
    name: "categorytag",
    initialState,
    reducers: {
        updatecatTagMobileImage(state, action) {
            state.mobileimage = action.payload;
            state.isCatTagmobileImageLoading = false;
        },
        updatecatTagDeskImage(state, action) {
            state.desktopimage = action.payload;
            state.iscattagDeskImageLoading = false;
        },
        removecatTagImage(state, action) {
            state.desktopimage = "";
            state.mobileimage = "";
            state.iscattagDeskImageLoading = true;
            state.isCatTagmobileImageLoading = true;

        },
        deletecategoryTag(state, action) {
            state.categoryTagTotal = state.categoryTagTotal.filter((subcategory) => subcategory._id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getCategorytag.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategorytag.fulfilled, (state, action) => {
                state.categoryTagTotal = action.payload;
                state.categoryTagShow = state.categoryTagTotal.filter((data) => data.status === true).sort();
                localStorage.setItem("categoryTagTotal", JSON.stringify(state.categoryTagTotal));
                state.isLoading = false;
                state.CategoryTagLoading = false;
            })
            .addCase(getCategorytag.rejected, (state) => {
                state.isLoading = true;
            });
        builder
            .addCase(CategoryTagPost.pending, (state) => {
                state.CategoryTagLoading = true;
            })
            .addCase(CategoryTagPost.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.categoryTagTotal = [...state.categoryTagTotal, action.payload.ctegoryTags];
                    state.categoryTagShow = state.categoryTagTotal.filter((data) => data.status === true).sort();
                    localStorage.setItem("categoryTagTotal", JSON.stringify(state.categoryTagTotal));
                }
                state.CategoryTagLoading = false;
            })
            .addCase(CategoryTagPost.rejected, (state) => {
                state.CategoryTagLoading = true;
            });
        builder
            .addCase(CategoryTagUpdate.pending, (state) => {
                state.CategoryTagLoading = true;
            })
            .addCase(CategoryTagUpdate.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.categoryTagTotal = state.categoryTagTotal.filter((subcategory) => subcategory._id !== action.payload.ctegoryTags._id);
                    state.categoryTagTotal = [...state.categoryTagTotal, action.payload.ctegoryTags];
                    state.mobileimage = "";
                    state.desktopimage = "";
                    state.isCatTagmobileImageLoading = true;
                    state.iscattagDeskImageLoading = true;
                    state.categoryTagShow = state.categoryTagTotal.filter((data) => data.status === true).sort();
                    localStorage.setItem("categoryTagTotal", JSON.stringify(state.categoryTagTotal));
                }
                state.CategoryTagLoading = false;
            })
            .addCase(CategoryTagUpdate.rejected, (state) => {
                state.CategoryTagLoading = true;
            });

        builder
            .addCase(CategoryTagDelete.pending, (state) => {
                state.deleteCattagLoading = true;
            })
            .addCase(CategoryTagDelete.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.categoryTagTotal = state.categoryTagTotal.filter((subcategory) => subcategory._id !== action.payload.id);
                    state.categoryTagShow = state.categoryTagTotal.filter((data) => data.status === true).sort();
                    localStorage.setItem("categoryTagTotal", JSON.stringify(state.categoryTagTotal));
                }
                state.deleteCattagLoading = false;
            })
            .addCase(CategoryTagDelete.rejected, (state) => {
                state.deleteCattagLoading = true;
            });
        builder
            .addCase(CategoryTagImages.pending, (state) => {
                state.isSubDeskImageLoading = true;
            })
            .addCase(CategoryTagImages.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.desktopimage = action.payload.desktopImages;
                    state.mobileimage = action.payload.mobileimages;

                }
                state.isSubDeskImageLoading = false;
                state.isCatTagmobileImageLoading = false;
            })
            .addCase(CategoryTagImages.rejected, (state) => {
                state.isSubDeskImageLoading = true;
            });
    }

});

export const {
    updatecatTagMobileImage,
    updatecatTagDeskImage,
    deletecategoryTag,
    removecatTagImage
} = CategoryTagSlice.actions;
export default CategoryTagSlice.reducer;