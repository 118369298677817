import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function Footerseller() {
  return (
    <div className="footerseller">
      <div className="footer-top clearfix">
        <div className="container">
          <div className="row w-100">
            <div className="col-6 col-sm-12 col-md-3 col-lg-4 footer-links">
              <h4>About</h4>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/About"
                  >
                    {" "}
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/PrivacyPolicy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/Career"
                  >
                    {" "}
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/Content1"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-6 col-sm-12 col-md-3 col-lg-4 footer-links">
              <h4>Customer Services</h4>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/Affiliate"
                  >
                    Affiliate
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/Faq"
                  >
                    Faq
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/RefundPolicy"
                  >
                    Refund Policy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ewshopping.com/TermsUse"
                  >
                    Terms Of Use
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.ewshopping.com/TermsSale"
                    rel="noopener noreferrer "
                  >
                    Terms & Of Sale
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.ewshopping.com/CancellationPolicy"
                    rel="noopener noreferrer"
                  >
                    Cancellation Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-12 col-md-3 col-lg-4 footer-contact">
              <h4 className="h4">Contact Us</h4>
              <p className="address d-flex">
                <span>
                  {" "}
                  <CiLocationOn
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                </span>{" "}
                Ewshopping, Rajendra Palace, New Delhi, Pin -110008
              </p>
              <p className="phone d-flex align-items-center">
                <span>
                  <AiOutlinePhone
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                </span>
                <b className="me-1 d-none">Phone:</b> +91 8447282606
              </p>
              <p className="email d-flex align-items-center">
                <span>
                  <HiOutlineMail
                    style={{ marginRight: "5px", marginBottom: "1px" }}
                  />
                </span>
                <b className="me-1 d-none">Email:</b> info@ewshopping.com
              </p>
              <ul className="list-inline social-icons mt-3">
                <li className="list-inline-item">
                  <FaFacebookF />
                </li>
                <li className="list-inline-item">
                  <FaTwitter className="text-light" />
                </li>
                <li className="list-inline-item">
                  <FaPinterestP />
                </li>
                <li className="list-inline-item">
                  <FaLinkedinIn />
                </li>
                <li className="list-inline-item">
                  <FaInstagram />
                </li>
                <li className="list-inline-item">
                  <FaYoutube className="text-light" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copytext">
          <strong style={{ fontWeight: "600", fontFamily: "sans-serif" }}>
            OUR TOP CATEGORIES FOR ONLINE SHOPPING
          </strong>
          <div className="mt-3">
            <p style={{ textAlign: "justify" }}>
              ELECTRONICS: Televisions | Speakers | Headphone & Earphones |
              Audio & Video | Winter Appliances | Washing Machines | Coolers |
              Fans | Grooming Appliances | Small Appliances
            </p>
            <p style={{ textAlign: "justify" }}>
              MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets |
              Mobile Accessories | Wearables & Smartwatches | Tablet Accessories
            </p>
            <p style={{ textAlign: "justify" }}>
              Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter
              Wear | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches
              | Precious Jewellery | Gold Coins & Bars | Fashion Accessories |
              Sunglasses
            </p>
            <p style={{ textAlign: "justify" }}>
              {" "}
              Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear |
              Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery &
              Cufflinks | Accessories | Fragrances
            </p>
            <p style={{ textAlign: "justify" }}>
              {" "}
              Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness |
              Food And Gourmet | Nutrition & Supplements | Health Monitoring
              Devices | General Wellness | Baby Care | Household Essentials
            </p>
            <p style={{ textAlign: "justify" }}>
              Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear |
              Accessories | Kids Clothing | Girls Clothing | Boys Clothing |
              Baby Clothing | Baby Care | Stationery
            </p>
            <p style={{ textAlign: "justify" }}>
              Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing |
              Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet
              Supplies
            </p>
            <p style={{ textAlign: "justify" }}>
              Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get
              Ready For Gym | Sports | Hiking & Outdoor
            </p>
            <p style={{ textAlign: "justify" }}>
              CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics
              | Car Audio Finder | Car Accessories | Tyre Inflators |Automotive
              | Car Fresheners | Air Purifiers & Ionizers | Biker Gear &
              Accessories | Biker Raincoats | Helmets & Accessories | Parts &
              Spares | Tyres & Alloys | Motorbike Batteries
            </p>
            <p style={{ textAlign: "justify" }}>
              Computers Gaming: Laptops | Printers & Inks | Networking Devices |
              Office Equipment | Storage | Computer Accessories | Desktops |
              Gaming | Computer Components | Software | Monitors | Top Laptop
              Brands
            </p>
            <p style={{ textAlign: "justify" }}>
              BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education |
              Musical Instruments | Indian Instruments | Pa & Stage | Other
              Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards |
              Digital Subscriptions | Movies & Tv Shows
            </p>
            <p style={{ textAlign: "justify" }}>
              Why Choose EWShopping as the Best Online Shopping Sites in India
            </p>
            <p style={{ textAlign: "justify" }}>
              Worldwide Shipping: Available as Standard or Express delivery
              EWShopping Promise: Products with this tag are quality checked,
              and shipped the same day from certified warehouses. So you get the
              right product, faster. Secure Payments: At EWShopping 100% Secure
              payment. If you have any issues, your money is immediately
              refunded. Sit back and enjoy your shopping. Free Return: Your
              money is yours! All refunds come with a question-asked guarantee
              for all orders. 24/7 Dedicated support: Need Help? Click Here. You
              can also talk to us on -------- to resolve your query.
            </p>
            <p style={{ textAlign: "justify" }}>
              India's Best Online Shopping Website for Fashion & Lifestyle
            </p>
            <p style={{ textAlign: "justify" }}>
              EWShopping is the Best Site for Online shopping in India when it
              comes to online clothes shopping for men, women, and kids at the
              lowest prices. The latest fashion trends and styles are showcased
              here, yes at your favorite online shopping destination. Well, if
              fashion is medicine then Beyoung is the chemist shop where you can
              do your online shopping for men and women with ease. EWShopping is
              without a doubt one of the best online shopping sites in India.
              The website is replete with the cool products that you always
              crave. Indeed, online shopping for women, men & kids at Beyoung is
              such hassle-free that in just a few clicks one can purchase
              whatever he or she wants. A one-stop destination for all your
              shopping needs, Beyoung caters to each taste and need for men’s
              clothing, Women's Clothing, and accessories.
            </p>
            <p style={{ textAlign: "justify" }}>
              EWShopping is the Best Site for Online shopping in India when it
              comes to online clothes shopping for men, women, and kids at the
              lowest prices. The latest fashion trends and styles are showcased
              here, yes at your favorite online shopping destination. Well, if
              fashion is medicine then Beyoung is the chemist shop where you can
              do your online shopping for men and women with ease. EWShopping is
              without a doubt one of the best online shopping sites in India.
              The website is replete with the cool products that you always
              crave. Indeed, online shopping for women, men & kids at Beyoung is
              such hassle-free that in just a few clicks one can purchase
              whatever he or she wants. A one-stop destination for all your
              shopping needs, Beyoung caters to each taste and need for men’s
              clothing, Women's Clothing, and accessories.
            </p>
          </div>

          <br />
        </div>
      </div>
    </div>
  );
}

export default Footerseller;
