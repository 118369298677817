import React, { useState, useEffect } from "react";
import { Button, Flex, Form, Select, Col, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../redux/product/productSlice";
const { Option } = Select;

const BeautySupercat = ({ buttonPress, getPropDetails, resetield }) => {
  const dispatch = useDispatch();
  const { product_basic_details } = useSelector((store) => store.product);
  const {
    romanTypeMessurements,
    sleevestype,
    top_shapes,
    top_types,
    bottom_styles_with_kurti,
    Dupattaavailable,
    pritsandPatterns,
    dress_designs,
    hemline_styles,
    length_styles,
    necktype,
    occasion_styles,
    embellishment_types,
    wave_patterns,
    wave_types,
    pattern_coverage,
    sleeve_styles,
    no_of_item,
    saree_types,
    saree_border_styles,
    fabric_types,
    Blouseavailable,
    fabric_care_instructions,
    fastening_types,
    transparency_levels,
    main_trends,
  } = useSelector((store) => store.Messurents);

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);

  const [skin_type, setSkin_type] = useState("");
  const [skin_typeerror, setSkin_typeerror] = useState("");
  const [concern, setConcern] = useState("");
  const [concernerror, setConcernerror] = useState("");
  const [coverage, setCoverage] = useState("");
  const [coverageerror, setCoverageerror] = useState("");
  const [finish, setFinish] = useState("");
  const [finisherror, setFinisherror] = useState("");
  const [formulation, setFormulation] = useState("");
  const [formulationerror, setFormulationerror] = useState("");
  const [preference, setPreference] = useState("");
  const [preferenceerror, setPreferenceerror] = useState("");
  const [skin_tone, setSkin_tone] = useState("");
  const [skin_toneerror, setSkin_toneerror] = useState("");
  const [sustainable, setSustainable] = useState("");
  const [sustainableerror, setSustainableerror] = useState("");
  const [concious, setConcious] = useState("");
  const [conciouserror, setConciouserror] = useState("");
  const [benefits, setBenefits] = useState("");
  const [benefitserror, setBenefitserror] = useState("");
  const [pack_of, setPack_of] = useState("");
  const [pack_oferror, setPack_oferror] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [ingredientserror, setIngredientserror] = useState("");
  const [gender, setGender] = useState("");
  const [gendererror, setGendererror] = useState("");
  const [country_origin, setCountry_origin] = useState("");
  const [country_originerror, setCountry_originerror] = useState("");
  const [color, setColor] = useState("");
  const [colorerror, setColorerror] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionerror, setDescriptionerror] = useState("");
  const [how_to_use, setHow_to_use] = useState("");
  const [how_to_useerror, setHow_to_useerror] = useState("");
  const [key_ingredients, setKey_ingredients] = useState("");
  const [key_ingredientserror, setKey_ingredientserror] = useState("");
  const [usage_period, setUsage_period] = useState("");
  const [usage_perioderror, setUsage_perioderror] = useState("");
  const [color_family, setColor_family] = useState("");
  const [color_familyerror, setColor_familyerror] = useState("");
  const [color_shade_name, setColor_shade_name] = useState("");
  const [color_shade_nameerror, setColor_shade_nameerror] = useState("");
  const [features, setFeatures] = useState("");
  const [featureserror, setFeatureserror] = useState("");
  const [type, setType] = useState("");
  const [typeerror, setTypeerror] = useState("");
  const [fragrance, setFragrance] = useState("");
  const [fragranceerror, setFragranceerror] = useState("");
  const [strength, setStrength] = useState("");
  const [strengtherror, setStrengtherror] = useState("");
  const [spf, setSpf] = useState("");
  const [spferror, setSpferror] = useState("");

  useEffect(() => {
    if (product_basic_details !== "") {
      const getdata = async () => {
        const formData = {
          superCategoryId: product_basic_details.superCategoryId,
          categoryId: product_basic_details.categoryId,
          SubCategoryId: product_basic_details.SubCategoryId,
          CategoryTagId: product_basic_details.CategoryTagId,
        };
        console.log(formData, "formData");

        const data = await dispatch(getOneFieldStatus(formData));
        console.log(data.payload, "data.payload.success");

        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];

          if (filterShow.length === 0) {
            const singleObject = {};
            getPropDetails(singleObject, true);
          }
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        } else {
          const singleObject = {};
          getPropDetails(singleObject, true);
        }
      };
      getdata();
    }
  }, [product_basic_details]);

  useEffect(() => {
    if (buttonPress === true) {
      if (skin_type === "") {
        setSkin_typeerror("Required");
      } else {
        setSkin_typeerror("");
      }
      if (coverage === "") {
        setCoverageerror("Required");
      } else {
        setCoverageerror("");
      }
      if (finish === "") {
        setFinisherror("Required");
      } else {
        setFinisherror("");
      }
      if (formulation === "") {
        setFormulationerror("Required");
      } else {
        setFormulationerror("");
      }
      if (concern === "") {
        setConcernerror("Required");
      } else {
        setConcernerror("");
      }
      if (preference === "") {
        setPreferenceerror("Required");
      } else {
        setPreferenceerror("");
      }
      if (skin_tone === "") {
        setSkin_toneerror("Required");
      } else {
        setSkin_toneerror("");
      }
      if (sustainable === "") {
        setSustainableerror("Required");
      } else {
        setSustainableerror("");
      }
      if (concious === "") {
        setConciouserror("Required");
      } else {
        setConciouserror("");
      }
      if (benefits === "") {
        setBenefitserror("Required");
      } else {
        setBenefitserror("");
      }
      if (pack_of === "") {
        setPack_oferror("Required");
      } else {
        setPack_oferror("");
      }
      if (ingredients === "") {
        setIngredientserror("Required");
      } else {
        setIngredientserror("");
      }
      if (gender === "") {
        setGendererror("Required");
      } else {
        setGendererror("");
      }
      if (country_origin === "") {
        setCountry_originerror("Required");
      } else {
        setCountry_originerror("");
      }
      if (color === "") {
        setColorerror("Required");
      } else {
        setColorerror("");
      }
      if (description === "") {
        setDescriptionerror("Required");
      } else {
        setDescriptionerror("");
      }
      if (how_to_use === "") {
        setHow_to_useerror("Required");
      } else {
        setHow_to_useerror("");
      }
      if (key_ingredients === "") {
        setKey_ingredientserror("Required");
      } else {
        setKey_ingredientserror("");
      }
      if (usage_period === "") {
        setUsage_perioderror("Required");
      } else {
        setUsage_perioderror("");
      }
      if (color_family === "") {
        setColor_familyerror("Required");
      } else {
        setColor_familyerror("");
      }
      if (color_shade_name === "") {
        setColor_shade_nameerror("Required");
      } else {
        setColor_shade_nameerror("");
      }
      if (features === "") {
        setFeatureserror("Required");
      } else {
        setFeatureserror("");
      }
      if (type === "") {
        setTypeerror("Required");
      } else {
        setTypeerror("");
      }
      if (fragrance === "") {
        setFragranceerror("Required");
      } else {
        setFragranceerror("");
      }
      if (strength === "") {
        setStrengtherror("Required");
      } else {
        setStrengtherror("");
      }
      if (spf === "") {
        setSpferror("Required");
      } else {
        setSpferror("");
      }
    }
  }, [
    buttonPress,
    skin_type,
    skin_typeerror,
    concern,
    coverage,
    finish,
    formulation,
    preference,
    skin_tone,
    sustainable,
    concious,
    benefits,
    pack_of,
    ingredients,
    gender,
    country_origin,
    color,
    description,
    how_to_use,
    key_ingredients,
    usage_period,
    color_family,
    color_shade_name,
    features,
    type,
    fragrance,
    strength,
    spf,
  ]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (distinctfieldValidate.every((field) => field.value !== "")) {
        let finalarray = [];
        for (let index = 0; index < distinctfieldValidate.length; index++) {
          let element = distinctfieldValidate[index];
          let obj = { [element.field]: element.value };
          finalarray = [...finalarray, obj];
        }
        finalarray = finalarray;

        const singleObject = finalarray.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});
        getPropDetails(singleObject, true);
      } else {
        console.log("fieldas are empty");
      }
    }
  }, [buttonPress, distinctfieldValidate]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Flex wrap="wrap">
          {distinctfield.includes("skin_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Skin Type"
                hasFeedback
                validateStatus={skin_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Skin Type"
                  value={skin_type}
                  onChange={(value) => {
                    setSkin_type(value);
                    updateValues("skin_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={skin_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("concern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Concern"
                hasFeedback
                validateStatus={concernerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Concern"
                  value={concern}
                  onChange={(value) => {
                    setConcern(value);
                    updateValues("concern", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={concern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("coverage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Coverage"
                hasFeedback
                validateStatus={coverageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Coverage"
                  value={coverage}
                  onChange={(value) => {
                    setCoverage(value);
                    updateValues("coverage", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={coverage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("finish") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Finish"
                hasFeedback
                validateStatus={finisherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Finish"
                  value={finish}
                  onChange={(value) => {
                    setFinish(value);
                    updateValues("finish", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={finish === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("formulation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Formulation"
                hasFeedback
                validateStatus={formulationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Formulation"
                  value={formulation}
                  onChange={(value) => {
                    setFormulation(value);
                    updateValues("formulation", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={formulation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("preference") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Preference"
                hasFeedback
                validateStatus={preferenceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Preference"
                  value={preference}
                  onChange={(value) => {
                    setPreference(value);
                    updateValues("preference", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={preference === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("skin_tone") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Skin Tone"
                hasFeedback
                validateStatus={skin_toneerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Skin Tone"
                  value={skin_tone}
                  onChange={(value) => {
                    setSkin_tone(value);
                    updateValues("skin_tone", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={skin_tone === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sustainable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sustainable"
                hasFeedback
                validateStatus={sustainableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sustainable"
                  value={sustainable}
                  onChange={(value) => {
                    setSustainable(value);
                    updateValues("sustainable", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sustainable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("concious") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Concious"
                hasFeedback
                validateStatus={conciouserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Concious"
                  value={concious}
                  onChange={(value) => {
                    setConcious(value);
                    updateValues("concious", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={concious === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("benefits") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Benefits"
                hasFeedback
                validateStatus={benefitserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Benefits"
                  value={benefits}
                  onChange={(value) => {
                    setBenefits(value);
                    updateValues("benefits", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={benefits === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pack_of") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pack of"
                hasFeedback
                validateStatus={pack_oferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Pack of"
                  value={pack_of}
                  onChange={(value) => {
                    setPack_of(value);
                    updateValues("pack_of", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pack_of === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ingredients") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ingredients"
                hasFeedback
                validateStatus={ingredientserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Ingredients"
                  value={ingredients}
                  onChange={(value) => {
                    setIngredients(value);
                    updateValues("ingredients", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ingredients === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gender") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Gender"
                hasFeedback
                validateStatus={gendererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Gender"
                  value={gender}
                  onChange={(value) => {
                    setGender(value);
                    updateValues("gender", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gender === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("country_origin") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Country OF Origin"
                hasFeedback
                validateStatus={country_originerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Country OF Origin"
                  value={country_origin}
                  onChange={(value) => {
                    setCountry_origin(value);
                    updateValues("country_origin", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={country_origin === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color"
                hasFeedback
                validateStatus={colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Color"
                  value={color}
                  onChange={(value) => {
                    setColor(value);
                    updateValues("color", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("description") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Description"
                hasFeedback
                validateStatus={descriptionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Description"
                  value={description}
                  onChange={(value) => {
                    setDescription(value);
                    updateValues("description", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={description === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("how_to_use") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="How to Use"
                hasFeedback
                validateStatus={how_to_useerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select How to Use"
                  onChange={(value) => {
                    setHow_to_use(value);
                    updateValues("how_to_use", value);
                  }}
                  value={how_to_use}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={how_to_use === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("key_ingredients") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Key Ingredients"
                hasFeedback
                validateStatus={key_ingredientserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Key Ingredients"
                  onChange={(value) => {
                    setKey_ingredients(value);
                    updateValues("key_ingredients", value);
                  }}
                  value={key_ingredients}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={key_ingredients === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usage_period") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Usage Period"
                hasFeedback
                validateStatus={usage_perioderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Usage Period"
                  onChange={(value) => {
                    setUsage_period(value);
                    updateValues("usage_period", value);
                  }}
                  value={usage_period}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usage_period === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_family") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Family"
                hasFeedback
                validateStatus={color_familyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Color Family"
                  onChange={(value) => {
                    setColor_family(value);
                    updateValues("color_family", value);
                  }}
                  value={color_family}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_family === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_shade_name") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Shade Name"
                hasFeedback
                validateStatus={color_shade_nameerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Color Shade Name"
                  onChange={(value) => {
                    setColor_shade_name(value);
                    updateValues("color_shade_name", value);
                  }}
                  value={color_shade_name}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_shade_name === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Features"
                hasFeedback
                validateStatus={featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Features"
                  onChange={(value) => {
                    setFeatures(value);
                    updateValues("features", value);
                  }}
                  value={features}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type"
                hasFeedback
                validateStatus={typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Type"
                  onChange={(value) => {
                    setType(value);
                    updateValues("type", value);
                  }}
                  value={type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fragrance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Fragrance"
                hasFeedback
                validateStatus={fragranceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Fragrance"
                  onChange={(value) => {
                    setFragrance(value);
                    updateValues("fragrance", value);
                  }}
                  value={fragrance}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fragrance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("strength") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Strength"
                hasFeedback
                validateStatus={strengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Strength"
                  value={strength}
                  onChange={(value) => {
                    setStrength(value);
                    updateValues("strength", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={strength === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("spf") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="SPF"
                hasFeedback
                validateStatus={spferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select SPF"
                  onChange={(value) => {
                    setSpf(value);
                    updateValues("spf", value);
                  }}
                  value={spf}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={spf === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
                  
        </Flex>
      </Row>
    </>
  );
};

export default BeautySupercat;
