import React, { useEffect, useState } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { AudioOutlined, DownOutlined } from "@ant-design/icons";
import {
  Input,
  Tooltip,
  Dropdown,
  Space,
  Button,
  Flex,
  Radio,
  ConfigProvider,
  Modal,
} from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import {
  get_seller_earn,
  setOut_For_deliveryCancelUpdate,
  setOut_for_delivery_ordersProccessUpdate,
  setPacked_ordersCancelUpdate,
  setPacked_ordersProccessUpdate,
  updateOrder,
} from "../../redux/order/OrderSlice";

const Out_For_Delivery = ({ search }) => {
  const dispatch = useDispatch();

  const { out_for_delivery_orders } = useSelector((store) => store.order);

  const [selectedOrder, setselectedOrder] = useState("");
  const [selectedOrderId, setselectedOrderId] = useState("");

  const [cancelModel, setcancelModel] = useState(false);
  const [ProcedModel, setProcedModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonLoadingLoading, setbuttonLoadingLoading] = useState(false);

  const [filter_Order, setfilter_Order] = useState([]);

  useEffect(() => {
    if (search !== "") {
      const result = out_for_delivery_orders.filter((order) => {
        return order._id.toLowerCase().slice(18).match(search.toLowerCase());
      });
      setfilter_Order([...result]);
    } else {
      setfilter_Order([...out_for_delivery_orders]);
    }
  }, [search, out_for_delivery_orders]);

  const items = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  const columns = [
    {
      field: "Order ID",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row._id.slice(18)}
          </div>
        );
      },
    },
    { field: "ProductName", headerName: "Product Information", width: 350 },

    {
      headerName: "Buyer Details",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{params.row.UserName}</h6>
            <h6>{params.row.UserMobile}</h6>
            <h6>{params.row.Address}</h6>
          </div>
        );
      },
    },

    {
      field: "Netpayable",
      headerName: "Amount",
      width: 130,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },
    {
      field: "ACTIONS",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex  flex-column my-3">
            <Button
              className="mb-2"
              variant="contained"
              onClick={(e) => {
                setselectedOrder(params.row);
                setselectedOrderId(params.row._id.slice(18));
                setcancelModel(true);
              }}
              style={{ background: "#556ee6", color: "#fff" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setselectedOrder(params.row);
                setselectedOrderId(params.row._id.slice(18));
                setProcedModel(true);
                dispatch(get_seller_earn());
              }}
              style={{ background: "#556ee6", color: "#fff" }}
            >
              Process
            </Button>
          </div>
        );
      },
    },
  ];

  const cancelOrderClick = async () => {
    setbuttonLoadingLoading(true);
    const form_data = {
      orderid: selectedOrder._id,
      OrderStatus: 0,
      OrderStatusText: "Order Cancelled",
    };
    const orderStatus = await dispatch(updateOrder(form_data));
    if (orderStatus.payload.success === true) {
      const order = orderStatus.payload.order;
      await dispatch(setOut_For_deliveryCancelUpdate(order));
      setbuttonLoadingLoading(false);
      setcancelModel(false);
    }
  };
  const proceedOrderClick = async () => {
    setbuttonLoadingLoading(true);
    const form_data = {
      orderid: selectedOrder._id,
      OrderStatus: 5,
      OrderStatusText: "Order Delivered",
    };
    const orderStatus = await dispatch(updateOrder(form_data));
    if (orderStatus.payload.success === true) {
      const order = orderStatus.payload.order;
      await dispatch(setOut_for_delivery_ordersProccessUpdate(order));
      setbuttonLoadingLoading(false);
      setProcedModel(false);
    }
  };

  const closeModel = () => {
    setselectedOrder("");
    setcancelModel(false);
    setProcedModel(false);
  };

  return (
    <>
      <div className="product_filtering">
        {/* <div className="product_filtering_left">
          <FaFilter />
        </div>
        <div className="product_filtering_right">
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Category <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Brand <DownOutlined />
              </Space>
            </Button>
          </Dropdown>

          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Stock <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Listing Price <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Listing Quality <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div> */}
      </div>
      <div className="product_filtering_grid">
        <div style={{ height: 1350, width: "100%" }}>
          <DataGrid
            rows={filter_Order}
            columns={columns}
            getRowId={(row) => row._id}
            rowHeight={100}
            getRowHeight={() => "auto"}
            sx={{
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 30, 50, 100]}
            // checkboxSelection
          />
        </div>
      </div>
      <Modal
        title="Order Cancel"
        open={cancelModel}
        onOk={cancelOrderClick}
        confirmLoading={confirmLoading}
        onCancel={closeModel}
        zIndex={99999}
        footer={[
          <Button key="back" onClick={closeModel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoadingLoading}
            onClick={cancelOrderClick}
          >
            Submit
          </Button>,
        ]}
      >
        <p>
          Do you want to cancel{" "}
          <span style={{ textTransform: "uppercase" }}>{selectedOrderId}</span>{" "}
          this OrderId
        </p>
      </Modal>
      <Modal
        title="Order Packed"
        open={ProcedModel}
        onOk={proceedOrderClick}
        confirmLoading={confirmLoading}
        onCancel={closeModel}
        zIndex={99999}
        footer={[
          <Button key="back" onClick={closeModel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoadingLoading}
            onClick={proceedOrderClick}
          >
            Submit
          </Button>,
        ]}
      >
        <p>
          Do you want to Delivered{" "}
          <span style={{ textTransform: "uppercase" }}>{selectedOrderId}</span>{" "}
          this OrderId
        </p>
      </Modal>
    </>
  );
};

export default Out_For_Delivery;
