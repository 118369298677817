import React from "react";
import {
  CheckCircleFilled,
  InfoCircleFilled,
  RightOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Col, Flex, Row } from "antd";
import { Button, Input, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// const { Search } = Input;
const Step_2_Add_Brand = () => {
  return (
    <>
      <div className="boxmaincontainer">
        <div className="boxmaincontainer1">
          <div className="checkcontainer">
            <h6 className="checktext">Check for the Brand you want to sell</h6>
            <div className="checkBrand">
              <Space.Compact
                style={{
                  width: "60%",
                }}
              >
                <Input defaultValue="Nalli" />
                <Button type="primary">Check Brand</Button>
              </Space.Compact>
            </div>

            <div className="hr"></div>
            <div className="or">OR</div>
            <div>
              <h6 className="selecttext">
                Check for the Brand you want to sell
              </h6>
            </div>
            <div className="sevencontainer">
              <div>
                <p className="testtext">SEL STORE</p>
                <p className="asftext">ASF UNIVERSAL</p>
              </div>
              <div>
                <p className="testtext">test</p>
                <p className="asftext">MANSI</p>
              </div>
              <div>
                <p className="testtext">Ashish</p>
                <p className="asftext">Seven Rocks</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="boxmaincontainer2">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <CheckCircleFilled className="checkicon" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <p style={{ fontSize: "10px" }}>Nalll</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-10px",
              }}
            >
              <p style={{ fontSize: "10px", fontWeight: "500" }}>
                You can start selling under this brand
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="brandtext">
                <InfoCircleFilled className="circleicon" />
                You brand is approved as an open brand which means other sellers
                will be allowed to latch on to your listing.If you wish to gate
                the brand please do reach out to seller support for assistance
              </p>
            </div>
            <Flex
              gap="small"
              wrap
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button type="primary">Create New Listing</Button>
            </Flex>
          </div>
        </div> */}
        <div className="boxmaincontainer2">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <CloseCircleOutlined className="checkicon1" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <p style={{ fontSize: "10px" }}>BIBA</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-10px",
              }}
            >
              <p style={{ fontSize: "10px", fontWeight: "500" }}>
                Please apply for an approval to sell under this brand
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="brandtext1">
                <InfoCircleFilled className="circleicon" />
                Kindly apply for approval with Invoice/Brand Authorization
                Letter/Trademark Certificare
              </p>
            </div>
            <Flex
              gap="small"
              wrap
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button type="primary">Apply for Brand approval</Button>
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step_2_Add_Brand;
