import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  superCatTotal: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal"))
    : [],
  superCatShow: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal")).filter((data) => data.showProducts === true && data.name !== "Home and furniture" && data.name !== "Toys & games")
    : [],
  isLoading: localStorage.getItem("superCatTotal") ? false : true,
  supercatLoading: true,
};

export const getSuperCategory = createAsyncThunk(
  "SuperCategory/getSuperCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/supercategory/all`;
      const resp = await axios(url);
      return resp.data.supercategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);


const SuperCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSuperCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSuperCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.superCatTotal = action.payload;
        state.superCatShow = state.superCatTotal.filter((data) => data.showProducts === true && data.name !== "Home and furniture" && data.name !== "Toys & games");
        localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));

      })
      .addCase(getSuperCategory.rejected, (state) => {
        state.isLoading = true;
      });
  },
});

export default SuperCategorySlice.reducer;