import React, { useState } from "react";

import { Col, Divider, Row, Input, Space, Typography } from "antd";
import { MdOutlineErrorOutline } from "react-icons/md";

import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { FaInfoCircle } from "react-icons/fa";

const ProductPacksize = ({
  get_product_option_data,
  product_option_buttonPress,
}) => {
  const dispatch = useDispatch();

  const { product_options } = useSelector((store) => store.product);

  const [packsize, setPacksize] = useState([
    {
      packSizeName: "",
      PsPrime: true,
      enabled: false,
      thumbnail: "",
      slider: [],
      PackSizeOptions: [
        {
          packSizeOptionName: "",
          PsnPrime: true,
          Mrp: "",
          Price: "",
          Discount: "",
          enabled: false,
        },
      ],
    },
  ]);

  const [packsizeError, setpacksizeError] = useState("");
  const [packsizeIndex, setpacksizeIndex] = useState("");
  const [optionSizePress, setoptionSizePress] = useState(false);

  const [optionNameError, setoptionNameError] = useState("");
  const [mrpError, setmrpError] = useState("");
  const [PriceError, setPriceError] = useState("");
  const [optionIndex, setoptionIndex] = useState("");
  const [optionPress, setoptionPress] = useState(false);
  const [currentPacksizeIndex, setcurrentPacksizeIndex] = useState("");

  useEffect(() => {
    if (product_options.length > 0) {
      setPacksize([...product_options]);
    }
  }, [product_options]);

  useEffect(() => {
    if (product_option_buttonPress === true) {
      setoptionPress(true);
      setoptionSizePress(true);
      if (optionPress === true) {
        let optionList = [...packsize];
        const findPackSixeIndex = optionList.findIndex(
          (data) => data.enabled === false
        );
        const packOptions = optionList[findPackSixeIndex].PackSizeOptions;
        const lastIndex = packOptions.findIndex(
          (data) => data.enabled === false
        );
        setoptionNameError("");
        setmrpError("");
        setPriceError("");
        setoptionIndex(lastIndex);
        setcurrentPacksizeIndex(findPackSixeIndex);
        if (packOptions[lastIndex].packSizeOptionName === "") {
          setoptionNameError("error");
        } else {
          setoptionNameError("");
        }
        if (packOptions[lastIndex].Mrp === "") {
          setmrpError("error");
        } else {
          setmrpError("");
        }
        if (packOptions[lastIndex].Price === "") {
          setPriceError("error");
        } else {
          setPriceError("");
        }
      }

      if (optionSizePress === true) {
        //   const lastIndex = packsize.length - 1;
        const lastIndex = packsize.findIndex((data) => data.enabled === false);
        //   setoptionSizePress(false);
        setpacksizeIndex(lastIndex);

        setpacksizeError("");
        setcurrentPacksizeIndex(lastIndex);
        if (packsize[lastIndex].packSizeName === "") {
          setpacksizeError("error");
        } else {
          setpacksizeError("");
        }
      }
    }
  }, [
    product_option_buttonPress,
    optionPress,
    packsize,
    currentPacksizeIndex,
    optionSizePress,
  ]);
  useEffect(() => {
    if (optionPress === true) {
      let optionList = [...packsize];
      const findPackSixeIndex = optionList.findIndex(
        (data) => data.enabled === false
      );
      const packOptions = optionList[findPackSixeIndex].PackSizeOptions;
      const lastIndex = packOptions.findIndex((data) => data.enabled === false);
      setoptionNameError("");
      setmrpError("");
      setPriceError("");
      setoptionIndex(lastIndex);
      setcurrentPacksizeIndex(findPackSixeIndex);
      if (packOptions[lastIndex].packSizeOptionName === "") {
        setoptionNameError("error");
      } else {
        setoptionNameError("");
      }
      if (packOptions[lastIndex].Mrp === "") {
        setmrpError("error");
      } else {
        setmrpError("");
      }
      if (packOptions[lastIndex].Price === "") {
        setPriceError("error");
      } else {
        setPriceError("");
      }
    }

    if (optionSizePress === true) {
      //   const lastIndex = packsize.length - 1;
      const lastIndex = packsize.findIndex((data) => data.enabled === false);
      //   setoptionSizePress(false);
      setpacksizeIndex(lastIndex);

      setpacksizeError("");
      setcurrentPacksizeIndex(lastIndex);
      if (packsize[lastIndex].packSizeName === "") {
        setpacksizeError("error");
      } else {
        setpacksizeError("");
      }
    }
  }, [optionPress, packsize, currentPacksizeIndex, optionSizePress]);

  useEffect(() => {
    if (packsize.length > 0) {
      let optionList = [...packsize];
      const findPackSixeIndex = optionList.findIndex(
        (data) => data.enabled === false
      );
      const packOptions = optionList[findPackSixeIndex].PackSizeOptions;
      const lastIndex = packOptions.findIndex((data) => data.enabled === false);

      if (
        packsize[findPackSixeIndex].packSizeName !== "" &&
        packOptions[lastIndex].packSizeOptionName !== "" &&
        packOptions[lastIndex].Mrp !== "" &&
        packOptions[lastIndex].Price !== ""
      ) {
        if (
          Number(packOptions[lastIndex].Mrp) >=
          Number(packOptions[lastIndex].Price)
        ) {
          get_product_option_data(packsize, true);
        } else {
          optionList = optionList.map((data, index) => {
            if (index === findPackSixeIndex) {
              data.PackSizeOptions[lastIndex].Price = "";
            }
            return data;
          });
          setPacksize(optionList);
          setoptionSizePress(true);
          setoptionPress(true);
        }
      }
    }
  }, [packsize]);

  const handlePackCheck = (
    index,
    e,
    selected,
    psoptionStatus,
    psIndex,
    psName
  ) => {
    let temp = [...packsize];
    if (selected === "PsPrime") {
      if (temp.length > 1) {
        temp = temp.map((data) => {
          data.PsPrime = false;
          return data;
        });
        temp[index][selected] = true;
      }
    } else if (psoptionStatus === true) {
      temp = temp.map((data, cuurentIndex) => {
        if (cuurentIndex === index) {
          if (psName === "packSizeOptionName") {
            data.PackSizeOptions[psIndex][psName] = e.target.value;
          } else if (psName === "PsnPrime") {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                optiondata.PsnPrime = false;
                return optiondata;
              }
            );
            data.PackSizeOptions[psIndex][psName] = true;
          } else {
            if (e.target.value.startsWith(0)) {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim()
                  .slice(1);
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                }
              }
              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                  Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            } else {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim();
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                }
              }

              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                  Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            }
          }
        }
        return data;
      });
      temp = temp;
    } else if (selected !== "PsPrime" && psoptionStatus === false) {
      temp[index][selected] = e.target.value;
    }
    setPacksize(temp);
  };

  const handleDiscount = (lastIndex, findOptionIndex) => {
    let optionList = [...packsize];
    const Discount = Math.round(
      ((Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Mrp) -
        Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Price)) *
        100) /
        Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Mrp)
    );
    optionList[lastIndex].PackSizeOptions[findOptionIndex].Discount =
      String(Discount);
    setPacksize(optionList);
  };

  const handleNewPackOption = (e, currentIndex) => {
    let optionList = [...packsize];
    const packOptions = optionList[currentIndex].PackSizeOptions;
    // const lastIndex = packOptions.length - 1;
    const lastIndex = packOptions.findIndex((data) => data.enabled === false);
    setcurrentPacksizeIndex(currentIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(lastIndex);
    setoptionPress(true);

    if (packOptions[lastIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[lastIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[lastIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }

    if (
      packOptions[lastIndex].packSizeOptionName !== "" &&
      packOptions[lastIndex].Mrp !== "" &&
      packOptions[lastIndex].Price !== ""
    ) {
      if (
        Number(packOptions[lastIndex].Mrp) >=
        Number(packOptions[lastIndex].Price)
      ) {
        optionList = optionList.map((data, index) => {
          if (index === currentIndex) {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                optiondata.enabled = true;
                return optiondata;
              }
            );

            data.PackSizeOptions = [
              ...data.PackSizeOptions,
              {
                packSizeOptionName: "",
                PsnPrime: false,
                Mrp: "",
                Price: "",
                Discount: "",
                enabled: false,
              },
            ];
          }

          return data;
        });

        setPacksize(optionList);
        setoptionPress(false);
      } else {
        optionList = optionList.map((data, index) => {
          if (index === currentIndex) {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                if (opIndex === lastIndex) {
                  optiondata.Price = "";
                }

                return optiondata;
              }
            );
          }
          return data;
        });
        setPacksize(optionList);
        setoptionPress(true);
      }
    } else {
      setoptionPress(true);
    }
  };

  const removeProductOptionClick = (e, crrentindex, curroptionIndex) => {
    let optionList = [...packsize];

    const currEditPackSizeIndex = optionList.findIndex(
      (data) => data.enabled === false
    );
    optionList = optionList.map((data, Index) => {
      if (Index === currEditPackSizeIndex) {
        data.PackSizeOptions = data.PackSizeOptions.filter(
          (data, optionindex) => optionindex !== curroptionIndex
        );
        const PackOptionLastIndex = data.PackSizeOptions.length - 1;
        data.PackSizeOptions[PackOptionLastIndex].enabled = false;
        const findprime = data.PackSizeOptions.find(
          (data) => data.PsnPrime === true
        );
        if (!findprime) {
          data.PackSizeOptions[PackOptionLastIndex].PsnPrime = true;
        }
      }
      return data;
    });
    setPacksize(optionList);
  };

  const editProductOptionClick = (e, currentIndex, curroptionIndex) => {
    let optionList = [...packsize];
    const packOptions = optionList[currentIndex].PackSizeOptions;
    const lastIndex = packOptions.length - 1;
    setcurrentPacksizeIndex(currentIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(lastIndex);
    setoptionPress(true);

    if (packOptions[lastIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[lastIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[lastIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }

    if (
      packOptions[lastIndex].packSizeOptionName !== "" &&
      packOptions[lastIndex].Mrp !== "" &&
      packOptions[lastIndex].Price !== ""
    ) {
      optionList = optionList.map((data, index) => {
        if (index === currentIndex) {
          data.PackSizeOptions = data.PackSizeOptions.map(
            (optiondata, opIndex) => {
              optiondata.enabled = true;
              return optiondata;
            }
          );
          data.PackSizeOptions[curroptionIndex].enabled = false;
        }

        return data;
      });
      setPacksize(optionList);
      setoptionPress(false);
    }
  };

  const handleNewPackSize = () => {
    // const lastIndex = packsize.length - 1;
    const lastIndex = packsize.findIndex((data) => data.enabled === false);
    let optionList = [...packsize];
    const packOptions = optionList[lastIndex].PackSizeOptions;
    const findOptionIndex = packOptions.findIndex(
      (data) => data.enabled === false
    );
    setoptionSizePress(true);
    setpacksizeIndex(lastIndex);
    setpacksizeError("");
    setcurrentPacksizeIndex(lastIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(findOptionIndex);
    setoptionPress(true);

    if (packsize[lastIndex].packSizeName === "") {
      setpacksizeError("error");
    } else {
      setpacksizeError("");
    }

    if (packOptions[findOptionIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[findOptionIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[findOptionIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }
    if (
      packsize[lastIndex].packSizeName !== "" &&
      packOptions[findOptionIndex].packSizeOptionName !== "" &&
      packOptions[findOptionIndex].Mrp !== "" &&
      packOptions[findOptionIndex].Price !== ""
    ) {
      if (
        Number(packOptions[findOptionIndex].Mrp) >=
        Number(packOptions[findOptionIndex].Price)
      ) {
        optionList = optionList.map((data) => {
          data.enabled = true;
          data.PackSizeOptions = data.PackSizeOptions.map((optionPack) => {
            optionPack.enabled = true;
            return optionPack;
          });

          return data;
        });
        setPacksize([
          ...packsize,
          {
            packSizeName: "",
            PsPrime: false,
            enabled: false,
            thumbnail: "",
            slider: [],
            PackSizeOptions: [
              {
                packSizeOptionName: "",
                PsnPrime: true,
                Mrp: "",
                Price: "",
                Discount: "",
                enabled: false,
              },
            ],
          },
        ]);

        setoptionSizePress(false);
        setoptionPress(false);
      } else {
        optionList = optionList.map((data, index) => {
          if (index === lastIndex) {
            data.PackSizeOptions[findOptionIndex].Price = "";
          }
          return data;
        });
        setPacksize(optionList);
        setoptionPress(true);
        setoptionSizePress(true);
      }
    } else {
      setoptionPress(true);
      setoptionSizePress(true);
    }
  };

  const removePackSize = (currentPackindex) => {
    let optionList = [...packsize];
    if (packsize.length > 1) {
      const currEditPackSizeIndex = optionList.findIndex(
        (data) => data.enabled === false
      );
      if (currEditPackSizeIndex === currentPackindex) {
        optionList = optionList.filter(
          (data, index) => index !== currentPackindex
        );
        const lastindex = optionList.length - 1;
        const lasrPackOptionindex =
          optionList[lastindex].PackSizeOptions.length - 1;
        optionList[lastindex].enabled = false;
        optionList[lastindex].PackSizeOptions[
          lasrPackOptionindex
        ].enabled = false;

        const findPrime = optionList.find((data) => data.PsPrime === true);

        if (!findPrime) {
          optionList[0].PsPrime = true;
        }
      } else {
        optionList = optionList.filter(
          (data, index) => index !== currentPackindex
        );
        const findPrime = optionList.find((data) => data.PsPrime === true);
        if (!findPrime) {
          optionList[0].PsPrime = true;
        }
      }

      setPacksize(optionList);
    }
  };

  const editPackSize = (currentselecPackIndex) => {
    const lastIndex = packsize.findIndex((data) => data.enabled === false);
    let optionList = [...packsize];
    const packOptions = optionList[lastIndex].PackSizeOptions;
    const findOptionIndex = packOptions.findIndex(
      (data) => data.enabled === false
    );
    setoptionSizePress(true);
    setpacksizeIndex(lastIndex);
    setpacksizeError("");
    setcurrentPacksizeIndex(lastIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(findOptionIndex);
    setoptionPress(true);

    if (packsize[lastIndex].packSizeName === "") {
      setpacksizeError("error");
    } else {
      setpacksizeError("");
    }

    if (packOptions[findOptionIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[findOptionIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[findOptionIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }
    if (
      packsize[lastIndex].packSizeName !== "" &&
      packOptions[findOptionIndex].packSizeOptionName !== "" &&
      packOptions[findOptionIndex].Mrp !== "" &&
      packOptions[findOptionIndex].Price !== ""
    ) {
      optionList = optionList.map((data, index) => {
        data.enabled = true;
        data.PackSizeOptions = data.PackSizeOptions.map((optionPack) => {
          optionPack.enabled = true;
          return optionPack;
        });

        const lasrPackOptionindex =
          optionList[currentselecPackIndex].PackSizeOptions.length - 1;
        optionList[currentselecPackIndex].enabled = false;
        optionList[currentselecPackIndex].PackSizeOptions[
          lasrPackOptionindex
        ].enabled = false;

        return data;
      });
      setPacksize(optionList);
      setoptionSizePress(false);
      setoptionPress(false);
    }
  };

  return (
    <div>
      {packsize.map((packDetails, index) => (
        <div key={index}>
          <>
            <Row className="mb-4">
              <Col span={24}>
                <div className="">
                  <Typography.Title level={5}>
                    {index + 1}) Product Size Name{" "}
                  </Typography.Title>
                  <Input
                    status={
                      packsizeError !== "" && packsizeIndex === index
                        ? "error"
                        : ""
                    }
                    suffix={
                      packsizeError !== "" && packsizeIndex === index ? (
                        <MdOutlineErrorOutline />
                      ) : (
                        ""
                      )
                    }
                    placeholder="enter product name"
                    type="text"
                    value={packDetails?.packSizeName}
                    disabled={packDetails?.enabled === true}
                    onChange={(e) =>
                      handlePackCheck(index, e, "packSizeName", false, "", "")
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col span={8}>
                <div className="">
                  <Typography.Title level={5}>Pack Size Prime</Typography.Title>
                  <Input
                    type="checkbox"
                    // className="me-1"
                    value="trending"
                    disabled={packDetails?.enabled === true}
                    checked={packDetails?.PsPrime === true}
                    onChange={(e) =>
                      handlePackCheck(index, true, "PsPrime", false, "", "")
                    }
                  />
                </div>
              </Col>
            </Row>
            {packDetails?.PackSizeOptions.map((otion, optionindex) => (
              <>
                <div key={optionindex}>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={6}>
                      <div className="">
                        <Typography.Title level={5}>
                          Option Name <span style={{ color: "red" }}>*</span>
                        </Typography.Title>
                        <Input
                          status={
                            optionNameError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex
                              ? "error"
                              : ""
                          }
                          suffix={
                            optionNameError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex ? (
                              <MdOutlineErrorOutline />
                            ) : (
                              ""
                            )
                          }
                          placeholder="enter  Option Name"
                          type="text"
                          disabled={otion?.enabled === true}
                          value={otion?.packSizeOptionName}
                          onChange={(e) =>
                            handlePackCheck(
                              index,
                              e,
                              "PackSizeOptions",
                              true,
                              optionindex,
                              "packSizeOptionName"
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div className="">
                        <Typography.Title level={5}>
                          Mrp
                          <span style={{ color: "red" }}>*</span>
                        </Typography.Title>
                        <Input
                          status={
                            mrpError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex
                              ? "error"
                              : ""
                          }
                          suffix={
                            mrpError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex ? (
                              <MdOutlineErrorOutline />
                            ) : (
                              ""
                            )
                          }
                          placeholder="enter Mrp"
                          type="text"
                          value={otion?.Mrp}
                          disabled={otion?.enabled === true}
                          onChange={(e) =>
                            handlePackCheck(
                              index,
                              e,
                              "PackSizeOptions",
                              true,
                              optionindex,
                              "Mrp"
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div className="">
                        <Typography.Title level={5}>
                          Price
                          <span style={{ color: "red" }}>*</span>
                        </Typography.Title>
                        <Input
                          status={
                            PriceError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex
                              ? "error"
                              : ""
                          }
                          suffix={
                            PriceError !== "" &&
                            currentPacksizeIndex === index &&
                            optionIndex === optionindex ? (
                              <MdOutlineErrorOutline />
                            ) : (
                              ""
                            )
                          }
                          placeholder="enter Price"
                          type="text"
                          value={otion?.Price}
                          disabled={otion?.enabled === true}
                          max={otion?.Mrp}
                          min="0"
                          onChange={(e) =>
                            handlePackCheck(
                              index,
                              e,
                              "PackSizeOptions",
                              true,
                              optionindex,
                              "Price"
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div className="">
                        <Typography.Title level={5}>Prime</Typography.Title>
                        <Input
                          type="checkbox"
                          className="me-1"
                          value="trending"
                          disabled={otion?.enabled === true}
                          checked={otion?.PsnPrime === true}
                          onChange={(e) => {
                            handlePackCheck(
                              index,
                              true,
                              "PackSizeOptions",
                              true,
                              optionindex,
                              "PsnPrime"
                            );
                          }}
                        />
                      </div>
                    </Col>

                    {packDetails?.PackSizeOptions.length !== 1 &&
                    packDetails?.enabled === false ? (
                      <>
                        <>
                          <Col className="gutter-row" span={6}>
                            <Space>
                              <Button
                                type="primary"
                                style={{
                                  background: "red",
                                  border: "1px solid red",
                                }}
                                onClick={(e) =>
                                  removeProductOptionClick(
                                    e,
                                    index,
                                    optionindex
                                  )
                                }
                              >
                                {" "}
                                <MdOutlineRemove />
                              </Button>
                              <Button
                                type="primary ml-3"
                                onClick={(e) =>
                                  editProductOptionClick(e, index, optionindex)
                                }
                              >
                                <CiEdit style={{ fontSize: "18px" }} />
                              </Button>
                            </Space>
                          </Col>
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
              </>
            ))}

            {packDetails?.enabled === false ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  {packsize.length > 1 ? (
                    <>
                      <Button
                        className="btn  btn-danger m-3"
                        onClick={() => removePackSize(index)}
                      >
                        Remove Option
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}

                  <Button
                    className="btn  btn-primary m-3"
                    onClick={(e) => handleNewPackOption(e, index)}
                  >
                    Add Option
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    className="btn  btn-danger m-3"
                    onClick={() => removePackSize(index)}
                  >
                    Remove Product Option
                  </Button>
                  <Button
                    className="btn  btn-primary m-3"
                    onClick={() => editPackSize(index)}
                  >
                    Edit Product Option
                  </Button>
                </div>
              </>
            )}
          </>
        </div>
      ))}

      {/* <ReactTooltip
        id="my-tooltip-1"
        place="top"
        variant="info"
        content="Academic match is provided on a rating scale from 0-100 (100 is the best match) "
        style={{
          width: "50vh",
        }}
      /> */}

      <Button
        className="btn  btn-primary m-3 "
        onClick={(e) => handleNewPackSize(e)}
      >
        Add More Product Pack size
      </Button>
    </div>
  );
};

export default ProductPacksize;
