import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  seller_details: localStorage.getItem("seller_details")
    ? JSON.parse(localStorage.getItem("seller_details"))
    : "",
  login_Data: localStorage.getItem("login_Data")
    ? JSON.parse(localStorage.getItem("login_Data"))
    : "",
  isAuth: localStorage.getItem("login_Data")
    ? JSON.parse(localStorage.getItem("login_Data")).isAuth
    : false,
  seller_Registation_Loading: true,
  seller_Registation_status: localStorage.getItem("seller_details")
    ? JSON.parse(localStorage.getItem("seller_details")).shop_form_status
    : 0,
};

export const seller_Sms_OTP = createAsyncThunk(
  "auth/seller_Sms_OTP",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/sendsms`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("shop Not create");
    }
  }
);
export const seller_registation = createAsyncThunk(
  "auth/seller_registation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/shop/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("shop Not create");
    }
  }
);

export const seller_Login = createAsyncThunk(
  "auth/seller_Login",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/shop/loginbypassword`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("shop Not create");
    }
  }
);

export const seller_Update = createAsyncThunk(
  "auth/seller_Update",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/shop/updateby/${formData.id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("seller Not create");
    }
  }
);

export const validateEmails = createAsyncThunk(
  "authentication/validateEmails",
  async (email, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/shop/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const validateMobile = createAsyncThunk(
  "authentication/validateMobile",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/shop/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validateshopgst = createAsyncThunk(
  "authentication/validateshopgst",
  async (shopgst, thunkAPI) => {
    let resp = {
      success: false,
      message: "new shopgst",
    };
    try {
      const url = `${Baseurl}/api/v1/shop/shopgst/${shopgst}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const validateshopdisplayname = createAsyncThunk(
  "authentication/validateshopdisplayname",
  async (shopdisplayname, thunkAPI) => {
    let resp = {
      success: false,
      message: "new shopdisplayname",
    };
    try {
      const url = `${Baseurl}/api/v1/shop/shop-display-name/${shopdisplayname}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);


const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    signin(state, action) {
      state.login_Data = action.payload

      state.isAuth = true
      localStorage.setItem("login_Data", JSON.stringify(action.payload));
    },
    signout(state, action) {
      localStorage.removeItem("login_Data");
      state.isAuth = false;
    },
    update_registation_status(state, action) {
      state.seller_Registation_status = action.payload;
    },
    update_seller_details(state, action) {
      state.seller_details = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(seller_registation.pending, (state) => {
        state.seller_Registation_Loading = true;
      })
      .addCase(seller_registation.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.seller_details = action.payload.shop
          localStorage.setItem(
            "seller_details",
            JSON.stringify(state.seller_details)
          );
        }
        state.seller_Registation_Loading = false;
      })
      .addCase(seller_registation.rejected, (state) => {
        state.seller_Registation_Loading = true;
      });
    builder
      .addCase(seller_Update.pending, (state) => {
        state.seller_Registation_Loading = true;
      })
      .addCase(seller_Update.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.seller_details = action.payload.shop
          state.seller_Registation_status = 0
          localStorage.setItem(
            "seller_details",
            JSON.stringify(state.seller_details)
          );
        }
        state.seller_Registation_Loading = false;
      })
      .addCase(seller_Update.rejected, (state) => {
        state.seller_Registation_Loading = true;
      });
  }
});

export const { signin, signout, update_registation_status, update_seller_details } = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
