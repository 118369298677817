import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Input, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineErrorOutline } from "react-icons/md";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import {
  setProduct_information,
  validateProductSlugUrl,
} from "../../../redux/product/productSlice";
const { TextArea } = Input;
const ProductInformation = ({
  get_product_ifo_data,
  product_ino_buttonPress,
}) => {
  const dispatch = useDispatch();

  const { product_information } = useSelector((store) => store.product);

  const [productname, setProductname] = useState("");
  const [checkUrl, setCheckUrl] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");

  const [ProductDetails, setProductDetails] = useState("");
  const [ProductDetailsError, setProductDetailsError] = useState("");

  const [description, setdescription] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [specification, setspecification] = useState("");
  const [specificationError, setspecificationError] = useState("");
  const [Dimension, setDimension] = useState("");
  const [DimensionError, setDimensionError] = useState("");
  const [manufacturing_company, setmanufacturing_company] = useState("");
  const [manufacturing_companyError, setmanufacturing_companyError] =
    useState("");
  const [cover_in_warranty, setcover_in_warranty] = useState("");
  const [cover_in_warrantyError, setcover_in_warrantyError] = useState("");
  const [return_days, setreturn_days] = useState("");
  const [return_daysError, setreturn_daysError] = useState("");

  useEffect(() => {
    if (product_information !== "") {
      setProductname(product_information.product_name);
      setCheckUrl(product_information.slugurl);
      setProductDetails(product_information.ProductDetails);
      setdescription(product_information.description);
      setspecification(product_information.specification);
      setDimension(product_information.Dimension);
      setmanufacturing_company(product_information.manufacturing_company);
      setcover_in_warranty(product_information.cover_in_warranty);
      setreturn_days(product_information.return_days);
    }
  }, [product_information]);

  useEffect(() => {
    if (product_ino_buttonPress === true) {
      if (checkUrl.trim() === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (ProductDetails.trim() === "") {
        setProductDetailsError("Required");
      } else {
        setProductDetailsError("");
      }
      if (description.trim() === "") {
        setdescriptionError("Required");
      } else {
        setdescriptionError("");
      }
      if (manufacturing_company.trim() === "") {
        setmanufacturing_companyError("Required");
      } else {
        setmanufacturing_companyError("");
      }
      if (cover_in_warranty.trim() === "") {
        setcover_in_warrantyError("Required");
      } else {
        setcover_in_warrantyError("");
      }
      if (return_days.trim() === "") {
        setreturn_daysError("Required");
      } else {
        setreturn_daysError("");
      }
    }
  }, [
    product_ino_buttonPress,
    checkUrl,
    ProductDetails,
    description,
    manufacturing_company,
    return_days,
  ]);

  const verifyUrl = async (e) => {
    const value = e.target.value;
    setProductname(value);
    setCheckUrl("");
    const groceryUrl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");

    if (groceryUrl !== "") {
      let response = await dispatch(validateProductSlugUrl(groceryUrl));
      if (response.payload.success === true) {
        setSlugUrlError("Product Already exist");
        setCheckUrl("");
      } else {
        setSlugUrlError("");
        setCheckUrl(groceryUrl);
      }
    }
  };

  useEffect(() => {
    if (
      checkUrl !== "" &&
      ProductDetails !== "" &&
      description !== "" &&
      cover_in_warranty !== "" &&
      return_days !== "" &&
      manufacturing_company != ""
    ) {
      const form_data = {
        product_name: productname,
        slugurl: checkUrl,
        ProductDetails: ProductDetails,

        description: description,
        specification: specification,
        Dimension: Dimension,
        manufacturing_company: manufacturing_company,
        cover_in_warranty: cover_in_warranty,
        return_days: return_days,
      };
      get_product_ifo_data(form_data, true);
   
    }
  }, [
    productname,
    checkUrl,
    ProductDetails,
    description,
    manufacturing_company,
    cover_in_warranty,
    return_days,
  ]);

  return (
    <>
      <Row className="mb-4">
        <Col span={24}>
          <div className="">
            <Input
              status={slugUrlError !== "" ? "error" : ""}
              suffix={slugUrlError !== "" ? <MdOutlineErrorOutline /> : ""}
              type="text"
              placeholder="enter product name"
              value={productname}
              onChange={(e) => verifyUrl(e)}
            />
          </div>
        </Col>
      </Row>
      <Row className="">
        <Col span={24}>
          <div className="">
            <TextArea
              allowClear
              placeholder="enter product details"
              status={ProductDetailsError !== "" ? "error" : ""}
              value={ProductDetails}
              onChange={(e) => setProductDetails(e.target.value)}
              suffix={
                ProductDetailsError !== "" ? <MdOutlineErrorOutline /> : ""
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <TextArea
              allowClear
              placeholder="enter product description"
              status={descriptionError !== "" ? "error" : ""}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              suffix={descriptionError !== "" ? <MdOutlineErrorOutline /> : ""}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <TextArea
              allowClear
              placeholder="enter product manufacturing company"
              status={manufacturing_companyError !== "" ? "error" : ""}
              value={manufacturing_company}
              onChange={(e) => setmanufacturing_company(e.target.value)}
              suffix={
                manufacturing_companyError !== "" ? (
                  <MdOutlineErrorOutline />
                ) : (
                  ""
                )
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <TextArea
              allowClear
              placeholder="enter product cover in warranty"
              status={cover_in_warrantyError !== "" ? "error" : ""}
              value={cover_in_warranty}
              onChange={(e) => setcover_in_warranty(e.target.value)}
              suffix={
                cover_in_warrantyError !== "" ? <MdOutlineErrorOutline /> : ""
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <Input
              status={return_daysError !== "" ? "error" : ""}
              suffix={return_daysError !== "" ? <MdOutlineErrorOutline /> : ""}
              type="text"
              placeholder="enter product return days"
              value={return_days}
              onChange={(e) =>
                setreturn_days(e.target.value.replace(/\D/g, ""))
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <Input
              type="text"
              placeholder="enter product Dimension"
              value={Dimension}
              onChange={(e) => setDimension(e.target.value.replace(/\D/g, ""))}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <div className="">
            <TextArea
              allowClear
              placeholder="enter product specification"
              value={specification}
              onChange={(e) => setspecification(e.target.value)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProductInformation;
