import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  brandtotal: localStorage.getItem("brandtotal")
    ? JSON.parse(localStorage.getItem("brandtotal")).sort()
    : [],
  brandShow: localStorage.getItem("brandtotal")
    ? JSON.parse(localStorage.getItem("brandtotal")).filter((data) => data.show === true).sort()
    : [],
  isLoading: true,
  brandLoading: true,
};

export const getBrand = createAsyncThunk("brand/getBrand", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/brand/all`;
    const resp = await axios(url);
    return resp.data.brands;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});


const BrandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrand.fulfilled, (state, action) => {
        state.brandtotal = action.payload.sort();
        state.brandShow = state.brandtotal.filter((data) => data.show === true);
        localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        state.isLoading = false;
        state.brandLoading = false;
      })
      .addCase(getBrand.rejected, (state) => {
        state.isLoading = true;
      });
  },
});

export const {
} = BrandSlice.actions;
export default BrandSlice.reducer;
