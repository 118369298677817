import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  Space,
  Dropdown,
  message,
  Upload,
  Col,
  Row,
  Checkbox,
  Radio,
} from "antd";
const { Search } = Input;
const onClick = ({ key }) => {
  message.info(`Click on item ${key}`);
};

const items = [
  {
    label: "1st menu item",
    key: "1",
  },
  {
    label: "2nd menu item",
    key: "2",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const props = {
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const ApprovadPage = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <>
      <div className="returncontainer">
        <div style={{ width: "40%" }}>
          <h4>Returns</h4>
        </div>
        <div className="subreturncontainer">
          <h6 className="guidetext">Teturn Policy</h6>
          <h6 className="guidetext">Take a tour</h6>
          <h6 className="guidetext">Guide to Reduce Returns</h6>
          <Space.Compact>
            <Search placeholder="input search text" allowClear />
          </Space.Compact>
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space
                style={{
                  fontSize: "12px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  color: "#666",
                }}
              >
                HYDERABAD:500016
                <DownOutlined color="#666" />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="returncontainer1">
        <div className="subreturncontainer2">
          <h6 className="guidetext2">Reaching you today</h6>
          <h6 className="guidetext1">Approved</h6>
          <h6 className="guidetext2">In Transit</h6>
          <h6 className="guidetext2">Completed</h6>
        </div>
        <div className="requestcontainer">Request OTC</div>
      </div>
      <div className="return_container">
        <div className="pickuptext">PICKUP 0</div>
        <div className="pickuptext1">OUT FOR DELIVERY 0</div>
      </div>
      <div className="return_container">
        <div className="pickuptext">DELIVERED 0</div>
        <div className="pickuptext1">UNDELIVERED 0</div>
      </div>
      <div className="returncontainer2">
        <div className="subreturncontainer3">
          <h6 className="guidetext2">FILTER</h6>
          <h6 className="guidetext2">Reset</h6>
          <h6 className="guidetext1">TOTAL COUNT :0</h6>
        </div>
        {/* <div className="requestcontainer"> */}
        <Upload {...props}>
          <Button
            icon={<UploadOutlined />}
            style={{ fontSize: "10px", fontWeight: "600" }}
          >
            Export
          </Button>
        </Upload>
        {/* </div> */}
      </div>

      <div>
        <Row>
          <Col span={18} push={6}>
            <p style={{ textAlign: "center" }}>No data to display</p>
          </Col>
          <Col span={6} pull={18}>
            <Space.Compact>
              <Search placeholder="Search SKU" allowClear />
            </Space.Compact>
            <div style={{ margin: "10px" }}>
              <p className="returnstext">RETURNS APPROVED</p>
              <Input
                placeholder="On/From-To"
                style={{ width: "70%", color: "#000" }}
              />
            </div>
            <div>
              <div style={{ margin: "10px" }}>
                <Dropdown
                  menu={{
                    items,
                    onClick,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space
                      style={{
                        fontSize: "10px",
                        padding: "5px",
                        color: "#666",
                        width: "100%",
                      }}
                    >
                      PHYSICAL DELIVERY
                      <DownOutlined color="#666" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <div style={{ margin: "10px" }}>
                <Checkbox onChange={onChange} style={{ fontSize: "10px" }}>
                  Expect
                </Checkbox>
              </div>
              <div style={{ margin: "10px" }}>
                <Checkbox onChange={onChange} style={{ fontSize: "10px" }}>
                  Don't Expect
                </Checkbox>
              </div>
            </div>
            <div>
              <div style={{ margin: "10px" }}>
                <Dropdown
                  menu={{
                    items,
                    onClick,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space
                      style={{
                        fontSize: "10px",
                        padding: "5px",
                        color: "#666",
                        width: "100%",
                      }}
                    >
                      FULFILLMENT TYPE
                      <DownOutlined color="#666" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <div style={{ margin: "10px" }}>
                <Checkbox onChange={onChange} style={{ fontSize: "10px" }}>
                  Non-FA
                </Checkbox>
              </div>
              <div style={{ margin: "10px" }}>
                <Checkbox onChange={onChange} style={{ fontSize: "10px" }}>
                  FA
                </Checkbox>
              </div>
            </div>
            <div style={{ margin: "10px" }}>
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    style={{
                      fontSize: "10px",
                      padding: "5px",
                      color: "#666",
                      width: "100%",
                    }}
                  >
                    SORT BY
                    <DownOutlined color="#666" />
                  </Space>
                </a>
              </Dropdown>
              {/* <div> */}
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1} style={{ fontSize: "10px" }}>
                    Latest Approval Date
                  </Radio>
                  <Radio value={2} style={{ fontSize: "10px" }}>
                    Oldest Approval Date
                  </Radio>
                </Space>
              </Radio.Group>
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ApprovadPage;
