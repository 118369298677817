import React, { useEffect, useState } from "react";
import {
  CheckCircleFilled,
  InfoCircleFilled,
  RightOutlined,
  HeartFilled,
} from "@ant-design/icons";
import {
  Card,
  Cascader,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Select,
  Typography,
  Button,
  Form,
  Flex,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdd_prduct_steps,
  setProduct_basic_details,
} from "../../redux/product/productSlice";

const { Option } = Select;

const Step_1_BasicDetails = () => {
  const dispatch = useDispatch();

  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");

  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [brandError, setbrandError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);

  useEffect(() => {
    setCategoryTagid("");
    setCategoryTag("");
  }, [supercategoryid, categoryid, subcategoryid]);

  useEffect(() => {
    if (buttonPress === true) {
      if (supercategoryid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (categoryTagid === "") {
        setcategoryTagError("Required");
      } else {
        setcategoryTagError("");
      }
      if (brand === "") {
        setbrandError("Required");
      } else {
        setbrandError("");
      }
    }
  }, [
    buttonPress,
    subcategoryid,
    categoryid,
    supercategoryid,
    brand,
    categoryTagid,
  ]);

  useEffect(() => {
    if (superCatShow.length > 0) {
      setSupercategoryid(superCatShow[0]._id);
      setSupercategory(superCatShow[0].name);
    }
  }, [superCatShow]);

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
    if (catbysupercat.length > 0) {
      setCategoryid(catbysupercat[0]._id);
      setCategory(catbysupercat[0].name);
    }
  }, [supercategoryid, categoryShow]);

  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);

    if (subcatbycat.length > 0) {
      setSubcategoryid(subcatbycat[0]._id);
      setSubcategory(subcatbycat[0].name);
    }
  }, [categoryid, subcategoryShow]);

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);
  }, [subcategoryid, categoryTagShow]);

  const basic_details_click = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (supercategoryid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (categoryTagid === "") {
      setcategoryTagError("Required");
    } else {
      setcategoryTagError("");
    }

    if (
      supercategoryid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      categoryTagid !== ""
    ) {
      const formData = {
        superCategoryId: supercategoryid,
        superCategory: supercategory,
        category: category,
        categoryId: categoryid,
        SubCategory: subcategory,
        SubCategoryId: subcategoryid,
        CategoryTag: categoryTag,
        CategoryTagId: categoryTagid,
      };
      dispatch(setProduct_basic_details(formData));
      dispatch(setAdd_prduct_steps(3));
    }
  };

  return (
    <>
      <div className="main-container">
        <Divider />
        <div className="container-one">
          <Row gutter={24} className="basic_row w-100">
            <Col className="basic_col" span={4}>
              {superCatShow &&
                superCatShow.map((data, index) => (
                  <div
                    onClick={() => {
                      setSupercategoryid(data._id);
                      setSupercategory(data.name);
                    }}
                    className={
                      supercategoryid === data._id ? "item activeItem" : "item"
                    }
                    key={index}
                  >
                    <h3>{data.name}</h3>
                  </div>
                ))}
            </Col>
            <Col className="basic_col" span={4}>
              {catbysuper &&
                catbysuper.map((data, index) => (
                  <div
                    onClick={() => {
                      setCategoryid(data._id);
                      setCategory(data.name);
                    }}
                    className={
                      categoryid === data._id ? "item activeItem" : "item"
                    }
                    key={index}
                  >
                    <h3>{data.name}</h3>
                  </div>
                ))}
            </Col>
            <Col className="basic_col" span={4}>
              {subCat &&
                subCat.map((data, index) => (
                  <div
                    onClick={() => {
                      setSubcategoryid(data._id);
                      setSubcategory(data.name);
                    }}
                    className={
                      subcategoryid === data._id ? "item activeItem" : "item"
                    }
                    key={index}
                  >
                    <h3>{data.name}</h3>
                  </div>
                ))}
            </Col>
            <Col className="basic_col" span={4}>
              {categoryTagsShow &&
                categoryTagsShow.map((data, index) => (
                  <div
                    onClick={() => {
                      setCategoryTagid(data._id);
                      setCategoryTag(data.name);
                    }}
                    className={
                      categoryTagid === data._id ? "item activeItem" : "item"
                    }
                    key={index}
                  >
                    <h3>{data.name}</h3>
                  </div>
                ))}
            </Col>
            <Col span={8} className="main_field">
              <div className="topselling">
                {supercategoryid !== "" &&
                  categoryid !== "" &&
                  subcategoryid !== "" &&
                  categoryTagid !== "" && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleFilled className="checkicon" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "10px", fontWeight: "500" }}>
                          Please select a brand to start selling in this
                          vertical
                        </p>
                      </div>
                      <Flex
                        gap="small"
                        wrap
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={(e) => basic_details_click(e)}
                        >
                          Select Listing
                        </Button>
                      </Flex>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Step_1_BasicDetails;
