import { configureStore } from "@reduxjs/toolkit";
import { saveToLocalStorage, loadFromLocalStorage } from './LocatStorage';
import authenticationreducer from "./Athentication/AuthSlice";
import orderreducer from "./order/OrderSlice";
import productreducer from "./product/productSlice";
import MessurentsReducer from "./messuurementsandDropdowns/Messuremenrsslice";
import superCategoryReducer from "./superCategory/superCategorySlice";
import categoryReducer from "./category/categorySlice";
import subCategoriesReducer from "./subCategory/subCategorySlice";
import brandReducer from "./brand/brandSlice";
import categorytagReducer from "./categoryTags/CategoryTagsSlice";
import fieldStatusReducer from "./fieldStatus/FieldStatusSlice";


const persistedState = loadFromLocalStorage('myReduxState');

export const store = configureStore({
    reducer: {
        authentication: authenticationreducer,
        order: orderreducer,
        product: productreducer,
        Messurents: MessurentsReducer,
        superCategory: superCategoryReducer,
        category: categoryReducer,
        subCategories: subCategoriesReducer,
        brand: brandReducer,
        categorytag: categorytagReducer,
        fieldStatus: fieldStatusReducer,
    },
    persistedState
});
store.subscribe(() => {
    saveToLocalStorage('myReduxState', store.getState());
});