import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaUserGroup } from "react-icons/fa6";

const Status_Dashbord = () => {
  return (
    <>
      <div className="statusmaincontainer">
        <div className="statusmaincontainer1">
          <div>
            <img src="/assets/images/silver.png" className="img-fluid" />
          </div>
          <div className="statusmaincontainer2">
            <h6 className="statustext">Your Status Silver</h6>
            <h6 className="statustext1">Based on Performance from Merch 1st</h6>
            <h6 className="statustext1">May 28th 2024</h6>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <h5 className="dispatchtext">Dispatch Breaches</h5>
          <h5 className="dispatchtext">Seller Cancellations</h5>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ display:"flex" }}>
            <div>
              <img src="/assets/images/gold.png" height="30px" width="auto" />
            </div>
            <div>
              <h6 className="goldstatustext">
                You are at 0.00% Gold Tier- 1.00%
              </h6>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <img src="/assets/images/gold.png" height="30px" width="auto" />
            </div>
            <div>
              <h6 className="goldstatustext">
                You are at 0.00% Gold Tier- 1.00%
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="statusmaincontainer">
        <div className="promotiontext">EWShoopng Promotions</div>
        <div className="promotiontext1">Up to 20% off on Fashion</div>
        <div className="promotiontext1">Up to 15% off on Fashion</div>
        <div className="promotiontext1">Coupon- 10% off on Fashion</div>
      </div>
      <div className="statusmaincontainer">
        <div className="promotiontext">Useful Links</div>
        <FaGraduationCap />
        <div className="promotiontext1">EWShopping Learning Center</div>
        <AiOutlineGlobal />
        <div className="promotiontext1">Seller Community</div>
        <FaUserGroup />
        <div className="promotiontext1">Manage your profile</div>
      </div>
    </>
  );
};

export default Status_Dashbord;
