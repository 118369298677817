import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { BiCategory } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import StepComponent from "./StepComponent";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Link, useNavigate } from "react-router-dom";
import {
  seller_registation,
  seller_Sms_OTP,
  update_registation_status,
  validateEmails,
  validateMobile,
} from "../../../redux/Athentication/AuthSlice";

const Step_one = () => {
  const [mobile, setMobile] = useState("");
  const [mobile_confirm, setMobile_confirm] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [Email, setEmail] = useState("");
  const [Email_Confirm, setEmail_Confirm] = useState("");
  const [EmailError, setEmailError] = useState("");

  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  const [otpStatus, setOtpStatus] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    if (buttonPress === true) {
      if (Password.length <= 7) {
        setPasswordError("Please enter your Password length must be 7 letters");
      } else {
        setPasswordError("");
      }

      if (mobile_confirm.length !== 10) {
        setMobileError("Please enter your mobile number");
      } else {
        setMobileError("");
      }
      if (Email_Confirm === "") {
        setEmailError("Please enter your email ID");
      } else {
        setEmailError("");
      }
    }
  }, [buttonPress, mobile_confirm, Email_Confirm, Password]);

  const validateEmail = async (e) => {
    let inputValue = e.target.value;
    setEmail(inputValue);
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue)) {
      setEmailError("Please enter valid email");
      setEmail_Confirm("");
    } else {
      const emailValidate = await dispatch(validateEmails(inputValue));
      if (emailValidate.payload.success === false) {
        setEmailError("");
        setEmail_Confirm(inputValue);
      } else {
        setEmailError("Email already exist");
        setEmail_Confirm("");
      }
    }
  };
  const mobileValideteChange = async (e) => {
    let inputValue = e.replace(/\D/g, "");
    setMobile(inputValue);
    if (inputValue.length !== 10) {
      setMobileError("Please enter valid mobile number");
      setMobile_confirm("");
    } else {
      const mobileValidate = await dispatch(validateMobile(inputValue));
      if (mobileValidate.payload.success === false) {
        setMobileError("");
        setMobile_confirm(inputValue);
      } else {
        setMobileError("Mobile number already exist");
        setMobile_confirm("");
      }
    }
  };

  const verifyOtpClick = async (e) => {
    e.preventDefault();

    if (Password.length < 7) {
      setPasswordError("Please enter your Password length must be 7 letters");
    } else {
      setPasswordError("");
    }
    if (mobile_confirm.length !== 10) {
      setMobileError("Please enter your mobile number");
    } else {
      setMobileError("");
    }
    if (Email_Confirm === "") {
      setEmailError("Please enter your email ID");
    } else {
      setEmailError("");
    }

    if (
      Password.length >= 7 &&
      mobile_confirm.length === 10 &&
      Email_Confirm !== ""
    ) {
      setOtpStatus(true);
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }

      const formData = {
        mobile: mobile_confirm,
        OTP: OTP,
      };
      const register_otp = await dispatch(seller_Sms_OTP(formData));
      if (register_otp.payload.success) {
        setVerifyOtp(OTP);
      }
    }
  };

  const otpValideteChange = async (e) => {
    let inputValue = e.replace(/\D/g, "");
    setOtp(inputValue);
    if (inputValue.length !== 6) {
      setOtpError("otp must should be a 6 digit code");
    } else {
      setOtpError("");
    }
  };

  const createSellerClick = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (otp.length !== 6) {
      setOtpError("otp must should be a 6 digit code");
    } else {
      setOtpError("");
    }
    if (verifyOtp === otp) {
      const formData = {
        mobile: mobile_confirm,
        email: Email_Confirm,
        password: Password,
        shop_form_status: 1,
      };
      console.log(formData,"formData");
      const register_seller = await dispatch(seller_registation(formData));
      if (register_seller.payload.success) {
        dispatch(update_registation_status(1));
        addToast("Seller Create successfull", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
      }
    }else{
      setOtpError("Please enter correct otp")
    }
  };

  return (
    <>
      <div
        style={{
          margin: "auto",
          maxWidth: "800px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
        className="mobi_margin_only"
      >
        <div className="mobile_logo_login">
          <div>
            <img onClick={() => navigate("/")} src="/assets/images/ew10.png" />
          </div>
          <h4>Seller Panel</h4>
        </div>
        <StepComponent />
        <form autocomplete="off">
          {otpStatus === false ? (
            <>
              <div style={{ marginTop: "15px" }}>
                <TextField
                  label="Enter Mobile Number"
                  className="textfield "
                  type="text"
                  id="outlined"
                  error={mobileError !== "" ? true : false}
                  helperText={mobileError}
                  style={{ width: "100%" }}
                  value={mobile}
                  onChange={(e) => mobileValideteChange(e.target.value)}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                  }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Enter Email ID"
                  className="textfield"
                  type="email"
                  id="text"
                  style={{ width: "100%" }}
                  error={EmailError !== "" ? true : false}
                  helperText={EmailError}
                  onChange={(e) => validateEmail(e)}
                  value={Email}
                  autoComplete="off"
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Create Password *"
                  className="textfield"
                  type="password"
                  id="text"
                  error={PasswordError !== "" ? true : false}
                  helperText={PasswordError}
                  style={{ width: "100%" }}
                  value={Password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{
                    minLength: 7,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ marginTop: "15px" }}>
                <TextField
                  label="Enter Mobile Number"
                  className="textfield "
                  type="text"
                  id="outlined"
                  error={mobileError !== "" ? true : false}
                  helperText={mobileError}
                  style={{ width: "100%" }}
                  value={mobile}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                  }}
                  disabled
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Enter Email ID"
                  className="textfield"
                  type="email"
                  id="text"
                  style={{ width: "100%" }}
                  error={EmailError !== "" ? true : false}
                  helperText={EmailError}
                  onChange={(e) => validateEmail(e)}
                  value={Email}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Create Password *"
                  className="textfield"
                  type="password"
                  id="text"
                  error={PasswordError !== "" ? true : false}
                  helperText={PasswordError}
                  style={{ width: "100%" }}
                  value={Password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{
                    minLength: 7,
                  }}
                  disabled
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Enter OTP"
                  className="textfield"
                  type="text"
                  id="text"
                  style={{ width: "100%" }}
                  error={otpError !== "" ? true : false}
                  helperText={otpError}
                  onChange={(e) => otpValideteChange(e.target.value)}
                  value={otp}
                  autocomplete="off"
                />
              </div>
            </>
          )}
        </form>

        <div style={{ marginTop: "15px" }}>
          <h5 className="looktext2">
            By continuing, I agree to EWShopping{" "}
            <strong style={{ color: "#0066cc" }}>
              {" "}
              <Link
                style={{ textDecoration: "none", color: "#0066cc" }}
                to="https://ewshopping.com/TermsUse"
                target="blank"
              >
                Terms of use{" "}
              </Link>{" "}
            </strong>
            &{" "}
            <strong style={{ color: "#0066cc" }}>
              {" "}
              <Link
                style={{ textDecoration: "none", color: "#0066cc" }}
                to="https://ewshopping.com/PrivacyPolicy"
                target="blank"
              >
                {" "}
                Privacy Policy{" "}
              </Link>
            </strong>
          </h5>
        </div>
        <div className="resistercontainer1">
          {otpStatus === false ? (
            <>
              <div
                className="registercontainer1"
                onClick={(e) => verifyOtpClick(e)}
                style={{
                  cursor: "pointer",
                }}
              >
                <h5 className="registertext1">Verify OTP </h5>
                <FaArrowRightLong color="#fff" />
              </div>
            </>
          ) : (
            <>
              <div
                className="registercontainer"
                onClick={(e) => createSellerClick(e)}
                style={{
                  cursor: "pointer",
                }}
              >
                <h5 className="registertext">Register & Continue </h5>
                <FaArrowRightLong color="#fff" />
              </div>
            </>
          )}

          <span className="link_registered">
            <span
              style={{
                color: "#000",
              }}
            >
              You are already registered ?{" "}
            </span>
            <span
              onClick={() => navigate("/login")}
              style={{
                cursor: "pointer",
              }}
            >
              click here.
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Step_one;
