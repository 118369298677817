import React from "react";
import {
  CheckCircleFilled,
  InfoCircleFilled,
  RightOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Card, Cascader, Col, Divider, Input, Row, Space } from "antd";
const { Search } = Input;

const options = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];
const onChange = (value) => {
  console.log(value);
};
const onSearch = (value, _e, info) => console.log(info?.source, value);
const AddProduct_page2 = () => {
  return (
    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
      <div className="mylistingcontainer">
        <p className="mylistingtext">My Listings </p>
        <RightOutlined className="mylistingicon" />
        <p className="mylistingtext">Add New Listings</p>
        <RightOutlined className="mylistingicon" />
      </div>
      <div className="singlecontainer">
        <h5>Add a Single Listing</h5>
      </div>
      <div className="mainselectverticalcontainer">
        <div className="selectverticalcontainer">
          <div className="selectverticalcontainer1">
            <div className="one">1</div>
            <p className="verticaltext">SELECT VERTICAL</p>
          </div>
          <div className="selectverticalcontainer1">
            <div className="one">2</div>
            <p className="verticaltext">SELECT VERTICAL</p>
          </div>
          <div className="selectverticalcontainer1">
            <div className="one">3</div>
            <p className="verticaltext">SELECT VERTICAL</p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="savegocontainer">
            <p className="savegocontainertext">Save & Go Back</p>
          </div>
          <div className="sendtocontainer">
            <p className="sendtocontainertext">SEND TO OK</p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <h6 className="selectthetext">
            Select The Vertical For Your Product
          </h6>
          <p className="optionstext">
            You can use the Search or Browse options
          </p>
        </div>
        <div className="searchcontainer">
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            enterButton
          />
        </div>
      </div>

      <div className="main-container">
        <Divider />
        <div className="container-one">
          <Row>
            <Col
              xs={24}
              style={{
                height: "400px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              sm={24}
              md={24}
              lg={12}
              xl={4}
            >
              <div className="heading">
                <p>Browser Categories</p>
              </div>
              <Cascader
                options={options}
                onChange={onChange}
                placeholder="Please select"
              />
              {/* <div className="item">
                <h3>Top Selling</h3>
              </div>
              <div className="item">
                <h3>Home and Office Furniture</h3>
              </div>
              <div className="item">
                <h3>Automotive Care & Accessories</h3>
              </div>
              <div className="item">
                <h3>Sports & Fitness</h3>
              </div>
              <div className="item">
                <h3>Home Kitchen Accessories</h3>
              </div>
              <div className="item">
                <h3>Automotive Care & Accessories</h3>
              </div>
              <div className="item">
                <h3>Sports & Fitness</h3>
              </div>
              <div className="item">
                <h3>Home Kitchen Accessories</h3>
              </div> */}
            </Col>
            <Col xs={24} style={{}} sm={24} md={24} lg={12} xl={20}>
              <div className="heading">
                <p>Top Categories</p>
              </div>
              <div className="container-two">
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>

                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
                <Card className="cardcontainer2">
                  <div>
                    <div className="kid-card">
                      <span>Kid Bean Bag</span>
                      <span>
                        <HeartFilled className="heart" />
                      </span>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <p>
                        Kid Bean Bag refers to a bean bag with a size in
                        accordance to kids.
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddProduct_page2;
