import React, { useState, useEffect } from "react";
import { Button, Flex, Form, Select, Col, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../redux/product/productSlice";
const { Option } = Select;

const MedicienSupercat = ({ buttonPress, getPropDetails, resetield }) => {
  const dispatch = useDispatch();
  const { product_basic_details } = useSelector((store) => store.product);
  const {
    romanTypeMessurements,
    sleevestype,
    top_shapes,
    top_types,
    bottom_styles_with_kurti,
    Dupattaavailable,
    pritsandPatterns,
    dress_designs,
    hemline_styles,
    length_styles,
    necktype,
    occasion_styles,
    embellishment_types,
    wave_patterns,
    wave_types,
    pattern_coverage,
    sleeve_styles,
    no_of_item,
    saree_types,
    saree_border_styles,
    fabric_types,
    Blouseavailable,
    fabric_care_instructions,
    fastening_types,
    transparency_levels,
    main_trends,
  } = useSelector((store) => store.Messurents);

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);

  const [manufacturer_marketer, setManufacturer_marketer] = useState("");
  const [manufacturer_marketererror, setManufacturer_marketererror] =
    useState("");
  const [consume_type, setConsume_type] = useState("");
  const [consume_typeerror, setConsume_typeerror] = useState("");
  const [return_policy, setReturn_policy] = useState("");
  const [return_policyerror, setReturn_policyerror] = useState("");
  const [expires_on_or_after, setExpires_on_or_after] = useState("");
  const [expires_on_or_aftererror, setExpires_on_or_aftererror] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionerror, setDescriptionerror] = useState("");
  const [key_benefits, setKey_benefits] = useState("");
  const [key_benefitserror, setKey_benefitserror] = useState("");
  const [direction_for_use, setDirection_for_use] = useState("");
  const [direction_for_useerror, setDirection_for_useerror] = useState("");
  const [safety_information, setSafety_information] = useState("");
  const [safety_informationerror, setSafety_informationerror] = useState("");
  const [size, setSize] = useState("");
  const [sizeerror, setSizeerror] = useState("");
  const [selectd_pack_size, setSelectd_pack_size] = useState("");
  const [selectd_pack_sizeerror, setSelectd_pack_sizeerror] = useState("");
  const [variant, setvVariant] = useState("");
  const [varianterror, setvVarianterror] = useState("");
  const [selected_flavour_fragrance, setSelected_flavour_fragrance] =
    useState("");
  const [selected_flavour_fragranceerror, setSelected_flavour_fragranceerror] =
    useState("");
  const [selected_variant, setSelected_variant] = useState("");
  const [selected_varianterror, setSelected_varianterror] = useState("");
  const [key_ingredients, setKey_ingredients] = useState("");
  const [key_ingredientserror, setKey_ingredientserror] = useState("");
  const [features, setFeatures] = useState("");
  const [featureserror, setFeatureserror] = useState("");
  const [flavour, setFlavour] = useState("");
  const [flavourerror, setFlavourerror] = useState("");
  const [composition, setComposition] = useState("");
  const [compositionerror, setCompositionerror] = useState("");
  const [storage, setStorage] = useState("");
  const [storageerror, setStorageerror] = useState("");

  useEffect(() => {
    if (product_basic_details !== "") {
      const getdata = async () => {
        const formData = {
          superCategoryId: product_basic_details.superCategoryId,
          categoryId: product_basic_details.categoryId,
          SubCategoryId: product_basic_details.SubCategoryId,
          CategoryTagId: product_basic_details.CategoryTagId,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          if (filterShow.length === 0) {
            const singleObject = {};
            getPropDetails(singleObject, true);
          }
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        }else {
          const singleObject = {};
          getPropDetails(singleObject, true);
        }
      };
      getdata();
    }
  }, [product_basic_details]);

  useEffect(() => {
    if (buttonPress === true) {
      if (manufacturer_marketer === "") {
        setManufacturer_marketererror("Required");
      } else {
        setManufacturer_marketererror("");
      }
      if (consume_type === "") {
        setConsume_typeerror("Required");
      } else {
        setConsume_typeerror("");
      }
      if (return_policy === "") {
        setReturn_policyerror("Required");
      } else {
        setReturn_policyerror("");
      }
      if (expires_on_or_after === "") {
        setExpires_on_or_aftererror("Required");
      } else {
        setExpires_on_or_aftererror("");
      }
      if (description === "") {
        setDescriptionerror("Required");
      } else {
        setDescriptionerror("");
      }
      if (key_benefits === "") {
        setKey_benefitserror("Required");
      } else {
        setKey_benefitserror("");
      }
      if (direction_for_use === "") {
        setDirection_for_useerror("Required");
      } else {
        setDirection_for_useerror("");
      }
      if (safety_information === "") {
        setSafety_informationerror("Required");
      } else {
        setSafety_informationerror("");
      }
      if (size === "") {
        setSizeerror("Required");
      } else {
        setSizeerror("");
      }
      if (selectd_pack_size === "") {
        setSelectd_pack_sizeerror("Required");
      } else {
        setSelectd_pack_sizeerror("");
      }
      if (variant === "") {
        setvVarianterror("Required");
      } else {
        setvVarianterror("");
      }
      if (selected_flavour_fragrance === "") {
        setSelected_flavour_fragranceerror("Required");
      } else {
        setSelected_flavour_fragranceerror("");
      }
      if (selected_variant === "") {
        setSelected_varianterror("Required");
      } else {
        setSelected_varianterror("");
      }
      if (key_ingredients === "") {
        setKey_ingredientserror("Required");
      } else {
        setKey_ingredientserror("");
      }
      if (features === "") {
        setFeatureserror("Required");
      } else {
        setFeatureserror("");
      }
      if (flavour === "") {
        setFlavourerror("Required");
      } else {
        setFlavourerror("");
      }
      if (composition === "") {
        setCompositionerror("Required");
      } else {
        setCompositionerror("");
      }
      if (storage === "") {
        setStorageerror("Required");
      } else {
        setStorageerror("");
      }
    }
  }, [
    buttonPress,
    manufacturer_marketer,
    consume_type,
    return_policy,
    expires_on_or_after,
    description,
    key_benefits,
    direction_for_use,
    safety_information,
    size,
    selectd_pack_size,
    variant,
    selected_flavour_fragrance,
    selected_variant,
    key_ingredients,
    features,
    flavour,
    composition,
    storage,
  ]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (distinctfieldValidate.every((field) => field.value !== "")) {
        let finalarray = [];
        for (let index = 0; index < distinctfieldValidate.length; index++) {
          let element = distinctfieldValidate[index];
          let obj = { [element.field]: element.value };
          finalarray = [...finalarray, obj];
        }
        finalarray = finalarray;

        const singleObject = finalarray.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});
        getPropDetails(singleObject, true);
      } else {
        console.log("fieldas are empty");
      }
    }
  }, [buttonPress, distinctfieldValidate]);

  return (
    <>
      {/* second part of 10-05-2024 */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Flex wrap="wrap">
          {distinctfield.includes("manufacturer_marketer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Manufacturer/Marketer"
                hasFeedback
                validateStatus={
                  manufacturer_marketererror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Manufacturer/Marketer"
                  onChange={(value) => {
                    setManufacturer_marketer(value);
                    updateValues("manufacturer_marketer", value);
                  }}
                  value={manufacturer_marketer}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={manufacturer_marketer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("consume_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Consume Type"
                hasFeedback
                validateStatus={consume_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Consume Type"
                  onChange={(value) => {
                    setConsume_type(value);
                    updateValues("consume_type", value);
                  }}
                  value={consume_type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={consume_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("return_policy") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Return Policy"
                hasFeedback
                validateStatus={return_policyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Return Policy"
                  onChange={(value) => {
                    setReturn_policy(value);
                    updateValues("return_policy", value);
                  }}
                  value={return_policy}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={return_policy === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("expires_on_or_after") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Expires On or After"
                hasFeedback
                validateStatus={expires_on_or_aftererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Expires On or After"
                  onChange={(value) => {
                    setExpires_on_or_after(value);
                    updateValues("expires_on_or_after", value);
                  }}
                  value={expires_on_or_after}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={expires_on_or_after === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("description") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Description"
                hasFeedback
                validateStatus={descriptionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Description"
                  onChange={(value) => {
                    setDescription(value);
                    updateValues("description", value);
                  }}
                  value={description}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={description === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("key_benefits") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Key Benefits"
                hasFeedback
                validateStatus={key_benefitserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Key Benefits"
                  onChange={(value) => {
                    setKey_benefits(value);
                    updateValues("key_benefits", value);
                  }}
                  value={key_benefits}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={key_benefits === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("direction_for_use") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Direction For Use"
                hasFeedback
                validateStatus={direction_for_useerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Direction For Use"
                  onChange={(value) => {
                    setDirection_for_use(value);
                    updateValues("direction_for_use", value);
                  }}
                  value={direction_for_use}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={direction_for_use === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("safety_information") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Safety Information"
                hasFeedback
                validateStatus={safety_informationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Safety Information"
                  onChange={(value) => {
                    setSafety_information(value);
                    updateValues("safety_information", value);
                  }}
                  value={safety_information}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={safety_information === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Size"
                hasFeedback
                validateStatus={sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Size"
                  onChange={(value) => {
                    setSize(value);
                    updateValues("size", value);
                  }}
                  value={size}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("selectd_pack_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Selectd Pack Size"
                hasFeedback
                validateStatus={selectd_pack_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Selectd Pack Size"
                  onChange={(value) => {
                    setSelectd_pack_size(value);
                    updateValues("selectd_pack_size", value);
                  }}
                  value={selectd_pack_size}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={selectd_pack_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("variant") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Variant"
                hasFeedback
                validateStatus={varianterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Variant"
                  onChange={(value) => {
                    setvVariant(value);
                    updateValues("variant", value);
                  }}
                  value={variant}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={variant === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("selected_flavour_fragrance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Selected Flavour Fragrance"
                hasFeedback
                validateStatus={
                  selected_flavour_fragranceerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Selected Flavour Fragrance"
                  onChange={(value) => {
                    setSelected_flavour_fragrance(value);
                    updateValues("selected_flavour_fragrance", value);
                  }}
                  value={selected_flavour_fragrance}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={selected_flavour_fragrance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("selected_variant") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Selected Variant"
                hasFeedback
                validateStatus={selected_varianterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Selected Variant"
                  onChange={(value) => {
                    setSelected_variant(value);
                    updateValues("selected_variant", value);
                  }}
                  value={selected_variant}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={selected_variant === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("key_ingredients") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Key Ingredients"
                hasFeedback
                validateStatus={key_ingredientserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Key Ingredients"
                  onChange={(value) => {
                    setKey_ingredients(value);
                    updateValues("key_ingredients", value);
                  }}
                  value={key_ingredients}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={key_ingredients === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Features"
                hasFeedback
                validateStatus={featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Features"
                  onChange={(value) => {
                    setFeatures(value);
                    updateValues("features", value);
                  }}
                  value={features}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("flavour") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flavour"
                hasFeedback
                validateStatus={flavourerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Flavour"
                  onChange={(value) => {
                    setFlavour(value);
                    updateValues("flavour", value);
                  }}
                  value={flavour}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flavour === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("composition") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Composition"
                hasFeedback
                validateStatus={compositionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Composition"
                  onChange={(value) => {
                    setComposition(value);
                    updateValues("composition", value);
                  }}
                  value={composition}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={composition === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("storage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Storage"
                hasFeedback
                validateStatus={storageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Storage"
                  onChange={(value) => {
                    setStorage(value);
                    updateValues("storage", value);
                  }}
                  value={storage}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={storage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
                  
        </Flex>
      </Row>
    </>
  );
};

export default MedicienSupercat;
