import React, { useState, useRef, useEffect } from "react";
import { FaCheckCircle, FaPlusCircle, FaEye, FaEdit } from "react-icons/fa";
import { MdBrowserUpdated } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Collapse, Space, Button, Affix } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { FaCheck } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import ProductInformation from "./components/ProductInformation";
import ProductDiscription from "./components/ProductDiscription";
import ProductPacksize from "./components/ProductPacksize";
import { useSelector, useDispatch } from "react-redux";
import {
  packsizeImages,
  setAdd_prduct_images,
  setProduct_description,
  setProduct_information,
  setProduct_options,
} from "../../redux/product/productSlice";

const Step_3_Product_info = () => {
  const dispatch = useDispatch();
  const image_select_1 = useRef(null);
  const image_select_2 = useRef(null);
  const image_select_3 = useRef(null);
  const image_select_4 = useRef(null);
  const image_select_5 = useRef(null);
  const image_select_6 = useRef(null);

  const {
    product_basic_details,
    product_Images,
    product_information,
    product_description,
    product_options,
  } = useSelector((store) => store.product);

  const [activeKey, setActiveKey] = useState(["0"]);
  const [resetield, setresetield] = useState(false);

  const [image_count, setimage_count] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [image_selected_1, setimage_selected_1] = useState(null);
  const [image_selected_2, setimage_selected_2] = useState(null);
  const [image_selected_3, setimage_selected_3] = useState(null);
  const [image_selected_4, setimage_selected_4] = useState(null);
  const [image_selected_5, setimage_selected_5] = useState(null);
  const [image_selected_6, setimage_selected_6] = useState(null);

  const [product_ino_buttonPress, setproduct_ino_buttonPress] = useState(false);
  const [product_info_daata, setproduct_info_daata] = useState("");
  const [product_info_daata_status, setproduct_info_daata_status] =
    useState("");

  const [product_description_buttonPress, setproduct_description_buttonPress] =
    useState(false);
  const [product_description_daata, setproduct_description_daata] =
    useState("");
  const [
    product_description_daata_status,
    setproduct_description_daata_status,
  ] = useState("");

  const [product_option_buttonPress, setproduct_option_buttonPress] =
    useState(false);
  const [product_option_daata, setproduct_option_daata] = useState([]);
  const [product_option_daata_status, setproduct_option_daata_status] =
    useState("");

  useEffect(() => {
    const find_count = [
      image_selected_1,
      image_selected_2,
      image_selected_3,
      image_selected_4,
      image_selected_5,
      image_selected_6,
    ].filter((field) => field !== null);
    setimage_count(Number(find_count.length));
  }, [
    image_selected_1,
    image_selected_2,
    image_selected_3,
    image_selected_4,
    image_selected_5,
    image_selected_6,
  ]);

  const handleChange = (key) => {
    setActiveKey(key);
  };

  const saveImage_click = () => {
    const all_images_array = [
      image_selected_1,
      image_selected_2,
      image_selected_3,
      image_selected_4,
      image_selected_5,
      image_selected_6,
    ].filter((field) => field !== null);
    dispatch(setAdd_prduct_images(all_images_array));
  };

  const get_product_ifo_data = (value, status) => {
    if (status === true) {
      setproduct_info_daata(value);
      setproduct_info_daata_status(status);
      // dispatch(setProduct_information(form_data));
    }
  };


  const get_product_description_data = (value, status) => {
    if (status === true) {
      setproduct_description_daata(value);
      setproduct_description_daata_status(status);
    }
  };
  const get_product_option_data = (value, status) => {
    if (status === true) {
      console.log(value, "value");
      setproduct_option_daata(value);
      setproduct_option_daata_status(status);
    }
  };
  const update_product_info_press = (e) => {
    setproduct_ino_buttonPress(true);
    if (product_info_daata_status === true && product_info_daata !== "") {
      dispatch(setProduct_information(product_info_daata));
    } else {
      e.stopPropagation();
    }
  };

  const product_discription_update = (e) => {
    setproduct_description_buttonPress(true);
    if (
      product_description_daata_status === true &&
      product_description_daata !== ""
    ) {
      dispatch(setProduct_description(product_description_daata));
    } else {
      e.stopPropagation();
    }
  };


  const product_options_update = (e) => {
    setproduct_option_buttonPress(true);
    if (
      product_option_daata_status === true &&
      product_option_daata.length > 0
    ) {
      dispatch(setProduct_options(product_option_daata));
    } else {
      e.stopPropagation();
    }
  };

  const items = [
    {
      key: "1",
      label:
        product_information !== "" ? (
          <span style={{ color: "green" }}>
            Product Information <FaRegCheckCircle />
          </span>
        ) : (
          <span>Product Information</span>
        ),
      children: (
        <>
          <ProductInformation
            get_product_ifo_data={get_product_ifo_data}
            product_ino_buttonPress={product_ino_buttonPress}
          />
        </>
      ),
      extra:
        activeKey[0] === "1" ? (
          <>
            <div>
              <Space>
                <Button
                  onClick={(e) => {
                    update_product_info_press(e);
                  }}
                  type="text"
                >
                  Save
                </Button>
              </Space>
            </div>
          </>
        ) : (
          <Button
            // onClick={(event) => {
            //   event.stopPropagation();
            // }}
            type="dashed"
          >
            Edit
          </Button>
        ),
    },
    {
      key: "2",
      label:
        product_description !== "" ? (
          <span style={{ color: "green" }}>
            Product Discription <FaRegCheckCircle />
          </span>
        ) : (
          <span>Product Discription</span>
        ),
      children: (
        <>
          <ProductDiscription
            get_product_description_data={get_product_description_data}
            product_description_buttonPress={product_description_buttonPress}
            supercategory={product_basic_details.superCategory}
            resetield={resetield}
          />
        </>
      ),
      extra:
        activeKey[0] === "2" ? (
          <>
            <div>
              <Space>
                <Button
                  onClick={(e) => {
                    product_discription_update(e);
                  }}
                  type="text"
                >
                  Save
                </Button>
              </Space>
            </div>
          </>
        ) : (
          <Button
            // onClick={(event) => {
            //   event.stopPropagation();
            // }}
            type="dashed"
          >
            Edit
          </Button>
        ),
    },
    {
      key: "3",
      label:
        product_options.length !== 0 ? (
          <span style={{ color: "green" }}>
            Product Pack Size <FaRegCheckCircle />
          </span>
        ) : (
          <span> Product Pack Size</span>
        ),
      children: (
        <>
          <ProductPacksize
            get_product_option_data={get_product_option_data}
            product_option_buttonPress={product_option_buttonPress}
          />
        </>
      ),
      extra:
        activeKey[0] === "3" ? (
          <>
            <div>
              <Space>
                <Button
                  onClick={(e) => {
                    product_options_update(e);
                  }}
                  type="text"
                >
                  Save
                </Button>
              </Space>
            </div>
          </>
        ) : (
          <Button
            // onClick={(event) => {
            //   event.stopPropagation();
            // }}
            type="dashed"
          >
            Edit
          </Button>
        ),
    },
  ];

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_1(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const image_change_2 = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_2(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const image_change_3 = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_3(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const image_change_4 = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_4(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const image_change_5 = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_5(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const image_change_6 = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState === 2) {
          const getImage = await dispatch(
            packsizeImages({ packsizeImage: reader.result })
          );
          if (getImage.payload.success) {
            let image = `${getImage.payload.packsizeImages}`;
            setimage_selected_6(image);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div className="info_container">
        {product_Images.length === 0 ? (
          <>
            <div className="info_container_left">
              {/* <Affix offsetTop={30}>  </Affix> */}
              <div className="no_img_div">
                <span className="image_name">
                  <FaCheckCircle className="image_title_icon" />
                  <span className="image_no_title">
                    Product Photos({image_count}/6)
                  </span>
                </span>
                <div className="save_image_div">
                  <div className="cancel_imag">
                    <span
                      onClick={() => {
                        setimage_selected_1(null);
                        setimage_selected_2(null);
                        setimage_selected_3(null);
                        setimage_selected_4(null);
                        setimage_selected_5(null);
                        setimage_selected_6(null);
                        dispatch(setAdd_prduct_images([]));
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </span>
                  </div>
                  {image_count !== 0 && (
                    <div
                      className="save_image"
                      style={{ cursor: "pointer" }}
                      onClick={() => saveImage_click()}
                    >
                      <span>Save</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="image_array_container">
                <div className="single_img_box">
                  <img
                    src={image_selected_1 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_1.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_1}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <span className="image_check_icon">
                    {image_selected_1 !== "" && image_selected_1 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
                <div className="single_img_box">
                  <img
                    src={image_selected_2 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_2.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_2}
                    style={{ display: "none" }}
                    onChange={image_change_2}
                  />

                  <span className="image_check_icon">
                    {image_selected_2 !== "" && image_selected_2 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
                <div className="single_img_box">
                  <img
                    src={image_selected_3 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_3.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_3}
                    style={{ display: "none" }}
                    onChange={image_change_3}
                  />

                  <span className="image_check_icon">
                    {image_selected_3 !== "" && image_selected_3 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
                <div className="single_img_box">
                  <img
                    src={image_selected_4 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_4.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_4}
                    style={{ display: "none" }}
                    onChange={image_change_4}
                  />

                  <span className="image_check_icon">
                    {image_selected_4 !== "" && image_selected_4 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
                <div className="single_img_box">
                  <img
                    src={image_selected_5 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_5.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_5}
                    style={{ display: "none" }}
                    onChange={image_change_5}
                  />

                  <span className="image_check_icon">
                    {image_selected_5 !== "" && image_selected_5 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
                <div className="single_img_box">
                  <img
                    src={image_selected_6 || "/assets/images/noproduct.png"}
                    alt="Click to select file"
                    onClick={() => image_select_6.current.click()}
                    className="imgbox_img"
                  />

                  {/* Hidden file input element */}
                  <input
                    type="file"
                    ref={image_select_6}
                    style={{ display: "none" }}
                    onChange={image_change_6}
                  />

                  <span className="image_check_icon">
                    {image_selected_6 !== "" && image_selected_6 !== null ? (
                      <FaCheckCircle className="valid_icon" />
                    ) : (
                      <FaPlusCircle className="empty_icon" />
                    )}
                  </span>
                </div>
              </div>
              <div className="image_priview_div">
                <img
                  src={image_selected_1 || "/assets/images/noproduct.png"}
                  alt="Click to select file"
                  onClick={() => image_select_1.current.click()}
                  style={{
                    cursor: "pointer",
                    width: "400px",
                    height: "400px",
                  }}
                />

                {/* Hidden file input element */}
                <input
                  type="file"
                  ref={image_select_1}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div className="priview_icondiv">
                  <span className="privie_text">
                    <FaEye /> <span>view Simple</span>
                  </span>
                  <span
                    className="delete_icon"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <MdDelete
                      onClick={() => {
                        setimage_selected_1(null);
                      }}
                    />
                  </span>
                </div>
              </div>

              <div className="image_guidline_container">
                <div className="image_guidline_main">
                  <h5>Side View</h5>
                  <div className="guid_div">
                    <div className="guid_div_top">
                      <span className="image_qui_title">
                        <span>
                          <FaCircleInfo />
                        </span>
                        <span className="mx-2">
                          Fallow image GuideLines to reduce the quality Check
                          failures
                        </span>
                      </span>
                    </div>
                    <div className="guid_box">
                      <span className="gu_title">Image Resolutions</span>
                      <span className="gu_text">
                        Use clear colour imagies with minimum resolutins 404x473
                      </span>
                    </div>
                    <div className="guid_box">
                      <span className="gu_title">Image GuideLines</span>
                      <span className="gu_text">
                        Use clear colour imagies with minimum resolutins 404x473
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="info_container_left">
              {/* <Affix offsetTop={30}> */}
              <div className="image_array_container">
                {product_Images.map((image, index) => (
                  <div className="single_img_box" key={index}>
                    <img
                      src={image}
                      alt="Click to select file"
                      className="imgbox_img"
                    />
                  </div>
                ))}
              </div>
              {/* </Affix> */}
            </div>
          </>
        )}
        <div className="info_container_right">
          <Collapse
            activeKey={activeKey}
            onChange={handleChange}
            className="accordien"
            items={items}
            accordion={true}
            bordered={false}
            size="large"
          />
        </div>
      </div>
    </>
  );
};

export default Step_3_Product_info;
