import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  categoryPost,
  validateSlugUrl,
  categoryImages,
  categoryBannerImages,
} from "../../redux/category/categorySlice";
import { useToasts } from "react-toast-notifications";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const AddCategory = () => {
  const {
    mobileimage,
    desktopimage,
    isCatmobileImageLoading,
    isDeskImageLoading,
    checkSlugurl,
    catBannerImage,
    isCategoryBannerImageLoading,
  } = useSelector((store) => store.category);
  const { superCatShow } = useSelector((store) => store.superCategory);

  const { addToast } = useToasts();

  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroy, setCategroy] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [catImageError, setCatImageError] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [result, setResult] = useState([]);
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercat(superCatShow[0].name);
      setSupercatid(superCatShow[0]._id);
    }
  }, [superCatShow]);

  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" && desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }
      if (catBannerImage === "") {
        setCatImageError("Required");
      } else {
        setCatImageError("");
      }
    }
  }, [
    buttonPress,
    supercatid,
    checkslugUrl,
    mobileimage,
    desktopimage,
    catBannerImage,
  ]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCategroy(value);
    setMetatitle(value);
    setMetakeyword(value);
    setMetadesc(value);
    // setErrorcolor("green");
    setCheckSlugUrl("");
    // setError("category available");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);

    if (catSlug !== "") {
      let responce = await dispatch(validateSlugUrl(catSlug));
      if (responce.payload.success) {
        setError("category alredy exist");
        setErrorcolor("red");
      } else if (catSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (catSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(catSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" && desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }
    if (catBannerImage === "") {
      setCatImageError("Required");
    } else {
      setCatImageError("");
    }
    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      mobileimage !== "" &&
      desktopimage !== "" &&
      catBannerImage !== ""
    ) {
      const formData = {
        name: categroy,
        slugUrl: checkslugUrl,
        superCategoryId: supercatid,
        superCategory: supercat,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        mobileImage: mobileimage,
        desktopImage: desktopimage,
        catBannerImage: catBannerImage,
      };
      const data = await dispatch(categoryPost(formData));
      if (data.payload.success) {
        addToast("Category Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        setCategroy("");
        setSlugUrl("");
        setCheckSlugUrl("");
        setMetatitle("");
        setMetakeyword("");
        setMetadesc("");
        setSupercat("");
        setSupercatid("");
        setsupcategroyError("");
        setSlugUrlError("");
        setimageError("");
        setCatImageError("");
        setbuttonPress(false);
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    setSupercatid(value);
    const textValue = superCatShow.find((indSupCat) => indSupCat._id === value);
    if (textValue) {
      setSupercat(textValue.name);
    }
  };

  const dispatch = useDispatch();

  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const categoryBannerImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryBannerImages({ catBannerImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                            htmlFor="fName"
                            className="text-sm font-medium text-default-900"
                            style={{
                              fontWeight: "700",
                              color: "rgb(0, 0, 102)",
                              fontSize: "22px",
                            }}
                          >
                            Super Category
                          </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Super Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label=" Select Super Category"
                              onChange={(e) => {
                                selectSuperCat(e);
                              }}
                              value={superCatShow._id}
                              name={superCatShow.name}
                              className={
                                supcategroyError !== "" ? "error_class" : ""
                              }
                              inputProps={{ style: { textAlign: "center" } }}
                              size="small"
                            >
                              {superCatShow.map((test, index) => (
                                <MenuItem
                                  key={test._id}
                                  value={test._id}
                                  name={test.name}
                                  selected={supercatid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "rgb(0, 0, 102)" }}
                        >
                          Category Name
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Category"
                            variant="outlined"
                            style={{
                              width: "100%",
                              textTransform: "capitalize",
                            }}
                            type="text"
                            id="text"
                            className={slugUrlError !== "" ? "error_class" : ""}
                            value={categroy}
                            onChange={(e) => verifyslugurl(e)}
                            // className={classes.textfield}
                            size="small"
                          />
                        </div>
                        <p style={{ color: errorcolor }} className="mt-2">
                          {error}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                        className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Category Banner Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryBannerImageChange}
                        className={catImageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isCategoryBannerImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={catBannerImage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "#000066" }}
                        >
                          Meta Description
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Description"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metadesc}
                            onChange={(e) => setMetadesc(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "#000066" }}
                        >
                          Meta Title
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Title"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metatitle}
                            onChange={(e) => setMetatitle(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "#000066" }}
                        >
                          Meta Keyword
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Keyword"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metakeyword}
                            onChange={(e) => setMetakeyword(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer">
                  {buttonLoading ? (
                    <button className="btn btn-primary">Add Category</button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Add Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategory;
