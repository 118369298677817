import React, { useState } from "react";

import { PersonRounded } from "@mui/icons-material";
import Mobile_Laptop_Version from "./Mobile_Laptop_Version";
import { Bell, Shield } from "react-bootstrap-icons";

import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space, Affix } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { setReset_data } from "../../redux/product/productSlice";
import { signout } from "../../redux/Athentication/AuthSlice";
import { setReset_Order } from "../../redux/order/OrderSlice";

import { FaAngleDown } from "react-icons/fa6";


const Home_Laptop_Version = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, login_Data } = useSelector((store) => store.authentication);

  const listing = [
    {
      key: "1",
      label: (
        <Link className="draopdown_link" to="/seller/my-listings">
          My Listings
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <span
          className="draopdown_link"
          onClick={() => {
            dispatch(setReset_data());
            navigate("/seller/new-product");
          }}
        >
          Add New Listings
        </span>
      ),
    },
    {
      key: "6",
      label: (
        <span
          className="draopdown_link"
          onClick={() => {
            navigate("/seller/category/add-category");
          }}
        >
          Add Category
        </span>
      ),
    },
    {
      key: "7",
      label: (
        <span
          className="draopdown_link"
          onClick={() => {
            navigate("/seller/add-sub-category");
          }}
        >
          Add Sub Category
        </span>
      ),
    },
    {
      key: "8",
      label: (
        <span
          className="draopdown_link"
          onClick={() => {
            navigate("/seller/add-category-tags");
          }}
        >
          Add Category Tag
        </span>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Track Approval Requests
    //     </Link>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Shopping Trends
    //     </Link>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Dhamaka Selection
    //     </Link>
    //   ),
    // },
  ];
  const orders = [
    {
      key: "1",
      label: (
        <Link className="draopdown_link" to="/seller/active-orders">
          Active Orders
        </Link>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <Link className="draopdown_link" to="/seller/return-orders">
    //       Returns
    //     </Link>
    //   ),
    // },
    {
      key: "3",
      label: (
        <Link className="draopdown_link" to="/seller/return-orders">
          Cancellations
        </Link>
      ),
    },
    // {
    //   key: "4",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Customer Returns Reduction
    //     </Link>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Cancellations & Courier Returns
    //     </Link>
    //   ),
    // },
  ];
  const profile = [
    // {
    //   key: "1",
    //   label: (
    //     <Link className="draopdown_link" to="/seller">
    //       Manage Profile
    //     </Link>
    //   ),
    // },
    {
      key: "2",
      label: (
        <span
          className="draopdown_link"
          onClick={() => {
            dispatch(signout());
            dispatch(setReset_data());
            dispatch(setReset_Order());
            navigate("/");
          }}
        >
          Logout
        </span>
      ),
    },
  ];
  const reports = [
    {
      key: "1",
      label: (
        <Link className="draopdown_link" to="/seller/reports/sales-report">
          Sales Reports
        </Link>
      ),
    },
  ];
  const payments = [
    {
      key: "1",
      label: (
        <Link className="draopdown_link" to="/seller">
          Payments Overview
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link className="draopdown_link" to="/seller">
          Previous Payments
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link className="draopdown_link" to="/seller">
          Search Order Settlements
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link className="draopdown_link" to="/seller">
          Invoices
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link className="draopdown_link" to="/seller">
          Statements
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link className="draopdown_link" to="/seller">
          Services Transaction History
        </Link>
      ),
    },
  ];

  return (
    <Affix offsetTop={0}>
      <div className="">
        <div id="nav2">
          <div id="nav" className="w-100 h-100 p-1 mx-auto my-auto px-4">
            <div className="left_header">
              <Link to="/seller" className="shopsy mx-1">
                <img src="/assets/images/ew10-removebg.png" />
              </Link>
              {/* <div className="buyerchat">
                <Link to="/seller" className="link_deco">
                  <h6>Welcome</h6>
                </Link>
              </div> */}
              <div>
                <Dropdown
                  menu={{
                    items: listing,
                  }}
                  className="dropdown_ant"
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>
                      Listing
                      <FaAngleDown className="dropdown_icon" />
                    </Space>
                  </span>
                </Dropdown>
              </div>
              <div>
                <Dropdown
                  menu={{
                    items: orders,
                  }}
                  className="dropdown_ant"
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>
                      Orders
                      <FaAngleDown className="dropdown_icon" />
                    </Space>
                  </span>
                </Dropdown>
              </div>
              <div>
                <Dropdown
                  menu={{
                    items: payments,
                  }}
                  className="dropdown_ant"
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>
                      Payments
                      <FaAngleDown className="dropdown_icon" />
                    </Space>
                  </span>
                </Dropdown>
              </div>
              <div>
                <Dropdown
                  menu={{
                    items: reports,
                  }}
                  className="dropdown_ant"
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>
                      Reports
                      <FaAngleDown className="dropdown_icon" />
                    </Space>
                  </span>
                </Dropdown>
              </div>
            </div>
            <div className="right_header">
              <div>
                <div className="buyerchat">
                  <h6>Help</h6>
                </div>
              </div>
              <div>
                <div className="buyerchat">
                  <h6>
                    <Bell />
                  </h6>
                </div>
              </div>
              {/* <div>
                <div className="buyerchat">
                  <h6>
                    <Shield />
                  </h6>
                </div>
              </div> */}
              <div>
                <Dropdown
                  menu={{
                    items: profile,
                  }}
                  className="dropdown_ant"
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <PersonRounded />
                    <Space>
                      <span className="ellipsis">
                        {login_Data.shopDisplayName}
                      </span>
                    </Space>
                  </span>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="responsive">
          <Mobile_Laptop_Version />
        </div>
      </div>
    </Affix>
  );
};

export default Home_Laptop_Version;
