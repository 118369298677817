import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { BiCategory } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import StepComponent from "./StepComponent";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  seller_Update,
  update_registation_status,
  validateshopdisplayname,
  validateshopgst,
} from "../../../redux/Athentication/AuthSlice";
import { Link, useNavigate } from "react-router-dom";

function Step_two() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const { seller_details } = useSelector((state) => state.authentication);

  const [ShopeName, setShopeName] = useState("");
  const [ShopeNameError, setShopeNameError] = useState("");
  const [ShopeDisplayname, setShopeDisplayname] = useState("");
  const [ShopeDisplayname_confirm, setShopeDisplayname_confirm] = useState("");
  const [ShopeDisplaynameError, setShopeDisplaynameError] = useState("");
  const [stiIn, setstiIn] = useState("");
  const [gstn_confirm, setgstn_confirm] = useState("");
  const [stiInError, setstiInError] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  useEffect(() => {
    if (buttonPress === true) {
      if (ShopeName === "") {
        setShopeNameError("Please enter your Shop Name");
      } else {
        setShopeNameError("");
      }
      if (ShopeDisplayname_confirm === "") {
        setShopeDisplaynameError("Please enter your Display Shop Name");
      } else {
        setShopeDisplaynameError("");
      }
      if (gstn_confirm === "") {
        setstiInError("Please enter your GST number");
      } else {
        setstiInError("");
      }
    }
  }, [buttonPress, ShopeName, ShopeDisplayname_confirm, gstn_confirm]);

  const dispalynameChange = async (e) => {
    let inputValue = e.trim();
    setShopeDisplayname(inputValue);
    if (inputValue === "") {
      setShopeDisplaynameError("Please enter your Display Shope Name ");
      setShopeDisplayname_confirm("");
    } else {
      const gstValidate = await dispatch(validateshopdisplayname(inputValue));
      if (gstValidate.payload.success === false) {
        setShopeDisplaynameError("");
        setShopeDisplayname_confirm(inputValue);
      } else {
        setShopeDisplaynameError("Display Shope Name already exist");
        setShopeDisplayname_confirm("");
      }
    }
  };

  const gstnChange = async (e) => {
    let inputValue = e;
    setstiIn(inputValue);
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!gstRegex.test(inputValue)) {
      setstiInError("Please enter valid GST formate");
      setgstn_confirm("");
    } else {
      const gstValidate = await dispatch(validateshopgst(inputValue));
      if (gstValidate.payload.success === false) {
        setstiInError("");
        setgstn_confirm(inputValue);
      } else {
        setstiInError("GST number already exist");
        setgstn_confirm("");
      }
    }
  };

  const updateSellerClick = async (e) => {
    e.preventDefault();

    setbuttonPress(true);

    if (ShopeName === "") {
      setShopeNameError("Please enter your Shop Name");
    } else {
      setShopeNameError("");
    }
    if (ShopeDisplayname_confirm === "") {
      setShopeDisplaynameError("Please enter your Display Shop Name");
    } else {
      setShopeDisplaynameError("");
    }

    if (gstn_confirm === "") {
      setstiInError("Please enter your GST number");
    } else {
      setstiInError("");
    }

    if (
      ShopeName !== "" &&
      ShopeDisplayname_confirm !== "" &&
      gstn_confirm !== ""
    ) {
      const formData = {
        shopName: ShopeName,
        shopDisplayName: ShopeDisplayname_confirm,
        shopGst: gstn_confirm,
        shop_form_status: 2,
        id: seller_details._id,
      };

      const update_seller = await dispatch(seller_Update(formData));
      if (update_seller.payload.success) {
        dispatch(update_registation_status(2));
        addToast("Seller update successfull", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
      }
    }
  };

  return (
    <div
      style={{
        margin: "auto",
        paddingLeft: "15px",
        paddingRight: "15px",
        maxWidth: "800px",
      }}
      className="mobi_margin_only"
    >
      <div className="mobile_logo_login">
        <div>
          <img onClick={() => navigate("/")} src="/assets/images/ew10.png" />
        </div>
        <h4>Seller Panel</h4>
      </div>
      <StepComponent />
      {/* <div className="textcontainer">
        <p style={{ color: "#000" }}>
          We've sent a verification link to your email
        </p>
      </div> */}
      <div className="textcontainer1">
        <h6 style={{ color: "#000", fontWeight: "600", fontSize: "14px" }}>
          Almost there......
        </h6>
      </div>
      <div className="textcontainer1">
        <p style={{ color: "#000" }}>
          We need these details to set up your account.You can also chose to
          fill them in the next step
        </p>
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextField
          label="GSTIN"
          className="textfield"
          type="text"
          id="text"
          style={{ width: "100%" }}
          error={stiInError !== "" ? true : false}
          helperText={stiInError}
          onChange={(e) => gstnChange(e.target.value)}
          value={stiIn}
        />
      </div>

      {/* <div className="textcontainer">
        <h6
          style={{
            color: "rgb(0, 102, 204)",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          Suggest Password
        </h6>
      </div> */}
      <div style={{ marginTop: "20px" }}>
        <TextField
          label="Your Full Name"
          className="textfield"
          type="text"
          id="text"
          error={ShopeNameError !== "" ? true : false}
          helperText={ShopeNameError}
          style={{ width: "100%" }}
          value={ShopeName}
          onChange={(e) => setShopeName(e.target.value)}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextField
          label="Store Name"
          className="textfield"
          type="text"
          id="text"
          error={ShopeDisplaynameError !== "" ? true : false}
          helperText={ShopeDisplaynameError}
          style={{ width: "100%" }}
          value={ShopeDisplayname}
          onChange={(e) => dispalynameChange(e.target.value)}
        />
      </div>

      <div
        className="continuecontainer"
        onClick={(e) => updateSellerClick(e)}
        style={{
          cursor: "pointer",
        }}
      >
        <h5 className="continuetext"> Continue </h5>
        <FaArrowRightLong color="#fff" />
      </div>
    </div>
  );
}

export default Step_two;
