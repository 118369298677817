import React, { useState } from "react";
import { Drawer, Radio, Space } from "antd";
import { VscThreeBars } from "react-icons/vsc";

import { FaList } from "react-icons/fa";
import {
  Close,
  CurrencyRupeeRounded,
  PersonRounded,
} from "@mui/icons-material";
import { ImCheckboxChecked } from "react-icons/im";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { FaCompass } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { MdHome } from "react-icons/md";
import { CgLogIn } from "react-icons/cg";
import { IoMdPricetags } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { GrDocumentPerformance } from "react-icons/gr";
import { GiArmorUpgrade } from "react-icons/gi";
import { BiSolidParty } from "react-icons/bi";
import { Shield } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setReset_data } from "../../redux/product/productSlice";
import { signout } from "../../redux/Athentication/AuthSlice";
import { setReset_Order } from "../../redux/order/OrderSlice";
import { IoIosLogOut } from "react-icons/io";
import { MdOutlineNewLabel } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";

const Mobile_Laptop_Version = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, login_Data } = useSelector((store) => store.authentication);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="container-fluid-lg">
      <div id="dropdown" style={{ backgroundColor: "#027CD5" }}>
        <div className="row w-100 h-100 justify-content-around mx-auto align-items-center">
          <div className="col-3">
            <Space className="d">
              <Radio.Group
                value={placement}
                onChange={(e) => setPlacement(e.target.value)}
              >
                <Radio
                  className="d-none"
                  style={{ visibility: "hidden" }}
                  value="right"
                ></Radio>
              </Radio.Group>
              <div>
                <h6
                  className="h-100 d-flex justify-content-start w-100 text-white mt-2"
                  onClick={showDrawer}
                >
                  <VscThreeBars className="threebars" />
                </h6>
              </div>
            </Space>
            <Drawer
              placement={placement}
              closable={false}
              onClose={onClose}
              open={open}
              key={placement}
              width={300}
              className={`custom-drawer ${
                open ? "custom-drawer-enter" : "custom-drawer-leave"
              }`}
              //   bodyStyle={{animation:'slideIn 0.5s'}}
            >
              <div>
                <div
                  style={{
                    backgroundColor: "#027CD5",
                    padding: "10px",
                    color: "white",
                  }}
                  className="profile d-flex align-items-center justify-content-between"
                >
                  <Shield />
                  <span>{login_Data.shopDisplayName}</span>
                  <Close onClick={onClose} />
                </div>
                <div className="menu-list mt-4">
                  <div
                    className="menu-item text-center d-flex align-items-center"
                    onClick={() => {
                      onClose();
                      navigate("/seller");
                    }}
                  >
                    <MdHome className="fs-5" color="#000" />
                    <span>Dashboard</span>
                  </div>
                  <div
                    onClick={() => {
                      onClose();
                      navigate("/seller/my-listings");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <FaList color="#000" />
                    <span>My Listings</span>
                  </div>
                  <div
                    onClick={() => {
                      onClose();
                      dispatch(setReset_data());
                      navigate("/seller/new-product");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <MdOutlineNewLabel color="#000" />
                    <span> Add New Listings</span>
                  </div>
                  {/* <div
                    onClick={() => {
                      onClose();
                      navigate("/seller");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <ImCheckboxChecked color="#000" />
                    <span>Brand Approval</span>
                  </div> */}
                  <div
                    onClick={() => {
                      onClose();
                      navigate("/seller/active-orders");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <GiCardboardBoxClosed className="fs-5" color="#000" />
                    <span>Active Orders</span>
                  </div>
                  <div
                    onClick={() => {
                      onClose();
                      navigate("/seller/return-orders");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <BsArrowDownSquareFill color="#000" />
                    <span>Cancellations</span>
                  </div>
                  <div
                    onClick={() => {
                      onClose();
                      navigate("/seller/reports/sales-report");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <TbReportAnalytics color="#000" />
                    <span>Sales Reports</span>
                  </div>
                  <div
                    onClick={() => {
                      onClose();
                      dispatch(signout());
                      dispatch(setReset_data());
                      dispatch(setReset_Order());
                      navigate("/");
                    }}
                    className="menu-item d-flex align-items-center"
                  >
                    <IoIosLogOut color="#000" />
                    <span>Log Out</span>
                  </div>
                  {/* <div className="menu-item d-flex align-items-center">
                    <FaCompass />
                    <span>Growth Central</span>
                  </div> */}
                  {/* <div className="menu-item d-flex align-items-center">
                    <IoIosRocket />
                    <span>EWS Growth Plans</span>
                  </div>
                  <div className="menu-item d-flex align-items-center">
                    <IoMdPricetags />
                    <span>Price Recommendations</span>
                  </div>
                  <div className="menu-item d-flex align-items-center">
                    <BiSolidOffer />
                    <span>Partner Services</span>
                  </div> */}
                  {/* <div className="menu-item d-flex align-items-center">
                    <GrDocumentPerformance />
                    <span>Performance</span>
                  </div>
                  <div className="menu-item d-flex align-items-center">
                    <GiArmorUpgrade />
                    <span>EWS Promotions</span>
                  </div>
                  <div className="menu-item d-flex align-items-center">
                    <BiSolidParty />
                    <span>Dhamaka Selection</span>
                  </div> */}
                </div>
              </div>
            </Drawer>
          </div>
          <div className="col-4">
            <div className="logo"></div>
          </div>
          <div className="col-5">
            <div className="d-flex justify-content-end text-white align-items-center">
              <div
                style={{ lineHeight: "1" }}
                className="d-flex align-items-center"
              >
                <span>
                  <PersonRounded />
                </span>{" "}
                <span className="mt-1">{login_Data.shopDisplayName}</span>
                {/* <span><CgLogIn /></span> <span className="mt-1">SignIn</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile_Laptop_Version;
