import React, { useEffect } from "react";
import Status_Dashbord from "./components/Status_Dashbord";
import { Dashobrd_Home } from "./components/Dashobrd_Home";
import Dashbord_Reports from "./components/Dashbord_Reports";
import { useDispatch } from "react-redux";
import { get_seller_earn } from "../../redux/order/OrderSlice";

const Dashbord = () => {
  const dispatch = useDispatch();


  return (
    <>
      <div className="homecontent">
        <div className="status">
          <Status_Dashbord />
        </div>
        <div className="sellerfull_main">
          <Dashobrd_Home />
        </div>
        <div className="grossrevenue">
          <Dashbord_Reports />
        </div>
      </div>
    </>
  );
};

export default Dashbord;
