import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import Mobile_Header from "./Mobile_Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  update_registation_status,
  update_seller_details,
} from "../../redux/Athentication/AuthSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-white border header">
      <div id="desk-v" className="px-4">
        <div className="icon">
          <span className="shopsy">
            <img src="/assets/images/ew10.png" className="img-fluid" alt="Shop Logo"/>
          </span>
        </div>
        <div className="auth-buttons mt-1">
          <button
            className="loginbtn"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </button>
          <button
            className="start-selling btn btn-warning"
            onClick={() => {
              navigate("/registation");
              dispatch(update_registation_status(0));
              dispatch(update_seller_details(""));
            }}
          >
            Start Selling
          </button>
        </div>
      </div>
      <div className="mobile-v">
        <Mobile_Header />
      </div>
    </div>
  );
};

export default Header;
