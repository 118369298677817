import React from "react";

const FooterRegPart = () => {
  return (
    <>
      <div style={{marginTop:"20px"}}>
        <div style={{ backgroundColor: "#ebf3fb", height: "auto",paddingTop:"20px" }}>
          <div className="shoopingcontainer">
            <h3 className="shoppingtext">Why sell on EWShooping?</h3>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    <img src="/assets/images/1.png" className="imgbox" />
                    <h6 className="headingtext">Sell Across India</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      Reach over 50 crore+ customers across 27000+ pincodes
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    <img src="/assets/images/1.png" className="imgbox" />
                    <h6 className="headingtext">Higher Profits</h6>
                  </div>

                  <li>
                    <a href="" className="listtext">
                      With 0% commission* , you take 100% profits with you
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    <img src="/assets/images/3.png" className="imgbox" />
                    <h6 className="headingtext">Account Management</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      Our Dedicated managers will help your business on
                      EWShopping
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    {" "}
                    <img src="/assets/images/5.png" className="imgbox" />
                    <h6 className="headingtext">Lower Return Charges</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      With our flat and low return charges, ship your products
                      stress-free
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    {" "}
                    <img src="/assets/images/6.png" className="imgbox" />
                    <h6 className="headingtext">Lower Return Charges</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      Use our simple pricing calculator to decide the best and
                      competitive selling price for your product
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    {" "}
                    <img src="/assets/images/7.png" className="imgbox" />
                    <h6 className="headingtext">Lower Return Charges</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      All your queries and issues are answered by our dedicated
                      Seller Support Team
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    <img src="/assets/images/8.png" className="imgbox" />
                    <h6 className="headingtext">Lower Return Charges</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      Get payments as fast as 7-10 days from the date of
                      dispatch
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6" style={{paddingLeft:"0px"}}>
                <ul>
                  <div className="sellacrosscontainer">
                    <img src="/assets/images/9.png" className="imgbox" />
                    <h6 className="headingtext">Lower Return Charges</h6>
                  </div>
                  <li>
                    <a href="" className="listtext">
                      Download our EWShopping Seller App to manage your business
                      anywhere, anytime
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterRegPart;
