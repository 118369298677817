import React, { useEffect, useState } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { AudioOutlined, DownOutlined } from "@ant-design/icons";
import {
  Input,
  Tooltip,
  Dropdown,
  Space,
  Button,
  Flex,
  Radio,
  ConfigProvider,
  Modal,
} from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";

const Return_order_Grid = ({ search }) => {
  const dispatch = useDispatch();

  const { cancelled_orders } = useSelector((store) => store.order);

  const [filter_Order, setfilter_Order] = useState([]);

  useEffect(() => {
    if (search !== "") {
      const result = cancelled_orders.filter((order) => {
        return order._id.toLowerCase().slice(18).match(search.toLowerCase());
      });
      setfilter_Order([...result]);
    } else {
      setfilter_Order([...cancelled_orders]);
    }
  }, [search, cancelled_orders]);

  const items = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  const columns = [
    {
      field: "Order ID",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row._id.slice(18)}
          </div>
        );
      },
    },
    { field: "ProductName", headerName: "Product Information", width: 350 },

    {
      headerName: "Buyer Details",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{params.row.UserName}</h6>
            <h6>{params.row.UserMobile}</h6>
            <h6>{params.row.Address}</h6>
          </div>
        );
      },
    },

    {
      field: "Netpayable",
      headerName: "Amount",
      width: 130,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="product_filtering">
        {/* <div className="product_filtering_left">
          <FaFilter />
        </div>
        <div className="product_filtering_right">
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Category <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Brand <DownOutlined />
              </Space>
            </Button>
          </Dropdown>

          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Stock <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Listing Price <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown
            className="header_dropdown margin_class"
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Listing Quality <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div> */}
      </div>
      <div className="product_filtering_grid">
        <div style={{ height: 1350, width: "100%" }}>
          <DataGrid
            rows={filter_Order}
            columns={columns}
            getRowId={(row) => row._id}
            rowHeight={100}
            getRowHeight={() => "auto"}
            sx={{
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 30, 50, 100]}
            // checkboxSelection
          />
        </div>
      </div>
    </>
  );
};

export default Return_order_Grid;
