import React, { useState, useEffect } from "react";
import { Button, Flex, Form, Select, Col, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../redux/product/productSlice";
const { Option } = Select;

const FootwearSupercategory = ({ buttonPress, getPropDetails, resetield }) => {
  const dispatch = useDispatch();
  const { product_basic_details } = useSelector((store) => store.product);
  const {
    romanTypeMessurements,
    sleevestype,
    top_shapes,
    top_types,
    bottom_styles_with_kurti,
    Dupattaavailable,
    pritsandPatterns,
    dress_designs,
    hemline_styles,
    length_styles,
    necktype,
    occasion_styles,
    embellishment_types,
    wave_patterns,
    wave_types,
    pattern_coverage,
    sleeve_styles,
    no_of_item,
    saree_types,
    saree_border_styles,
    fabric_types,
    Blouseavailable,
    fabric_care_instructions,
    fastening_types,
    transparency_levels,
    main_trends,
    Outer_Material,
    Ideal_for,
    Occasion,
    Insole_material,
    Type_of_sports,
    Sole_material,
    Closure,
    Upper_pattern,
    Season,
    Type_of_casual,
    Tip_shape,
    Type_of_formal,
    Weight,
    Type_of_flats,
    Type,
    Type_of_heels,
    Number_of_pairs,
    Size,
    Closure_type,
    Strap_material,
    Sub_type,
    Character,
    Heel_type,
    Length,
    Heel_design,
    Warranty
  } = useSelector((store) => store.Messurents);

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);

  const [color, setColor] = useState("");
  const [colorerror, setColorerror] = useState("");
  const [outer_material, setOuter_material] = useState("");
  const [outer_materialerror, setOuter_materialerror] = useState("");
  const [model_name, setModel_name] = useState("");
  const [model_nameerror, setModel_nameerror] = useState("");
  const [ideal_for, setIdeal_for] = useState("");
  const [ideal_forerror, setIdeal_forerror] = useState("");
  const [occasion, setOccasion] = useState("");
  const [occasionerror, setOccasionerror] = useState("");
  const [type_of_sports, setType_of_sports] = useState("");
  const [type_of_sportserror, setType_of_sportserror] = useState("");
  const [net_quantity, setNet_quantity] = useState("");
  const [net_quantityerror, setNet_quantityerror] = useState("");
  const [sole_material, setSole_material] = useState("");
  const [sole_materialerror, setSole_materialerror] = useState("");
  const [closure, setClosure] = useState("");
  const [closureerror, setClosureerror] = useState("");
  const [weight, setWeight] = useState("");
  const [weighterror, setWeighterror] = useState("");
  const [upper_pattern, setUpper_pattern] = useState("");
  const [upper_patternerror, setUpper_patternerror] = useState("");
  const [sales_package, setSales_package] = useState("");
  const [sales_packageerror, setSales_packageerror] = useState("");
  const [pack_of, setPack_of] = useState("");
  const [pack_oferror, setPack_oferror] = useState("");
  const [technology_used, setTechnology_used] = useState("");
  const [technology_usederror, setTechnology_usederror] = useState("");
  const [season, setSeason] = useState("");
  const [seasonerror, setSeasonerror] = useState("");
  const [care_instruction, setCare_instruction] = useState("");
  const [care_instructionerror, setCare_instructionerror] = useState("");
  const [type_of_casual, setType_of_casual] = useState("");
  const [type_of_casualerror, setType_of_casualerror] = useState("");
  const [tip_shape, setTip_shape] = useState("");
  const [tip_shapeerror, setTip_shapeerror] = useState("");
  const [type_of_formal, setType_of_formal] = useState("");
  const [type_of_formalerror, setType_of_formalerror] = useState("");
  const [type, setType] = useState("");
  const [typeerror, setTypeerror] = useState("");
  const [type_of_flats, setType_of_flats] = useState("");
  const [type_of_flatserror, setType_of_flatserror] = useState("");
  // -----------------10-05-2024--------
  const [brand, setBrand] = useState("");
  const [branderror, setBranderror] = useState("");
  const [style_code, setStyle_code] = useState("");
  const [style_codeerror, setStyle_codeerror] = useState("");
  const [size, setSize] = useState("");
  const [sizeerror, setSizeerror] = useState("");
  const [brand_color, setBrand_color] = useState("");
  const [brand_colorerror, setBrand_colorerror] = useState("");
  const [closure_type, setClosure_type] = useState("");
  const [closure_typeerror, setClosure_typeerror] = useState("");
  const [strap_material, setStrap_material] = useState("");
  const [strap_materialerror, setStrap_materialerror] = useState("");
  const [primary_color, setPrimary_color] = useState("");
  const [primary_colorerror, setPrimary_colorerror] = useState("");
  const [secondary_color, setSecondary_color] = useState("");
  const [secondary_colorerror, setSecondary_colorerror] = useState("");
  const [sub_type, setSub_type] = useState("");
  const [sub_typeerror, setSub_typeerror] = useState("");
  const [character, setCharacter] = useState("");
  const [charactererror, setCharactererror] = useState("");
  const [heel_type, setHeel_type] = useState("");
  const [heel_typeerror, setHeel_typeerror] = useState("");
  const [length, setLength] = useState("");
  const [lengtherror, setLengtherror] = useState("");
  const [heel_design, setHeel_design] = useState("");
  const [heel_designerror, setHeel_designerror] = useState("");
  const [warranty_summary, setWarranty_summary] = useState("");
  const [warranty_summaryerror, setWarranty_summaryerror] = useState("");
  const [insole_material, setInsole_material] = useState("");
  const [insole_materialerror, setInsole_materialerror] = useState("");
  const [inner_lining, setInner_lining] = useState("");
  const [inner_liningerror, setInner_liningerror] = useState("");
  const [removable_insole, setRemovable_insole] = useState("");
  const [removable_insoleerror, setRemovable_insoleerror] = useState("");
  const [type_of_heels, setType_of_heels] = useState("");
  const [type_of_heelserror, setType_of_heelserror] = useState("");

  useEffect(() => {
    if (product_basic_details !== "") {
      const getdata = async () => {
        const formData = {
          superCategoryId: product_basic_details.superCategoryId,
          categoryId: product_basic_details.categoryId,
          SubCategoryId: product_basic_details.SubCategoryId,
          CategoryTagId: product_basic_details.CategoryTagId,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        }
      };
      getdata();
    }
  }, [product_basic_details]);

  useEffect(() => {
    if (buttonPress === true) {
      if (color === "") {
        setColorerror("Required");
      } else {
        setColorerror("");
      }
      if (outer_material === "") {
        setOuter_materialerror("Required");
      } else {
        setOuter_materialerror("");
      }
      if (model_name === "") {
        setModel_nameerror("Required");
      } else {
        setModel_nameerror("");
      }
      if (ideal_for === "") {
        setIdeal_forerror("Required");
      } else {
        setIdeal_forerror("");
      }
      if (occasion === "") {
        setOccasionerror("Required");
      } else {
        setOccasionerror("");
      }
      if (type_of_sports === "") {
        setType_of_sportserror("Required");
      } else {
        setType_of_sportserror("");
      }
      if (net_quantity === "") {
        setNet_quantityerror("Required");
      } else {
        setNet_quantityerror("");
      }
      if (sole_material === "") {
        setSole_materialerror("Required");
      } else {
        setSole_materialerror("");
      }
      if (closure === "") {
        setClosureerror("Required");
      } else {
        setClosureerror("");
      }
      if (weight === "") {
        setWeighterror("Required");
      } else {
        setWeighterror("");
      }
      if (upper_pattern === "") {
        setUpper_patternerror("Required");
      } else {
        setUpper_patternerror("");
      }
      if (sales_package === "") {
        setSales_packageerror("Required");
      } else {
        setSales_packageerror("");
      }
      if (pack_of === "") {
        setPack_oferror("Required");
      } else {
        setPack_oferror("");
      }
      if (technology_used === "") {
        setTechnology_usederror("Required");
      } else {
        setTechnology_usederror("");
      }
      if (season === "") {
        setSeasonerror("Required");
      } else {
        setSeasonerror("");
      }
      if (care_instruction === "") {
        setCare_instructionerror("Required");
      } else {
        setCare_instructionerror("");
      }
      if (type_of_casual === "") {
        setType_of_casualerror("Required");
      } else {
        setType_of_casualerror("");
      }
      if (tip_shape === "") {
        setTip_shapeerror("Required");
      } else {
        setTip_shapeerror("");
      }
      if (type_of_formal === "") {
        setType_of_formalerror("Required");
      } else {
        setType_of_formalerror("");
      }
      if (type === "") {
        setTypeerror("Required");
      } else {
        setTypeerror("");
      }
      if (type_of_flats === "") {
        setType_of_flatserror("Required");
      } else {
        setType_of_flatserror("");
      }
      if (brand === "") {
        setBranderror("Required");
      } else {
        setBranderror("");
      }
      if (style_code === "") {
        setStyle_codeerror("Required");
      } else {
        setStyle_codeerror("");
      }
      if (size === "") {
        setSizeerror("Required");
      } else {
        setSizeerror("");
      }
      if (brand_color === "") {
        setBrand_colorerror("Required");
      } else {
        setBrand_colorerror("");
      }
      if (closure_type === "") {
        setClosure_typeerror("Required");
      } else {
        setClosure_typeerror("");
      }
      if (strap_material === "") {
        setStrap_materialerror("Required");
      } else {
        setStrap_materialerror("");
      }
      if (primary_color === "") {
        setPrimary_colorerror("Required");
      } else {
        setPrimary_colorerror("");
      }
      if (secondary_color === "") {
        setSecondary_colorerror("Required");
      } else {
        setSecondary_colorerror("");
      }
      if (sub_type === "") {
        setSub_typeerror("Required");
      } else {
        setSub_typeerror("");
      }
      if (character === "") {
        setCharactererror("Required");
      } else {
        setCharactererror("");
      }
      if (heel_type === "") {
        setHeel_typeerror("Required");
      } else {
        setHeel_typeerror("");
      }
      if (length === "") {
        setLengtherror("Required");
      } else {
        setLengtherror("");
      }
      if (heel_design === "") {
        setHeel_designerror("Required");
      } else {
        setHeel_designerror("");
      }
      if (warranty_summary === "") {
        setWarranty_summaryerror("Required");
      } else {
        setWarranty_summaryerror("");
      }
      if (insole_material === "") {
        setInsole_materialerror("Required");
      } else {
        setInsole_materialerror("");
      }
      if (inner_lining === "") {
        setInner_liningerror("Required");
      } else {
        setInner_liningerror("");
      }
      if (removable_insole === "") {
        setRemovable_insoleerror("Required");
      } else {
        setRemovable_insoleerror("");
      }
      if (type_of_heels === "") {
        setType_of_heelserror("Required");
      } else {
        setType_of_heelserror("");
      }
    }
  }, [
    buttonPress,
    color,
    outer_material,
    model_name,
    ideal_for,
    occasion,
    type_of_sports,
    net_quantity,
    sole_material,
    closure,
    weight,
    upper_pattern,
    sales_package,
    pack_of,
    technology_used,
    season,
    care_instruction,
    type_of_casual,
    tip_shape,
    type_of_formal,
    type,
    type_of_flats,
    brand,
    style_code,
    size,
    brand_color,
    closure_type,
    strap_material,
    primary_color,
    secondary_color,
    sub_type,
    character,
    heel_type,
    length,
    heel_design,
    warranty_summary,
    insole_material,
    inner_lining,
    removable_insole,
    type_of_heels,
  ]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (distinctfieldValidate.every((field) => field.value !== "")) {
        let finalarray = [];
        for (let index = 0; index < distinctfieldValidate.length; index++) {
          let element = distinctfieldValidate[index];
          let obj = { [element.field]: element.value };
          finalarray = [...finalarray, obj];
        }
        finalarray = finalarray;

        const singleObject = finalarray.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});
        getPropDetails(singleObject, true);
      } else {
        console.log("fieldas are empty");
      }
    }
  }, [buttonPress, distinctfieldValidate]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Flex wrap="wrap">
          {distinctfield.includes("color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color"
                hasFeedback
                validateStatus={colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Color"
                  value={color}
                  onChange={(value) => {
                    setColor(value);
                    updateValues("color", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("outer_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Outer Material"
                hasFeedback
                validateStatus={outer_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Outer Material"
                  value={outer_material}
                  onChange={(value) => {
                    setOuter_material(value);
                    updateValues("outer_material", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Outer_Material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={outer_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("model_name") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Model Name"
                hasFeedback
                validateStatus={model_nameerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Model Name"
                  value={model_name}
                  onChange={(value) => {
                    setModel_name(value);
                    updateValues("model_name", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={model_name === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ideal_for") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ideal For"
                hasFeedback
                validateStatus={ideal_forerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Ideal For"
                  value={ideal_for}
                  onChange={(value) => {
                    setIdeal_for(value);
                    updateValues("ideal_for", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Ideal_for.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ideal_for === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("occasion") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Occasion"
                hasFeedback
                validateStatus={occasionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Occasion"
                  value={occasion}
                  onChange={(value) => {
                    setOccasion(value);
                    updateValues("occasion", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Occasion.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={occasion === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_sports") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Sports"
                hasFeedback
                validateStatus={type_of_sportserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type of Sports"
                  value={type_of_sports}
                  onChange={(value) => {
                    setType_of_sports(value);
                    updateValues("type_of_sports", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type_of_sports.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_sports === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("net_quantity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Net Quantity"
                hasFeedback
                validateStatus={net_quantityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Net Quantity"
                  value={net_quantity}
                  onChange={(value) => {
                    setNet_quantity(value);
                    updateValues("net_quantity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={net_quantity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sole_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sole Material"
                hasFeedback
                validateStatus={sole_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sole Material"
                  value={sole_material}
                  onChange={(value) => {
                    setSole_material(value);
                    updateValues("sole_material", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sole_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sole_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("closure") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Closure"
                hasFeedback
                validateStatus={closureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Closure"
                  value={closure}
                  onChange={(value) => {
                    setClosure(value);
                    updateValues("closure", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Closure.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={closure === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("weight") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Weight"
                hasFeedback
                validateStatus={weighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Weight"
                  value={weight}
                  onChange={(value) => {
                    setWeight(value);
                    updateValues("weight", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Weight.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={weight === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("upper_pattern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Upper Pattern"
                hasFeedback
                validateStatus={upper_patternerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Upper Pattern"
                  value={upper_pattern}
                  onChange={(value) => {
                    setUpper_pattern(value);
                    updateValues("upper_pattern", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Upper_pattern.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={upper_pattern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sales_package") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sales Package"
                hasFeedback
                validateStatus={sales_packageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sales Package"
                  value={sales_package}
                  onChange={(value) => {
                    setSales_package(value);
                    updateValues("sales_package", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sales_package === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pack_of") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pack Of"
                hasFeedback
                validateStatus={pack_oferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Pack Of"
                  value={pack_of}
                  onChange={(value) => {
                    setPack_of(value);
                    updateValues("pack_of", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_pairs.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pack_of === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("technology_used") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Technology Used"
                hasFeedback
                validateStatus={technology_usederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Technology Used"
                  value={technology_used}
                  onChange={(value) => {
                    setTechnology_used(value);
                    updateValues("technology_used", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={technology_used === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("season") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Season"
                hasFeedback
                validateStatus={seasonerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Season"
                  value={season}
                  onChange={(value) => {
                    setSeason(value);
                    updateValues("season", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Season.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={season === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("care_instruction") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Care Instruction"
                hasFeedback
                validateStatus={care_instructionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Care Instruction"
                  value={care_instruction}
                  onChange={(value) => {
                    setCare_instruction(value);
                    updateValues("care_instruction", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={care_instruction === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_casual") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Casual"
                hasFeedback
                validateStatus={type_of_casualerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type of Casual"
                  value={type_of_casual}
                  onChange={(value) => {
                    setType_of_casual(value);
                    updateValues("type_of_casual", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type_of_casual.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_casual === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tip_shape") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Tip Shape"
                hasFeedback
                validateStatus={tip_shapeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Tip Shape"
                  value={tip_shape}
                  onChange={(value) => {
                    setTip_shape(value);
                    updateValues("tip_shape", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Tip_shape.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tip_shape === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_formal") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Formal"
                hasFeedback
                validateStatus={type_of_formalerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type of Formal"
                  value={type_of_formal}
                  onChange={(value) => {
                    setType_of_formal(value);
                    updateValues("type_of_formal", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type_of_formal.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_formal === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type"
                hasFeedback
                validateStatus={typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type"
                  value={type}
                  onChange={(value) => {
                    setType(value);
                    updateValues("type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_flats") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Flats"
                hasFeedback
                validateStatus={type_of_flatserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type of Flats"
                  value={type_of_flats}
                  onChange={(value) => {
                    setType_of_flats(value);
                    updateValues("type_of_flats", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type_of_flats.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_flats === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        {/*----------------- 10-05-2024------------------------ */}
        <Flex wrap="wrap">
          {distinctfield.includes("brand") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Brand"
                hasFeedback
                validateStatus={branderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Brand"
                  onChange={(value) => {
                    setBrand(value);
                    updateValues("brand", value);
                  }}
                  value={brand}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={brand === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("style_code") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Style Code"
                hasFeedback
                validateStatus={style_codeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Style Code"
                  onChange={(value) => {
                    setStyle_code(value);
                    updateValues("style_code", value);
                  }}
                  value={style_code}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={style_code === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Size"
                hasFeedback
                validateStatus={sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Size"
                   onChange={(value) => {
                    setSize(value);
                    updateValues("size", value);
                  }}
                  value={size}
                  style={{ width: 240 }}
                >
                  {Size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("brand_color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Brand Color"
                hasFeedback
                validateStatus={brand_colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Brand Color"
                  onChange={(value) => {
                    setBrand_color(value);
                    updateValues("brand_color", value);
                  }}
                  value={brand_color}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={brand_color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("closure_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Closure Type"
                hasFeedback
                validateStatus={closure_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Closure Type"
                  onChange={(value) => {
                    setClosure_type(value);
                    updateValues("closure_type", value);
                  }}
                  value={closure_type}
                  style={{ width: 240 }}
                >
                  {Closure_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={closure_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("strap_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Strap Material"
                hasFeedback
                validateStatus={strap_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Strap Material"
                  onChange={(value) => {
                    setStrap_material(value);
                    updateValues("strap_material", value);
                  }}
                  value={strap_material}
                  style={{ width: 240 }}
                >
                  {Strap_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={strap_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("primary_color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Primary Color"
                hasFeedback
                validateStatus={primary_colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Primary Color"
                  onChange={(value) => {
                    setPrimary_color(value);
                    updateValues("primary_color", value);
                  }}
                  value={primary_color}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={primary_color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("secondary_color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Secondary Color"
                hasFeedback
                validateStatus={secondary_colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Secondary Color"
                  onChange={(value) => {
                    setSecondary_color(value);
                    updateValues("secondary_color", value);
                  }}
                  value={secondary_color}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={secondary_color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sub_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sub Type"
                hasFeedback
                validateStatus={sub_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sub Type"
                  onChange={(value) => {
                    setSub_type(value);
                    updateValues("sub_type", value);
                  }}
                  value={sub_type}
                  style={{ width: 240 }}
                >
                  {Sub_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sub_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("character") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Character"
                hasFeedback
                validateStatus={charactererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Character"
                  onChange={(value) => {
                    setCharacter(value);
                    updateValues("character", value);
                  }}
                  value={character}
                  style={{ width: 240 }}
                >
                  {Character.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={character === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("heel_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Heel Type"
                hasFeedback
                validateStatus={heel_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Heel Type"
                  onChange={(value) => {
                    setHeel_type(value);
                    updateValues("heel_type", value);
                  }}
                  value={heel_type}
                  style={{ width: 240 }}
                >
                  {Heel_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={heel_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Length"
                hasFeedback
                validateStatus={lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Length"
                  onChange={(value) => {
                    setLength(value);
                    updateValues("length", value);
                  }}
                  value={length}
                  style={{ width: 240 }}
                >
                  {Length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("heel_design") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Heel Design"
                hasFeedback
                validateStatus={heel_designerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Heel Design"
                  onChange={(value) => {
                    setHeel_design(value);
                    updateValues("heel_design", value);
                  }}
                  value={heel_design}
                  style={{ width: 240 }}
                >
                  {Heel_design.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={heel_design === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("warranty_summary") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Warranty summary"
                hasFeedback
                validateStatus={warranty_summaryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Warranty Summary"
                  onChange={(value) => {
                    setWarranty_summary(value);
                    updateValues("warranty_summary", value);
                  }}
                  value={warranty_summary}
                  style={{ width: 240 }}
                >
                  {Warranty.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={warranty_summary === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("insole_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Insole Material"
                hasFeedback
                validateStatus={insole_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Insole Material"
                  onChange={(value) => {
                    setInsole_material(value);
                    updateValues("insole_material", value);
                  }}
                  value={insole_material}
                  style={{ width: 240 }}
                >
                  {Insole_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={insole_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inner_lining") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inner Lining"
                hasFeedback
                validateStatus={inner_liningerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Inner Lining"
                  onChange={(value) => {
                    setInner_lining(value);
                    updateValues("inner_lining", value);
                  }}
                  value={inner_lining}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inner_lining === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_insole") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Insole"
                hasFeedback
                validateStatus={removable_insoleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Removable Insole"
                  onChange={(value) => {
                    setRemovable_insole(value);
                    updateValues("removable_insole", value);
                  }}
                  value={removable_insole}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_insole === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_heels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Heels"
                hasFeedback
                validateStatus={type_of_heelserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Type of Heels"
                  onChange={(value) => {
                    setType_of_heels(value);
                    updateValues("type_of_heels", value);
                  }}
                  value={type_of_heels}
                  style={{ width: 240 }}
                >
                  {Type_of_heels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_heels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
                  
        </Flex>
      </Row>
    </>
  );
};

export default FootwearSupercategory;
