import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  romanTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "3XS" },
    { label: "XXS" },
    { label: "XS" },
    { label: "XS/S" },
    { label: "S" },
    { label: "XS/M" },
    { label: "S/M" },
    { label: "M" },
    { label: "M/L" },
    { label: "L" },
    { label: "S/L" },
    { label: "L/XL" },
    { label: "XL" },
    { label: "XL/XXL" },
    { label: "XXL" },
    { label: "3XL" },
    { label: "4XL" },
    { label: "4XL/5XL" },
    { label: "5XL" },
    { label: "6XL" },
    { label: "7XL" },
    { label: "8XL" },
    { label: "9XL" },
    { label: "10XL" },
  ],

  yearsTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "1-2Y" },
    { label: "2-3Y" },
    { label: "3-4Y" },
    { label: "4-5Y" },
    { label: "5-6Y" },
    { label: "6-7Y" },
    { label: "7-8Y" },
    { label: "8-9Y" },
    { label: "9-10Y" },
    { label: "10-11Y" },
    { label: "11-12Y" },
    { label: "12-13Y" },
    { label: "13-14Y" },
    { label: "14-15Y" },
    { label: "15-16Y" },
  ],
  RegularTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "24" },
    { label: "25" },
    { label: "26" },
    { label: "27" },
    { label: "28" },
    { label: "29" },
    { label: "30" },
    { label: "31" },
    { label: "32" },
    { label: "33" },
    { label: "34" },
    { label: "35" },
    { label: "36" },
    { label: "37" },
    { label: "38" },
    { label: "39" },
    { label: "40" },
    { label: "41" },
    { label: "42" },
    { label: "43" },
    { label: "44" },
    { label: "45" },
    { label: "46" },
    { label: "47" },
    { label: "48" },
    { label: "49" },
    { label: "50" },
    { label: "51" },
    { label: "52" },
  ],
  innerTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "28A" },
    { label: "28AA" },
    { label: "28B" },
    { label: "28C" },
    { label: "28D" },
    { label: "28E" },
    { label: "30A" },
    { label: "30AA" },
    { label: "30B" },
    { label: "30C" },
    { label: "30D" },
    { label: "30DD" },
    { label: "30E" },
    { label: "30F" },
    { label: "30FF" },
    { label: "30G" },
    { label: "30J" },
    { label: "30JJ" },
    { label: "30K" },
    { label: "32A" },
    { label: "32AA" },
    { label: "32B" },
    { label: "32C" },
    { label: "32D" },
    { label: "32DD" },
    { label: "32DDD" },
    { label: "32E" },
    { label: "32F" },
    { label: "32FF" },
    { label: "32G" },
    { label: "32H" },
    { label: "32J" },
    { label: "32JJ" },
    { label: "32K" },
    { label: "32Z" },
    { label: "34A" },
    { label: "34B" },
    { label: "34C" },
    { label: "34D" },
    { label: "34DD" },
    { label: "34DDD" },
    { label: "34E" },
    { label: "34F" },
    { label: "34FF" },
    { label: "34G" },
    { label: "34GG" },
    { label: "34H" },
    { label: "34JJ" },
    { label: "34K" },
    { label: "34Z" },
    { label: "36A" },
    { label: "36B" },
    { label: "36C" },
    { label: "36D" },
    { label: "36DD" },
    { label: "36DDD" },
    { label: "36E" },
    { label: "36F" },
    { label: "36FF" },
    { label: "36G" },
    { label: "36GG" },
    { label: "36H" },
    { label: "36J" },
    { label: "36JJ" },
    { label: "36K" },
    { label: "36Z" },
    { label: "38A" },
    { label: "38B" },
    { label: "38C" },
    { label: "38D" },
    { label: "38DD" },
    { label: "38E" },
    { label: "38F" },
    { label: "38G" },
    { label: "38H" },
    { label: "38HH" },
    { label: "38J" },
    { label: "38JJ" },
    { label: "38K" },
    { label: "38Z" },
    { label: "39B" },
    { label: "40A" },
    { label: "40B" },
    { label: "40C" },
    { label: "40D" },
    { label: "40DD" },
    { label: "40E" },
    { label: "40F" },
    { label: "40FF" },
    { label: "40G" },
    { label: "40GG" },
    { label: "40H" },
    { label: "40HH" },
    { label: "40JJ" },
    { label: "40K" },
    { label: "40Z" },
    { label: "42A" },
    { label: "42B" },
    { label: "42C" },
    { label: "42D" },
    { label: "42DD" },
    { label: "42E" },
    { label: "42F" },
    { label: "42FF" },
    { label: "42G" },
    { label: "42GG" },
    { label: "42H" },
    { label: "42Z" },
    { label: "43B" },
    { label: "44A" },
    { label: "44B" },
    { label: "44C" },
    { label: "44D" },
    { label: "44DD" },
    { label: "44E" },
    { label: "44F" },
    { label: "44FF" },
    { label: "44G" },
    { label: "44GG" },
    { label: "44H" },
    { label: "44Z" },
    { label: "46A" },
    { label: "46B" },
    { label: "46C" },
    { label: "46D" },
    { label: "46DD" },
    { label: "46E" },
    { label: "46F" },
    { label: "46G" },
    { label: "48A" },
    { label: "48B" },
    { label: "48C" },
    { label: "48D" },
    { label: "48DD" },
    { label: "48E" },
    { label: "50A" },
    { label: "50B" },
    { label: "50C" },
    { label: "50D" },
    { label: "50DD" },
    { label: "50E" },
  ],

  sleevestype: [
    { label: "3/4 Sleeve" },
    { label: "Full Sleeve" },
    { label: "Half Sleeve" },
    { label: "Short Sleeve" },
    { label: "Sleeveless" },
  ],

  sleeve_styles: [
    { label: "Set-In Sleeve" },
    { label: "Raglan Sleeve" },
    { label: "Kimono Sleeve" },
    { label: "Bishop Sleeve" },
    { label: "Bell Sleeve" },
    { label: "Puff Sleeve" },
    { label: "Cold Shoulder Sleeve" },
    { label: "Off-the-Shoulder Sleeve" },
    { label: "Cape Sleeve" },
  ],
  top_shapes: [
    { label: "T-shirt" },
    { label: "Blouse" },
    { label: "Tank Top" },
    { label: "Crop Top" },
    { label: "Peplum Top" },
    { label: "Off-the-Shoulder Top" },
    { label: "Wrap Top" },
    { label: "Halter Top" },
    { label: "Bodysuit" },
    { label: "Tube Top" },
    { label: "High-Low Top" },
    { label: "Poncho Top" },
  ],
  top_types: [
    { label: "Indo-Western Suits" },
    { label: "Saree" },
    { label: "Salwar Kameez" },
    { label: "Anarkali Suit" },
    { label: "Patiala Suit" },
    { label: "Palazzo Suit" },
    { label: "Kurti with Cigarette Pants" },
    { label: "Cape top Saree" },
    { label: "Lehengas" },
    { label: "Kurti and Churidar" },
    { label: "Women’s Sherwani" },
    { label: "Indian Gowns" },
    { label: "Lancha" },
    { label: "Sharara and Gharara Suits" },
    { label: "Maxi Gown" },
    { label: "Straight" },
    { label: "Denim Jacket" },
  ],
  dress_designs: [
    { label: "A-line Dress" },
    { label: "Shift Dress" },
    { label: "Sheath Dress" },
    { label: "Wrap Dress" },
    { label: "Fit and Flare Dress" },
    { label: "Maxi Dress" },
    { label: "Bodycon Dress" },
    { label: "High-Low Dress" },
    { label: "Mermaid Dress" },
    { label: "Empire Waist Dress" },
    { label: "T-shirt Dress" },
    { label: "Pinafore Dress" },
  ],

  necktype: [
    { label: "Asymmetrical" },
    { label: "Boat neckline" },
    { label: "Collar" },
    { label: "Cowl neck" },
    { label: "Halter neckline" },
    { label: "Illusion" },
    { label: "Jewel" },
    { label: "Keyhole neckline" },
    { label: "Off-the-shoulder" },
    { label: "One shoulder" },
    { label: "Plunging" },
    { label: "Queen Anne" },
    { label: "Round neck or Crew neck" },
    { label: "Scalloped neckline" },
    { label: "Scoop neckline" },
    { label: "Spaghetti straps" },
    { label: "Split Crew neck" },
    { label: "Square neck" },
    { label: "Strapless" },
    { label: "Surplice" },
    { label: "Sweetheart neckline" },
    { label: "Tie Bow neckline" },
    { label: "Turtleneck (High Neckline)" },
    { label: "U-Shaped neckline" },
    { label: "V-neck" },
  ],
  pritsandPatterns: [
    { label: "Animal Print" },
    { label: "Applique" },
    { label: "Blocked Printed" },
    { label: "Checkered" },
    { label: "Color Block" },
    { label: "Digital Print" },
    { label: "Dyed" },
    { label: "Embellished" },
    { label: "Embroidered" },
    { label: "Floral Print" },
    { label: "Geometric Print" },
    { label: "Graphic Print" },
    { label: "Hand Painted" },
    { label: "Ombre" },
    { label: "Paisley" },
    { label: "Polka Print" },
    { label: "Printed" },
    { label: "Self Design" },
    { label: "Solid/Plain" },
    { label: "Striped" },
    { label: "Temple Border" },
    { label: "Woven" },
    { label: "Yoke Design" },
  ],
  slit_styles: [
    { label: "Side mini slit" },
    { label: "Multiple slit" },
    { label: "Back high slit" },
    { label: "Back mini slit" },
    { label: "Front mini slit" },
    { label: "Front high slit" },
    { label: "M-slit/Double slit" },
    { label: "Waist slit" },
    { label: "Ruche slit" },
    { label: "Flouncing slit" },
    { label: "Asymmetrical slit" },
    { label: "Symmetrical slit" },
    { label: "Lace up slit" },
    { label: "Tape slit" },
  ],
  length_styles: [
    { label: "ankle-length" },
    { label: "knee-length" },
    { label: "capri" },
    { label: "mid-calf" },
    { label: "stirrup" },
    { label: "athleisure" },
    { label: "faux-leather" },
    { label: "footed" },
    { label: "rugged" },
    { label: "woolen" },
    { label: "Regular" },
    { label: "Calf Length" },
  ],
  hemline_styles: [
    { label: "A-line Hem" },
    { label: "Asymmetrical Hemline" },
    { label: "Bubble Hem" },
    { label: "Fishtail hem" },
    { label: "Flared hemline" },
    { label: "Handkerchief hem" },
    { label: "High-Low hem" },
    { label: "Raw edge hem" },
    { label: "Ruffled hemline" },
    { label: "Sharkbite hem" },
    { label: "Shirt tail hemline" },
    { label: "Slant hemline" },
    { label: "Slit hemline" },
    { label: "Step hem" },
    { label: "Tiered Bottom Hem" },
  ],
  occasion_styles: [
    { label: "Casual" },
    { label: "Party & Festive" },
    { label: "Wedding" },
    { label: "Wedding & Festive" },
    { label: "Ethnic" },
  ],
  fabric_types: [
    { label: "Art Silk" },
    { label: "Brasso" },
    { label: "Chanderi" },
    { label: "Chiffon" },
    { label: "Cotton Blend" },
    { label: "Cotton Jute" },
    { label: "Cotton Linen" },
    { label: "Cotton Silk" },
    { label: "Crepe" },
    { label: "Dupion Silk" },
    { label: "Georgette" },
    { label: "Jacquard" },
    { label: "Jute Silk" },
    { label: "Lace" },
    { label: "Linen" },
    { label: "Lycra Blend" },
    { label: "Muslin" },
    { label: "Net" },
    { label: "Nylon" },
    { label: "Organza" },
    { label: "Polyester" },
    { label: "Pure Cotton" },
    { label: "Pure Silk" },
    { label: "Raw Silk" },
    { label: "Satin" },
    { label: "Silk Blend" },
    { label: "Supernet" },
    { label: "Tissue" },
    { label: "Tussar Silk" },
    { label: "Velvet" },
    { label: "Elastane" },
    { label: "Cotton Rayon" },
    { label: "Viscose Rayon" },
    { label: "Acrylic Blend" },
    { label: "Blended Cotton" },
    { label: "Khadi Cotton" },
    { label: "Khadi Silk" },
    { label: "Hemp" },
    { label: "Hosiery" },
    { label: "Modal" },
    { label: "Polycotton" },
    { label: "Pure Linen" },
    { label: "Pure Wool" },
    { label: "Rayon" },
    { label: "Wool" },
    { label: "Wool Blend" },
  ],
  saree_types: [
    { label: "Arani Pattu" },
    { label: "Assam Silk" },
    { label: "Baluchari" },
    { label: "Banarasi" },
    { label: "Bandhani" },
    { label: "Berhampuri" },
    { label: "Bhagalpuri" },
    { label: "Bollywood" },
    { label: "Chanderi" },
    { label: "Chettinadu" },
    { label: "Chinnalapattu" },
    { label: "Daily Wear" },
    { label: "Dharmavaram" },
    { label: "Dokhona" },
    { label: "Fashion" },
    { label: "Gadwal" },
    { label: "Garad" },
    { label: "Hand Batik" },
    { label: "Handloom" },
    { label: "Ikkat" },
    { label: "Ilkal" },
    { label: "Jamdani" },
    { label: "Kalamkari" },
    { label: "Kanjivaram" },
    { label: "Kantha" },
    { label: "Kasavu" },
    { label: "Konrad" },
    { label: "Kota Doria" },
    { label: "Kovai" },
    { label: "Leheria" },
    { label: "Lucknow Chikankari" },
    { label: "Madhubani" },
    { label: "Madurai" },
    { label: "Maheshwari" },
    { label: "Mangalagiri" },
    { label: "Manipuri" },
    { label: "Mekhela Chador" },
    { label: "Mundum Neriyathum" },
    { label: "Murshidabad" },
    { label: "Mysore" },
    { label: "Narayanpet" },
    { label: "Paithani" },
    { label: "Patola" },
    { label: "Pochampally" },
    { label: "Sambalpuri" },
    { label: "Tant" },
    { label: "Venkatagiri" },
    { label: "Regular" },
  ],
  saree_styles: [
    { label: "9 Yards Sari" },
    { label: "Half & Half Sari" },
    { label: "Lehenga Sari" },
    { label: "Ready to Wear" },
    { label: "Regular Sari" },
    { label: "Stitched Sari" },
  ],
  blouse_styles: [{ label: "Blouse Piece" }, { label: "Unstitched" }],
  saree_border_styles: [
    { label: "Embellished" },
    { label: "Temple Saree Border" },
    { label: "Contrast Saree Border" },
    { label: "Mirror work Saree Border" },
    { label: "Scallop Saree Border" },
    { label: "Check Saree Border" },
    { label: "Velvet Saree Border" },
    { label: "Floral Saree Border" },
    { label: "Taping" },
  ],
  embellishment_types: [
    { label: "lace" },
    { label: "stone" },
    { label: "sequins" },
    { label: "beads" },
    { label: "ribbon" },
    { label: "metallic trims" },
    { label: "Gotta Patti" },
  ],
  fabric_care_instructions: [
    { fabric: "Acetate", label: "Hand Wash and Air Dry." },
    { fabric: "Cotton", label: "Hot Water and Warm Tumble Dry." },
    { fabric: "Linen", label: "Cool Water and Air Dry." },
    { fabric: "Polyester", label: "Cool Wash and Tumble Dry or Air Dry." },
    { fabric: "Silk", label: "Hand Wash/Cool and Cool Tumble Dry or Air Dry." },
    { fabric: "Wool", label: "Hand Wash and Air Dry." },
  ],
  kurti_styles: [
    { label: "Anarkali Kurti" },
    { label: "Straight Cut Kurti" },
    { label: "A-Line Kurti" },
    { label: "Lace Kurti" },
    { label: "Asymmetrical Kurti" },
    { label: "Kaftan Style Kurti" },
    { label: "Shirt Collar Kurti" },
    { label: "Overlay Kurti" },
    { label: "Jacket Style Kurti" },
    { label: "Cape Style Kurti" },
    { label: "Indo-Western Kurti" },
    { label: "Floor Length/Gown Style Kurti" },
    { label: "Color Block Kurti" },
    { label: "Printed Kurti" },
    { label: "Denim Kurti" },
    { label: "Chikankari Kurti" },
    { label: "Angrakha Kurti" },
    { label: "Dhoti Style Kurti" },
    { label: "Layered Kurti" },
    { label: "Flared Kurti" },
    { label: "Tie Dye Kurti" },
    { label: "Bandhani Kurti" },
    { label: "Kashmiri Kurti" },
    { label: "Gathered Kurti" },
    { label: "Peplum Kurti" },
    { label: "Tiered Kurti" },
    { label: "Boat Neck Kurti" },
    { label: "Embroidered Kurta" },
    { label: "Ikat Kurti" },
    { label: "Short Kurtis" },
    { label: "Cowel Neck Kurti" },
  ],
  bottom_styles_with_kurti: [
    { label: "Patiala Pants" },
    { label: "Palazzo or Parallel Pants" },
    { label: "Churidar Bottoms" },
    { label: "Straight Pants" },
    { label: "Sharara Pants" },
    { label: "Salwar" },
    { label: "Dhoti Pants" },
    { label: "Cigarette Pants" },
    { label: "Flared Skirt" },
  ],
  stitching_options: [{ label: "Stitched" }, { label: "Unstitched" }],
  Dupattaavailable: [{ label: "With Dupatta" }, { label: "Without Dupatta" }],
  Blouseavailable: [{ label: "With Blouse" }, { label: "Without Blouse" }],

  fastening_types: [
    { label: "buttons" },
    { label: "snap fasteners" },
    { label: "zippers" },
    { label: "toggle fasteners" },
    { label: "loop closures" },
  ],
  wave_patterns: [
    { label: "Horizontal Waves" },
    { label: "Vertical Waves" },
    { label: "Diagonal Waves" },
    { label: "Chevron Waves" },
    { label: "Curly Waves" },
    { label: "Abstract Waves" },
    { label: "Ocean Waves" },
    { label: "Ripple Waves" },
    { label: "Geometric Waves" },
    { label: "Floral Waves" },
    { label: "Tribal Waves" },
    { label: "Gradient Waves" },
  ],
  wave_types: [
    { label: "Sine Wave" },
    { label: "Sinusoidal Wave" },
    { label: "Square Wave" },
    { label: "Sawtooth Wave" },
    { label: "Triangle Wave" },
    { label: "Pulse Wave" },
    { label: "Cosine Wave" },
    { label: "Pulse Train" },
    { label: "Square Pulse Train" },
    { label: "Damped Wave" },
    { label: "Standing Wave" },
  ],
  pattern_coverage: [
    { label: "All-Over Print" },
    { label: "Placement Print" },
    { label: "Border Print" },
    { label: "Scattered Print" },
    { label: "Stripe Print" },
    { label: "Geometric Print" },
    { label: "Floral Print" },
    { label: "Animal Print" },
    { label: "Abstract Print" },
    { label: "Tribal Print" },
    { label: "Polka Dot Print" },
    { label: "Check Print" },
  ],
  no_of_item: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  transparency_levels: [
    { label: "Opaque" },
    { label: "Semi-Transparent" },
    { label: "Sheer" },
    { label: "Mesh" },
    { label: "Lace" },
    { label: "Chiffon" },
    { label: "Organza" },
    { label: "Tulle" },
    { label: "Voile" },
    { label: "Gauze" },
    { label: "Burnout" },
    { label: "Netting" },
  ],
  main_trends: [
    { label: "Bohemian" },
    { label: "Minimalist" },
    { label: "Athleisure" },
    { label: "Vintage-Inspired" },
    { label: "Eclectic" },
    { label: "Feminine" },
    { label: "Streetwear" },
    { label: "Sustainable Fashion" },
    { label: "Modern Classic" },
    { label: "Avant-Garde" },
    { label: "Global-Inspired" },
    { label: "Techwear" },
  ],

  //electronics
  washing_machine_types: [
    { label: "Fully automatic front load" },
    { label: "Semi automatic top load" },
    { label: "Fully automatic top load" },
  ],
  washing_modes: [{ label: "Gentle wash" }, { label: "Pulsator wash" }],
  yes_r_no: [{ label: "Yes" }, { label: "No" }],
  tub_materials: [
    { label: "Stainless Steel Tub" },
    { label: "Porcelain-Coated Steel Tub" },
    { label: "Plastic Tub" },
  ],
  Energy_Rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Maximum_Spin_Speed: [
    { label: "400" },
    { label: "500" },
    { label: "600" },
    { label: "700" },
    { label: "800" },
    { label: "900" },
    { label: "1000" },
    { label: "1100" },
    { label: "1200" },
    { label: "1300" },
    { label: "1400" },
    { label: "1500" },
    { label: "1600" },
  ],
  Washing_Capacity: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
    { label: "5.5" },
    { label: "6" },
    { label: "6.5" },
    { label: "7" },
    { label: "7.5" },
    { label: "8" },
    { label: "8.5" },
    { label: "9" },
    { label: "9.5" },
    { label: "10" },
    { label: "10.5" },
    { label: "11" },
    { label: "11.5" },
    { label: "12" },
    { label: "12.5" },
    { label: "13" },
  ],
  Dryer_Capacity: [
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  wash_program_types: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  dryer_types: [
    { label: "Vented Dryer" },
    { label: "Condenser Dryer" },
    { label: "Heat Pump Dryer" },
    { label: "Gas Dryer" },
    { label: "Compact Dryer" },
    { label: "Stackable Dryer" },
    { label: "Portable Dryer" },
    { label: "Electric Dryer" },
  ],
  outer_body_materials: [
    { label: "Plastic" },
    { label: "Stainless Steel" },
    { label: "Enamel-Coated Steel" },
  ],
  power_ratings: [
    { label: "300" },
    { label: "750" },
    { label: "1400" },
    { label: "1500" },
    { label: "2000" },
    { label: "2250" },
    { label: "2500" },
    { label: "3000" },
    { label: "3500" },
    { label: "4000" },
    { label: "4500" },
    { label: "5000" },
    { label: "5500" },
  ],
  sound_levels: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
  ],
  Width: [{ label: "60" }, { label: "65" }, { label: "70" }, { label: "75" }],
  height: [
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],
  weight: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
  ],
  Depth: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
  ],
  spin_cycle_duration: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  Wash_cycle_duration: [
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],

  air_conditioner_types: [
    { label: "Window" },
    { label: "Split" },
    { label: "Ductless Mini-Split" },
    { label: "Central" },
    { label: "Portable" },
    { label: "Packaged Terminal" },
    { label: "Packaged" },
  ],
  Capacity_in_tons: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
  ],
  compressor_types: [
    { label: "Reciprocating" },
    { label: "Rotary" },
    { label: "Scroll" },
    { label: "Centrifugal" },
    { label: "Inverter" },
  ],
  refrigerant_types: [
    { label: "R-410A" },
    { label: "R-22 (Freon)" },
    { label: "R-134a" },
    { label: "R-32" },
    { label: "R-407C" },
    { label: "R-290 (Propane)" },
    { label: "R-404A" },
    { label: "R-407A" },
  ],
  operating_modess: [
    { label: "Cooling Mode" },
    { label: "Heating Mode (Heat Pump Models)" },
    { label: "Fan Mode" },
    { label: "Dehumidification Mode" },
    { label: "Auto Mode" },
    { label: "Sleep Mode" },
    { label: "Eco Mode" },
    { label: "Turbo Mode" },
    { label: "Timer Mode" },
    { label: "Swing Mode" },
    { label: "Quiet Mode" },
    { label: "Smart Mode" },
    { label: "Auto Clean Mode" },
    { label: "Dry Mode (for reducing humidity without cooling)" },
    { label: "Energy Saver Mode" },
  ],
  condenser_coil_types: [
    { label: "Copper" },
    { label: "Aluminum" },
    { label: "Coated Aluminum" },
  ],
  panel_display_types: [
    { label: "LCD" },
    { label: "LED" },
    { label: "Touch screen" },
    { label: "Digital" },
    { label: "Backlit" },
    { label: "Alphanumeric" },
    { label: "Dot matrix" },
    { label: "Segment display" },
  ],
  condenser_fin_types: [
    { label: "Bare" },
    { label: "Slit" },
    { label: "Wavy" },
    { label: "Microchannel" },
    { label: "Corrugated" },
    { label: "Ripple" },
    { label: "Serrated" },
    { label: "Flat" },
    { label: "Elliptical" },
    { label: "Coated" },
  ],
  air_flow_directions: [{ label: "One-way" }, { label: "Two-way" }],
  Annual_electricity_consumption: [
    { label: "860" },
    { label: "900" },
    { label: "950" },
    { label: "980" },
    { label: "1000" },
  ],
  Operating_current: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
  ],
  Battery_type: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  refrigerator_types: [
    { label: "Single Door" },
    { label: "Double Door" },
    { label: "Triple Door" },
  ],
  refrige_types: [
    { label: "Top" },
    { label: "Bottom" },
    { label: "Side-by-Side" },
    { label: "French Door" },
    { label: "Compact / Mini" },
    { label: "Built-in" },
    { label: "Counter-depth" },
    { label: "Freezerless" },
    { label: "Smart" },
    { label: "Retro / Vintage" },
  ],
  mobiles_types: [{ label: "wire" }, { label: "wireless" }],
  mobiles_Usage: [{ label: "gaming" }, { label: "entertainment" }],
  Browse_Type: [{ label: "chrome" }, { label: "safari" }],
  SIM_Type: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  mobile_Display_Size: [
    { label: "5.5" },
    { label: "6.0" },
    { label: "6.5" },
    { label: "5.8" },
    { label: "6.7" },
    { label: "6.3" },
    { label: "6.4" },
    { label: "6.1" },
    { label: "5.7" },
  ],
  mobile_Resolution: [
    { label: "720p" },
    { label: "1080p" },
    { label: "1440p" },
    { label: "2160p" },
    { label: "4k" },
  ],
  mobile_Gpu: [{ label: "Adreno" }, { label: "Mali" }, { label: "Qualcomm" }],
  mobile_Display_Type: [
    { label: "Lcd" },
    { label: "Oled" },
    { label: "Amoled" },
    { label: "IPS" },
    { label: "P-oled" },
    { label: "super Amoled" },
  ],
  mobile_operating_system: [
    { label: "Android" },
    { label: "iOS" },
    { label: "Qualcomm" },
  ],
  mobile_Processor_brand: [
    { label: "Qualcomm" },
    { label: "apple" },
    { label: "MediaTek" },
    { label: "Samsung" },
    { label: "Exynos" },
    { label: "snapdragon" },
  ],
  mobile_Processor_core: [
    { label: "Single-core" },
    { label: "Dual-core" },
    { label: "Quad-core" },
    { label: "Hexa-core" },
    { label: "Octa-core" },
  ],
  mobile_primary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_secondary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_tertiary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  Internal_storage: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  mobile_ram: [
    { label: "1 GB" },
    { label: "2 GB" },
    { label: "3 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "12 GB" },
  ],
  Total_memory: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  Secodary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
  ],
  Primary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "12.5Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
    { label: "108Mp" },
    { label: "200Mp" },
  ],
  Flash: [
    { label: "Front" },
    { label: "Rear" },
  ],
  Frame_rate: [
    { label: "30 Hz" },
    { label: "60 Hz" },
    { label: "90 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" },
    { label: "240 Hz" },
    { label: "360 Hz" },
    { label: "480 Hz" },
    { label: "960 Hz" },
  ],
  Dual_lens: [
    { label: "primary" },
    { label: "secondary" },
  ],
  Network_type: [
    { label: "2G" },
    { label: "3G" },
    { label: "4G VoLTE" },
    { label: "4G" },
    { label: "5G" },
  ],
  Supported_networks: [
    { label: "4G LTE" },
    { label: "4G VoLTE" },
    { label: "5G" },
    { label: "GSM" },
    { label: "WCDMA" },
  ],
  Internet_connectivity: [
    { label: "4G" },
    { label: "5G" },
    { label: "3G" },
    { label: "WIFI" },
    { label: "EDGE" },
    { label: "GPRS" },
  ],
  Bluetooth_version: [
    { label: "4" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "5" },
    { label: "5.2" },
    { label: "5.3" },
    { label: "5.3" },
  ],
  Map_support: [
    { label: "Google Maps" },
    { label: "Maps" },
  ],
  Touchscreen_type: [
    { label: "capacitive" },
    { label: "resistive" },
    { label: "infrared" },
    { label: "SAW" },
    { label: "optical imaging" },
    { label: "P-CAP" },
    { label: "Multi-Touch" },
    { label: "single-Touch" },
  ],
  Sim_size: [
    { label: "standard sim" },
    { label: "micro sim" },
    { label: "nano sim" },
    { label: "e sim" },
  ],
  User_interface: [
    { label: "One UI" },
    { label: "Oxygen OS" },
    { label: "MIUI" },
    { label: "colorOS" },
    { label: "funtouchOS" },
    { label: "Realme UI" },
    { label: "hyperOS" },
  ],
  Sensors: [
    { label: "Proximity sensor" },
    { label: "Ambient light sensor" },
    { label: "Accelerometer" },
    { label: "Gyroscope" },
    { label: "Magnetometer" },
    { label: "Barometer" },
    { label: "Infrared sensor" },
    { label: "Face recognition sensor" },
    { label: "Heart rate sensor" },
  ],
  Browser: [{ label: "Google chrome" }, { label: "safari" }],
  GPS_type: [
    { label: "GPS" },
    { label: "AGPS" },
    { label: "GLONASS" },
    { label: "BEIDOU" },
    { label: "GALILEO" },
  ],
  Battery_capacity: [
    { label: "2000 mAh" },
    { label: "4000 mAh" },
    { label: "5000 mAh" },
    { label: "6000 mAh" },
    { label: "8000 mAh" },
    { label: "10000 mAh" },
    { label: "12000 mAh" },
  ],
  Mobile_Battery_type: [
    { label: "Lithium-ion[Li-ion]" },
    { label: "Lithium-polymer[Li-po]" },
    { label: "Nickel-metal hydrate" },
    { label: "nickel-cadmium" },
  ],
  mobile_width: [
    { label: "50mm" },
    { label: "55mm" },
    { label: "60mm" },
    { label: "65mm" },
    { label: "68mm" },
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
  ],
  mobile_height: [
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "150mm" },
    { label: "160mm" },
    { label: "170mm" },
    { label: "180mm" },
    { label: "190mm" },
    { label: "200mm" },
  ],
  mobile_depth: [
    { label: "5mm" },
    { label: "8mm" },
    { label: "10mm" },
    { label: "14mm" },
    { label: "16mm" },
    { label: "12mm" },
    { label: "18mm" },
    { label: "20mm" },
  ],
  mobile_weight: [
    { label: "100g" },
    { label: "150g" },
    { label: "186g" },
    { label: "246g" },
    { label: "283g" },
    { label: "256g" },
    { label: "263g" },
    { label: "293g" },
  ],


  // -------============Laptops/////////////////////////
  Laptop_Type: [
    { label: "Gaming" },
    { label: "Ultrabook " },
    { label: "Business" },
    { label: "Convertible" },
    { label: "Chrommobook " },
    { label: "Thin and Light " },
    { label: "2-in-1" },
],
Suitable_for: [
    { label: "processing" },
    { label: "multitasking" },
    { label: "programming" },
    { label: "multimedia" },
    { label: "travel" },
    { label: "graphic" },
    { label: "design" },
    { label: "everyday use" },
],

laptop_battery_backup: [
    { label: "upto 4hr" },
    { label: "upto 5hr" },
    { label: "upto 6hr" },
    { label: "upto 7hr" },
    { label: "upto 8hr" },
    { label: "upto 9hr" },
    { label: "upto 10hr" },

],

laptop_Processor_brand: [
    { label: "Intel" },
    { label: "AMD" },
],
laptop_Processor_name: [
    { label: "Intel Core i3" },
    { label: "Intel Core i5" },
    { label: "Intel Core i7" },
    { label: "Intel Core i9" },
    { label: "AMD Ryzen 3" },
    { label: "AMD Ryzen 5" },
    { label: "AMD Ryzen 7" },
    { label: "AMD Ryzen 9 " },
    { label: "Qualcomm snapdragon" },
    { label: "Apple M1”" },
],
laptop_Ram: [
    { label: "4" },
    { label: "8" },
    { label: "16" },
    { label: "32" },
    { label: "64" },
    { label: "128" }
],
laptop_Ram_type: [
    { label: "DDR3" },
    { label: "DDR4" },
    { label: "DDR5" },
],
laptop_Graphic_processor: [
    { label: "Intel HD Graphics" },
    { label: "Intel Iris Xe Graphics" },
    { label: "Intel UHD Graphics" },
    { label: "NVIDIA GeForce GTX" },
    { label: "NVIDIA GeForce RTX" },
    { label: "AMD Radeon RX" },
    { label: "AMD Radeon Pro" },
    { label: " Intel Integrated Graphics" },
    { label: " NVIDIA Quadro" },
    { label: "AMD Radeon Vega" },
],
laptop_Clock_speed: [
    { label: "1GHz" },
    { label: "1.2GHz" },
    { label: "1.4GHz" },
    { label: "2.1GHz" },
    { label: "2.3GHz" },
    { label: "2.5GHz" },
    { label: "2.8GHz" },
    { label: "3Ghz" },
],
laptop_Expandable_ssd_capacity: [
    { label: "upto 512GB" },
    { label: "upto 1TB" },
    { label: "upto 2TB" },
],
laptop_Operating_system: [
    { label: "Windows" },
    { label: "macOs" },
    { label: "Linux" },
    { label: "Chrome OS" },
],
System_archietecture: [
    { label: "32-bit" },
    { label: "64- bit" },

],
Screen_Size: [
    { label: "11 inch" },
    { label: "12 inch" },
    { label: "13 inch" },
    { label: "14 inch" },
    { label: "15 inch" },
    { label: "16 inch" },
    { label: "17 inch" },

],

laptop_Screen_type: [
    { label: "LCD" },
    { label: "LED" },
    { label: "OLED" },
    { label: "IPS" },
    { label: "TN" },
],
laptop_Sound_properties: [
    { label: "stereo" },
    { label: "built-in speakers" },
    { label: "dolby speakers" },
    { label: "harman Kardon" },
    { label: "noise concellation" },
    { label: "Audio jack" },
],
Dimensions: [
    { label: "250mm  x 150 mm x 10mm" },
    { label: "300mm x 200mm x 20mm" },
    { label: "350mm x 250mm x 30mm" },
    { label: "400mm x 300mm x 50mm" },
],
Weight: [
    { label: "1kg" },
    { label: "2kg" },
    { label: "3kg" },
    { label: "4kg" },
    { label: "5kg" },
],
Web_camera: [
    { label: "HD" },
    { label: "FULL hd" },
    { label: "720 p" },
    { label: "1080p" },
    { label: "4k" },
],
Emmc_storage_capacity: [
    { label: "32gb" },
    { label: "64gb" },
    { label: "128gb" },
],
Dedicated_graphic_memory_type: [
    { label: "GDDR5" },
    { label: "GDDR6X" },
    { label: "GDDR6" },
    { label: "HBM" },
    { label: "HBM2" },
    { label: "HBM2E" },
    { label: "HBM3" },
    { label: "GDDR5X" },
    { label: "GDDR6L" },
    { label: "GDDR7" },
],
Dedicated_graphic_memory_capacity: [
    { label: "2 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "16 GB" },
],
laptop_Ssd_capacity: [
    { label: "128GB" },
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "2 TB" },
],
Lock_port: [
    { label: "Kensington Lock Slot" },
],
// tablets---------------
Ideal_usage: [
    { label: "Entertainment" },
    { label: "Gaming" },
    { label: "esim" },
    { label: "Reading " },
    { label: "Browsing" },
    { label: "For Kids " },
    { label: "Business" },
],
Connectivity: [
    { label: "wifi" },
    { label: "Bluetooth" },
    { label: "NFC" },
    { label: "4G/LTE" },
    { label: "5G" },
],
tablets_Os: [
    { label: "Android" },
    { label: "iOS" }
],
tablets_ram: [
    { label: "2GB" },
    { label: "3GB" },
    { label: "4GB" },
    { label: "6GB" },
    { label: " 8GB" },
],
Supported_network: [
    { label: "Bluetooth" },
    { label: "wifi" },
    { label: "5g" },
    { label: "gsm" },
    { label: "cdma" },
    { label: "hspa" },
    { label: "volte" },
    { label: "dual" },
    { label: "sim" },
],
Processor_type: [
    { label: "Qualcomm Snapdragon" },
    { label: "Apple A-series (iPad)" },
    { label: "Samsung Exynos" },
    { label: " MediaTek" }
],
tablet_Display_size: [
    { label: "7 inches" },
    { label: "8 inches" },
    { label: "9 inches" },
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },

],
Display_resolution: [
    { label: "1280 x 720p" },
    { label: "1920 X 1080p" },
    { label: "2560 X 1440p" },
    { label: "3840 X 2160p" },
],

tablet_Primary_camera: [
    { label: "8Mp" },
    { label: "9Mp" },
    { label: "10Mp" },
    { label: "11Mp" },
    { label: "12Mp" },
    { label: "13Mp" },
    { label: "14Mp" },
    { label: "15Mp" },
    { label: "16Mp" },

],
tablet_Processor_speed: [
    { label: "1.2GHzz" },
    { label: "1.4GHzz" },
    { label: "1.6GHzz" },
    { label: "2GHzz" },
    { label: "2.2GHzz" },
    { label: "2.4GHzz" },
    { label: "3GHzz" }
],
tablet_Internal_storage: [
    { label: " 32 GB" },
    { label: " 64 GB" },
    { label: " 128 GB" },
    { label: " 256 GB " },
    { label: " 512 GB" },
    { label: " 1 TB" }
],
Sensors: [
    { label: "Accelerometer" },
    { label: "Fingerprint Sensor" },
    { label: "Gyro Sensor" },
    { label: "Geomagnetic Sensor" },
    { label: "Hall Sensor" },
    { label: "RGB Light Sensor" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
],
tablet_Sim_type: [
    { label: "single" },
    { label: "single/Dual" },
    { label: "Dual" },
],
browser: [
    { label: "chrome" },
    { label: "safari" },
],
tablet_Rom: [
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
],
Battery_capapcity: [
    { label: "3000-5000mAh" },
    { label: "5000-7000mah" },
    { label: "7000mAh-10000mAh" },
    { label: "above 10000mAh" },
],
Battery_type: [
    { label: "Lithium-ion (Li-ion)" },
    { label: "Lithium-polymer (Li-po)" }
],
Bluetooth_version: [
    { label: "4" },
    { label: "5" },
    { label: "6" },
],
Display_type: [
    { label: "Hd" },
    { label: "FHD" },
    { label: "Lcd" },
    { label: "oled" },
    { label: "amoled" },
    { label: "Lcd" },
    { label: "ips" },
    { label: "tft" },
    { label: "retinal display" },
],
Video_formats_supported: [
    { label: "MP4" },
],
Gps_type: [
    { label: "GPS" },
    { label: "Glonass" },
    { label: "Beidou" },
    { label: "Galileo" },
    { label: "QZSS" },
],
mouse_system_requiremnets: [
    { label: "laptop" },
],
laptop_bag_pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
],
laptop_bag_type: [
    { label: "backpack" },
    { label: "messenger bag" },
    { label: "shoulder bag" },
],
Ideal_for: [
    { label: "Men /Women" },

],
Compatible_laptop_size: [
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
],
Material: [
    { label: "nylon" },
    { label: "polyster" },
    { label: "Leather" },
    { label: "Canvas " },
    { label: "neoprene" },
    { label: "PU Leatehr" },
    { label: "Microfiber" },
],
Number_of_compartments: [
    { label: "1" },
    { label: "2" },
    { label: "3" }
],
Pattern: [
    { label: "solid" },
    { label: "striped" },
    { label: "checkered" },
    { label: "floral" },
    { label: "geometric" },
],
Capacity: [
    { label: "10-15L" },
    { label: "15-20L" },
    { label: "20-25L" },
    { label: "25-30L" },
    { label: "30-35L" },
    { label: "35-40L" },
    { label: "40-45L" },
    { label: "45-50L" },
],
laptop_bag_Dimensions: [
    { label: "15 inches x 10 inches x 3 inches" },
    { label: "18 inches x 12 inches x 4 inches" },
    { label: "20 inches x 14 inches x 6 inches" },
    { label: "22 inches x 16 inches x 9 inches " },
    { label: "25 inches x 18 inches x 18 inches" },
],
pc_speaker_type: [
    { label: "Laptop" },
    { label: "desktop" },
    { label: "Speaker" },
    { label: "stereo" },
    { label: "Bluetooth" },
],
Configuration: [
    { label: "2.0" },
    { label: "2.1" },
    { label: "2.2" },
    { label: "2.3" },
    { label: "2.4" },
    { label: "2.5" },
    { label: "2.6" },
    { label: "2.7" },
    { label: "2.8" },
    { label: "2.9" },
    { label: "3" },
    { label: "3.1" },
    { label: "3.2" },
    { label: "3.3" },
    { label: "3.4" },
    { label: "3.5" },
    { label: "3.6" },
    { label: "3.7" },
    { label: "3.8" },
    { label: "3.9" },
    { label: "4" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "4.5" },
],
Power_source: [
    { label: "usb" },
    { label: "Ac adaptor" },
],
Power_output: [
    { label: "2w" },
    { label: "5w" },
    { label: "10w" },
    { label: "15w" },
    { label: "20w" },
    { label: "30w" },
    { label: "40w" },
    { label: "50w" },
    { label: "100w" },
    { label: "150w" },
    { label: "180w" },
    { label: "200w" },
    { label: "300w" },
    { label: "400w" },
    { label: "500w" },
],
Frequency_response: [
    { label: "20 Hz - 200 Hz" },
    { label: "200 Hz - 2,000 Hz" },
    { label: "2,000 Hz - 20,000 Hz " },

],
Impedence: [
    { label: "2O" },
    { label: "2.1O" },
    { label: "2.2 O" },
    { label: "2.3 O" },
    { label: "2.4 O" },
    { label: "2.5 O" },
    { label: "2.6 O" },
    { label: "2.7 O" },
    { label: "2.8 O" },
    { label: "2.9 O" },
    { label: "3 O" },
    { label: "3.1 O" },
    { label: "3.2 O" },
    { label: "3.3 O" },
    { label: "3.4 O" },
    { label: "3.5 O" },
    { label: "3.6 O" },
    { label: "3.7 O" },
    { label: "3.8 O" },
    { label: "3.9 O" },
    { label: "4 O" },
    { label: "4.1 O" },
    { label: "4.2 O" },
    { label: "4.3 O" },
    { label: "4.4 O" },
    { label: "4.5 O" },
    { label: "4.6 O" },
    { label: "4.7 O" },
    { label: "4.8 O" },
    { label: "4.9 O" },
    { label: "5 O" },
    { label: "5.1 O" },
    { label: "5.2 O" },
    { label: "5.3 O" },
    { label: "5.4 O" },
    { label: "5.5 O" },
    { label: "5.6 O" },
    { label: "5.7 O" },
    { label: "5.8 O" },
    { label: "5.9 O" },
    { label: "6 O" },
    { label: "6.1 O" },
    { label: "6.2 O" },
    { label: "6.3 O" },
    { label: "6.4 O" },
    { label: "6.5 O" },
    { label: "6.6 O" },
    { label: "6.7 O" },
    { label: "6.8 O" },
    { label: "6.9 O" },
    { label: " 7 O" },
    { label: "7.1 O" },
    { label: "7.2 O" },
    { label: "7.3 O" },
    { label: "7.4 O" },
    { label: "7.5 O" },
    { label: "7.6 O" },
    { label: "7.7 O" },
    { label: "7.8 O" },
    { label: "7.9 O" },
    { label: "8 O" },
    { label: "8.1 O" },
    { label: "8.2 O" },
    { label: "8.3 O" },
    { label: "8.4 O" },
    { label: "8.5 O" },
    { label: "8.6 O" },
    { label: "8.7 O" },
    { label: "8.8 O" },
    { label: "8.9 O" },
    { label: "9 O" },
    { label: "9.1 O" },
    { label: "9.2 O" },
    { label: "9.3 O" },
    { label: "9.4 O" },
    { label: "9.5 O" },
    { label: "9.6 O" },
    { label: "9.7 O" },
    { label: "9.8 O" },
    { label: "9.9 O" },
    { label: "10 O" },

    { label: "10.1 O" },
    { label: "10.2 O" },
    { label: "10.3 O" },
    { label: "10.4 O" },
    { label: "10.5 O" },
    { label: "10.6 O" },
    { label: "10.7 O" },
    { label: "10.8 O" },
    { label: "10.9 O" },
    { label: "11 O" },
    { label: "11.1 O" },
    { label: "11.2 O" },
    { label: "11.3 O" },
    { label: "11.4 O" },
    { label: "11.5 O" },
    { label: "11.6 O" },
    { label: "11.7  O" },
    { label: "11.8 O" },
    { label: "11.9 O" },
    { label: "12 O" },
    { label: "12.1 O" },
    { label: "12.2 O" },
    { label: "12.3 O" },
    { label: "12.4 O" },
    { label: "12.5 O" },
    { label: "12.6 O" },
    { label: "12.7 O" },
    { label: "12.8 O" },
    { label: "12.9 O" },
    { label: "13 O" },
    { label: "13.1 O" },
    { label: "13.2 O" },
    { label: "13.3 O" },
    { label: "13.4 O" },
    { label: "13.5 O" },
    { label: "13.6 O" },
    { label: "13.7 O" },
    { label: "13.8 O" },
    { label: "13.9 O" },
    { label: "14 O" },
    { label: "14.1 O" },
    { label: "14.2 O" },
    { label: "14.3 O" },
    { label: "14.4 O" },
    { label: "14.5 O" },
    { label: "14.6 O" },
    { label: "14.7 O" },
    { label: "14.8 O" },
    { label: "14.9 O" },
    { label: "15 O" },
    { label: "15.1 O" },
    { label: "15.2 O" },
    { label: "15.3 O" },
    { label: "15.4 O" },
    { label: "15.5 O" },
    { label: "15.6 O" },
    { label: "15.7 O" },
    { label: "15.8 O" },
    { label: "15.9 O" },
    { label: "16 O" },
    { label: "16.1 O" },
    { label: "16.2 O" },
    { label: "16.3 O" },
    { label: "16.4 O" },
    { label: "16.5 O" },
    { label: "16.6 O" },
    { label: "16.7 O" },
    { label: "16.8 O" },
    { label: "16.9 O" },
    { label: "17 O" },
    { label: "17.1 O" },
    { label: "17.2 O" },
    { label: "17.3 O" },
    { label: "17.4 O" },
    { label: "17.5 O" },
    { label: "17.6 O" },
    { label: "17.7 O" },
    { label: "17.8 O" },
    { label: "17.9 O" },
    { label: "18 O" },
    { label: "18.1 O" },
    { label: "18.2 O" },
    { label: "18.3 O" },
    { label: "18.4 O" },
    { label: "18.5 O" },
    { label: "18.6 O" },
    { label: "18.7 O" },
    { label: "18.8 O" },
    { label: "18.9 O" },
    { label: "19 O" },
    { label: "19.1 O" },
    { label: "19.2 O" },
    { label: "19.3 O" },
    { label: "19.4 O" },
    { label: "19.5 O" },
    { label: "19.6 O" },
    { label: "19.7 O" },
    { label: "19.8 O" },
    { label: "19.9 O" },
    { label: "20 O" },




],
Wired_wireless: [
    { label: "Wired" },
    { label: "wireless" },
],
Compatitle_devices: [
    { label: "Pc" },
    { label: "Laptop" },
    { label: "Smartphone" },
    { label: "Laptop " },
    { label: "TV" },
    { label: "MP3 Player" },
    { label: "DVD Player " },
],
Controls: [
    { label: "Volume" },
    { label: "Bass" },
    { label: "Treblle" },
    { label: "Mode" },
    { label: "Input Select" },
],
Width: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },

],
height: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
    { label: "21cm" },
    { label: "22cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
    { label: "26cm" },
    { label: "27cm" },
    { label: "28cm" },
    { label: "29cm" },
    { label: "30cm" },
],
depth: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
],
pendrive_Opening_mechanism: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
],
Read_speed: [
    { label: "10MB/S" },
    { label: "20MB/S" },
    { label: "30MB/S" },
    { label: "40MB/S" },
    { label: "50MB/S" },
    { label: "60MB/S" },
    { label: "70MB/S" },
    { label: "80MB/S" },
    { label: "90MB/S" },
    { label: "100MB/S" },


],
pendrive_width: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
],
pendrive_height: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
],
pendrive_depth: [
    { label: "1 cm" },
    { label: "2 cm" },

],
pendrive_weight: [

    { label: "3 g" },
    { label: "4 g" },
    { label: "5 g" },
    { label: "6 g" },
    { label: "7 g" },
    { label: "8 g" },
    { label: "9 g" },
    { label: "10 g" },
],
Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },

],
hard_drive_type: [
    { label: "HDD" },
    { label: "SDD" },
],
Capacity: [
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "8TB " },
    { label: "16 TB" },
    { label: "32 TB" },
],
System_requirements: [
    { label: "Above Windows 10" },

],
Form_factor: [
    { label: "2.5 – 5 inch " },
    { label: "M.2 " },
    { label: "Portable" },
    { label: "dual sim" },
    { label: "single" },
],
Wired_wireless: [
    { label: "wired" },
    { label: "wireless" },

],
hard_drive_width: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
],
hard_drive_height: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
    { label: "11 cm" },
    { label: "12 cm" },
    { label: "13 cm" },
    { label: "14 cm" },
    { label: "15 cm" },
    { label: "16 cm" },
    { label: "17 cm" },
    { label: "18 cm" },
    { label: "19 cm" },
    { label: "20 cm" },
],
hard_drive_depth: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },

],
hard_drive_weight: [
    { label: "50 g" },
    { label: "60 g" },
    { label: "70 g" },
    { label: "80 g" },
    { label: "90 g" },
    { label: "100 g" },
    { label: "110 g" },
    { label: "120 g" },
    { label: "130 g" },
    { label: "140 g" },
    { label: "150 g" },
    { label: "160 g" },
    { label: "170 g" },
    { label: "180 g" },
    { label: "190 g" },
    { label: "200 g" },
    { label: "210 g" },
    { label: "220 g" },
    { label: "230 g" },
    { label: "240 g" },
    { label: "250 g" },
    { label: "260 g" },
    { label: "270 g" },
    { label: "280 g" },
    { label: "290 g" },
    { label: "300 g" },

],
// Network storage drives:
Network_storage_Type: [
    { label: "with modem" },
    { label: "without modem" },
],
net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
],
operating_system: [
    { label: "above windows 7" },
    { label: "MacOS" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
],
Operating_range: [
    { label: "10m" },
    { label: "20m" },
    { label: "30m" },
    { label: "40m" },
    { label: "50m" },
    { label: "60m" },
    { label: "70m" },
    { label: "80m" },
    { label: "90m" },
    { label: "100m" },
],
Frequency: [
    { label: "2.4 GHz " },
    { label: "5GHz" },
    { label: "Dual band" },
],
Frequency_band: [
    { label: "2.4 GHz" },
    { label: "5GHz" },
],
Wireless_speed: [
    { label: "1Mbps" },
    { label: "10 Mbps" },
    { label: "100 Mbps" },
    { label: "1 Gbps (1000 Mbps)" },
    { label: "10 Gbps" },
],

Dsl_modem_speed: [
    { label: "0Mbps" },
    { label: "10Mbps" },
    { label: "15Mbps" },
    { label: "20Mbps" },
    { label: "30Mbps" },
    { label: "40Mbps" },
    { label: "50Mbps" },
    { label: "60Mbps" },
    { label: "70Mbps" },
    { label: "80Mbps" },
    { label: "90Mbps" },
    { label: "100 Mbps" },
],
Lan_wan: [
    { label: "1-100 Mbps" },
    { label: "10-100 Mbps" },
],

Antennae: [
    { label: "External" },
    { label: "Internal" },
],
Number_of_usb_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },


],

//Keyboard
Keyboard_Type: [
    { label: "desktop" },
    { label: "keyboard" },
    { label: "gaming keyboard" },
    { label: "wireless keyboard" },
    { label: "touchscreen keyboard" },
    { label: "compact keyboard" },
    { label: "mechanical keyboard" },
],
// Solid State Drives:
solid_state_Type: [
    { label: "SSD" },
    { label: "M.2SATA" },
    { label: "M.2 NVMe" },
    { label: " PCIe " },
    { label: "U.2" },
],
Capacity: [
    { label: "120 GB" },
    { label: "240 GB" },
    { label: "256 GB" },
    { label: "480 GB" },
    { label: "500 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
    { label: "2 TB" },
    { label: "4 TB" },
    { label: "8 TB" },

],
System_Requirements: [
    { label: "Windows" },
    { label: "macOS" },
    { label: "linux" },
    { label: "Android" },
],
Form_factor: [
    { label: "Portable" },
],
Number_of_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
],
Connector_Type: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
],
Case_material: [
    { label: "Aluminum " },
    { label: "Plastc " },
    { label: "Metal Alloys" },
    { label: "Composite Materials" },
],
solid_state_width: [
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },

],
solid_state_height: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
    { label: "82mm " },
    { label: "84mm " },
    { label: "86mm " },
    { label: "88mm " },
    { label: "90mm " },
    { label: "92mm " },
    { label: "94mm " },
    { label: "96mm " },
    { label: "98mm " },
    { label: "100mm " },
],
solid_state_depth: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
],
solid_state_weight: [
    { label: "5 g" },
    { label: "10 g" },
    { label: "15 g" },
    { label: "20 g" },
    { label: "25 g" },
    { label: "30 g" },
    { label: "35 g" },
    { label: "40 g" },
    { label: "45 g" },
    { label: "50 g" },
    { label: "55 g" },
]
 ,

// ------------FootWear------------------------
Outer_material : [
  { label: "mesh" },
  { label: "polyester" },
  { label: "nylon" },
],
Ideal_for  : [
  { label: "running" },
  { label: "jogging" },
  { label: "walking" },
  { label: " gym workouts" },
],
Occasion : [
  { label: "casual outings" },
  { label: "exercise" },
  { label: "sports activities" },
  { label: "informal occasions" },
],
Type_of_sports: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "hiking" },
  { label: "weightlifting" },
  { label: "cross-training" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "TPU (thermoplastic polyurethane)" },
],
Closure: [
  { label: "laces" },
  { label: "hook-and-loop straps (Velcro)" },
  { label: "zippers" },
  { label: "slip-on designs" },
],
Upper_pattern : [
  { label: "mesh panels" },
  { label: "overlays" },
  { label: "stitching patterns" },
  { label: "designed to provide support" },
  { label: "breathability" },
  { label: "flexibility" },
  { label: "aesthetic appeal" },
],
Season: [
  { label: "summer" },
  { label: "rainy" },
  { label: "snow" },
],
// ----------------------Casual Shoes----------------------------------
Outer_material : [
  { label: "leather" },
  { label: "canvas" },
  { label: "suede" },
  { label: "synthetic fabrics" },
],
Ideal_for  : [
  { label: "everyday wear" },
  { label: "social gatherings" },
  { label: "informal outings" },
  { label: "relaxed settings" },
],
Occasion : [
  { label: "hanging out with friends" },
  { label: "going to casual dinners or parties" },
  { label: "running errands" },
  { label: "simply enjoying leisure time" },
],
Type_of_casual: [
  { label: "sneakers" },
  { label: "loafers" },
  { label: "boat shoes" },
  { label: "moccasins" },
  { label: "slip-ons" },
  { label: "sandals" },
  { label: "espadrilles" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "TPU (thermoplastic polyurethane)" },
  { label: "synthetic compounds" },
],
Closure: [
  { label: "laces" },
  { label: "hook-and-loop straps (Velcro)" },
  { label: "buckles" },
  { label: "elastic bands" },
  { label: "slip-on designs" },
],
Upper_pattern : [
  { label: "stitching patterns" },
  { label: "overlays" },
  { label: "perforations" },
  { label: "embossed designs" },
],
Tip_shape: [
  { label: "rounded to pointed" },
  { label: "even square" },
],

// ---------------formal shoes--------------------------------------

Outer_material : [
  { label: "leather" },
  { label: "synthetic leather" },
],
Ideal_for  : [
  { label: "professional environments" },
  { label: "business meetings" },
  { label: "formal events" },
],
Occasion : [
  { label: "weddings" },
  { label: "business meetings" },
  { label: "job interviews" },
  { label: "gala dinners" },
],
Type_of_formal: [
  { label: "Oxford shoes" },
  { label: "Derby shoes" },
  { label: "Brogues" },
  { label: "Monk straps" },
  { label: "Loafers" },
  { label: "Dress boots" },
],
Closure: [
  { label: "laces" },
  { label: "buckles" },
  { label: "slip-on designs" },
],
Season: [
  { label: "summer" },
  { label: "rainy" },
  { label: "winter" },
],

//-----------------------------Sandles & Floaters ---------------------------

Type: [
  { label: "Flip-flops" },
  { label: "Slides" },
  { label: "Gladiator sandals" },
  { label: "Espadrilles" },
  { label: "Sport sandals" },
  { label: "Floaters" },
],
Closure: [
  { label: "Straps with buckles" },
  { label: " Velcro straps" },
  { label: "slip-on designs" },
  { label: "Elastic bands" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "synthetic compounds" },
],

// ------------------------Flip- flops  -----------------------------------

Ideal_for: [
  { label: "casual wear" },
  { label: "beach outings" },
  { label: "poolside relaxation" },
  { label: "any laid-back occasion" },
],

// -------------------------------Loafers  ------------------------------

Outer_material : [
  { label: "leather" },
  { label: "suede" },
],
Type_of_casual: [
  { label: "slip-on design" },
  { label: "low heels" },
  { label: "a moccasin-style construction" },
],
Ideal_for: [
  { label: "social gatherings" },
  { label: "weekend outings" },
  { label: "relaxed workplaces" },
],
Occasion : [
  { label: "Casual Fridays" },
  { label: "Weekend outings" },
  { label: "Barbecues or picnics" },
  { label: "Casual dinners or dates" },
  { label: "Shopping trips or errands" },
  { label: "Traveling" },
  { label: "sightseeing" },
],

// -------------------------Boots------------------------------------

Outer_material : [
  { label: "leather genuine" },
  { label: "synthetic leather" },
  { label: "suede" },
  { label: "canvas" },
  { label: "rubber" },
],
Ideal_for: [
  { label: "hiking" },
  { label: "camping" },
  { label: "hunting" },
],
Occasion : [
  { label: "Casual outings" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Work" },
],
Type_of_casual: [
  { label: "Chelsea boots" },
  { label: "Chukka boots" },
  { label: "Desert boots" },
  { label: "Ankle boots" },
  { label: "Combat boots" },
  { label: "Hiking boots" },
  { label: "Work boots" },
],
Sole_material: [
  { label: "rubber" },
  { label: "polyurethane (PU)" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "thermoplastic polyurethane (TPU)" },
  { label: "tleather" },
],

// ----------------------Running Shoes-----------------------------------

Outer_material : [
  { label: "mesh" },
  { label: "polyester" },
  { label: "nylon" },
],
Ideal_for  : [
  { label: "running" },
  { label: "jogging" },
  { label: "walking" },
  { label: " gym workouts" },
],
Occasion : [
  { label: "casual outings" },
  { label: "exercise" },
  { label: "sports activities" },
  { label: "informal occasions" },
],
Type_of_sports: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "hiking" },
  { label: "weightlifting" },
  { label: "cross-training" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "TPU (thermoplastic polyurethane)" },
],
Closure: [
  { label: "laces" },
  { label: "hook-and-loop straps (Velcro)" },
  { label: "zippers" },
  { label: "slip-on designs" },
],
Upper_pattern : [
  { label: "mesh panels" },
  { label: "overlays" },
  { label: "stitching patterns" },
  { label: "designed to provide support" },
  { label: "breathability" },
  { label: "flexibility" },
  { label: "aesthetic appeal" },
],
Weight: [
  { label: "100g" },
  { label: "200g" },
  { label: "300g" },
  { label: "400g" },
  { label: "500g" },
  { label: "600g" },
  { label: "700g" },
],
Season: [
  { label: "summer" },
  { label: "rainy" },
  { label: "snow" },
],

// ------------------------Sneakers---------------------------------

Outer_material : [
  { label: "leather" },
  { label: "canvas" },
  { label: "suede" },
  { label: "synthetic fabrics" },
],
Ideal_for  : [
  { label: "everyday wear" },
  { label: "social gatherings" },
  { label: "informal outings" },
  { label: "relaxed settings" },
],
Occasion : [
  { label: "hanging out with friends" },
  { label: "going to casual dinners or parties" },
  { label: "running errands" },
  { label: "simply enjoying leisure time" },
],
Type_of_casual: [
  { label: "sneakers" },
  { label: "loafers" },
  { label: "boat shoes" },
  { label: "moccasins" },
  { label: "slip-ons" },
  { label: "sandals" },
  { label: "espadrilles" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA (ethylene-vinyl acetate)" },
  { label: "TPU (thermoplastic polyurethane)" },
  { label: "synthetic compounds" },
],
Closure: [
  { label: "laces" },
  { label: "hook-and-loop straps (Velcro)" },
  { label: "buckles" },
  { label: "slip-on designs" },
  { label: "elastic bands" },
],
Upper_pattern : [
  { label: "stitching patterns" },
  { label: "overlays" },
  { label: "stitching patterns" },
  { label: "perforations" },
  { label: "embossed designs" },
],
Tip_shape: [
  { label: "rounded to pointed" },
  { label: "even square" },
],

// -------------------------WOMENS-FLATS---------------------------------

Type: [
  { label: "Ballet flats" },
  { label: "loafers" },
  { label: "espadrilles" },
  { label: "moccasins" },
  { label: "D’Orsay Flats" },
  { label: "Pointed-Toe Flats" },
  { label: "Mary jane flats" },
  { label: "slingback flates" },
  { label: "mules" },
  { label: "T-strap Flats" },
],
Type_of_flats: [
  { label: "Ballet flats" },
  { label: "loafers" },
  { label: "espadrilles" },
  { label: " moccasins D’orsay" },
  { label: "pointed –Toe flats" },
  { label: "Mary jane flats" },
  { label: "slingback flats" },
  { label: "Mules" },
  { label: "T-strap flats" },
],

// ----------------------------------Heels--------------------------

Type: [
  { label: "Stiletto" },
  { label: "block" },
  { label: "kitten" },
  { label: "wedge" },
  { label: "platform" },
  { label: "cone" },
  { label: "slingback" },
  { label: "peep-top" },
  { label: "ankle strap" },
  { label: "chunky heels" },
  { label: "sandals" },
],
Type_of_heels: [
  { label: "1 inch" },
  { label: "1.5 inch" },
  { label: "2 inch" },
  { label: "2.5 inch" },
  { label: "3 inch" },
  { label: "3.5 inch" },
  { label: "4 inch" },
  { label: "4.5 inch" },
  { label: "5 inch" },
  { label: "5.5 inch" },
  { label: "6 inch" },           
],
Upper_pattern : [
  { label: "solid" },
  { label: "plain" },
  { label: "textured" },
  { label: "printed" },
  { label: "embellished" },
  { label: "perforated" },
  { label: "cut-out" },
  { label: "striped" },
  { label: "checkered/plaid" },
],

// ----------------------------------Wedges-----------------------------------

Type: [
  { label: "pumps" },
  { label: "platform" },
  { label: "cork" },
  { label: "espadrille" },
  { label: "wood" },
  { label: "peep-top" },
  { label: "slingback" },
  { label: "ankle" },
  { label: "lace-up" },
  { label: "T-Strap" },
],
Type_of_Wedges: [
  { label: "Stiletto" },
  { label: "block" },
  { label: "kitten" },
  { label: "wedge" },
  { label: "platform" },
  { label: "cone" },
  { label: "slingback" },
  { label: "peep-top" },
  { label: "ankle strap" },
  { label: "chunky heels" },
  { label: "sandals" },           
],

// ------------------------------------Sports Shoes------------------------------

Type: [
  { label: "Running" },
  { label: "training" },
  { label: "basketball" },
  { label: "Soccer cleats" },
  { label: "Tennis" },
  { label: "Golf" },
  { label: "Cross-training" },
  { label: "trail running" },
  { label: "walkin" },
  { label: "hikin boots" },
],
Outer_Material : [
  { label: "Mesh" },
  { label: "synthetic leather" },
  { label: "leather" },
  { label: "knit" },
  { label: "synthetic" },
  { label: "textile" },
  { label: "rubber" },          
  { label: "nylon" },          
  { label: "polyster" },          
],
Ideal_for: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "tennsi" },
  { label: "golf" },
  { label: "crodd –training" },
  { label: "trail running" },          
  { label: "walking shoes" },          
  { label: "hiking boots" },          
],
Occasion : [
  { label: "regular" },
  { label: "everyday" },
  { label: "athletic" },
  { label: "outdoor" },
  { label: "casual" },
  { label: "formal occasions" },
],
Type_of_sports: [
  { label: "Basketball" },
  { label: "soccer" },
  { label: "tennis" },
  { label: "golf" },
  { label: "running" },
  { label: "cycling" },
  { label: "swimming" },
  { label: "volleyball" },
  { label: "baseball" },
  { label: "surfing" },
  { label: "gymnastics" },           
  { label: "skiing" },           
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "TPU" },
  { label: "Phylon" },
  { label: "Carbon Fiber" },
  { label: "Gum Rubber" },
],
Closure: [
  { label: "laces" },
  { label: "Velcro starps" },
  { label: "zippers" },
  { label: "buckles" },
  { label: "slip –on" },
  { label: "boa system" },
  { label: "Elastic" },
],
Season: [
  { label: "Spring" },
  { label: "summer" },
  { label: "fall" },
  { label: "snow" },        
  { label: "year-round" },        
],

// --------------------------------Causual Shoes---------------------------------

Outer_material : [
  { label: "Leather" },
  { label: "Canvas" },
  { label: "suede" },
  { label: "synthetic materials" },
  { label: "mesh" },
  { label: "textile" },          
],
Ideal_for: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "tennsi" },
  { label: "golf" },
  { label: "crodd –training" },
  { label: "trail running" },          
  { label: "walking shoes" },          
  { label: "hiking boots" },          
],
Occasion : [
  { label: "regular" },
  { label: "everyday" },
  { label: "athletic" },
  { label: "outdoor" },
  { label: "casual" },
  { label: "formal occasions" },
],
Type_of_causual : [
  { label: "sneakers" },
  { label: "loafers" },
  { label: "boat shoes" },
  { label: "espadrilles" },
  { label: "moccasins" },
  { label: "derby shoes" },        
],
Upper_pattern : [
  { label: "solid" },
  { label: "plain" },
  { label: "textured" },
  { label: "printed" },
  { label: "embellished" },
  { label: "perforated" },
],
Tip_shape: [
  { label: "Round" },
  { label: "square" },
  { label: "pointed" },
  { label: "almond" },
  { label: "chisel" },
],

// -------------------------------Boots-------------------------------

Outer_material : [
  { label: "Leather" },
  { label: "Synthetic Leather" },
  { label: "fabric" },
  { label: "rubber" },
  { label: "suede" },
  { label: "gore-tex" },
  { label: "textile" },          
],
Ideal_for: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "tennsi" },
  { label: "golf" },
  { label: "crodd –training" },
  { label: "trail running" },          
  { label: "walking shoes" },          
  { label: "hiking boots" },          
],
Occasion : [
  { label: "regular" },
  { label: "everyday" },
  { label: "athletic" },
  { label: "outdoor" },
  { label: "casual" },
  { label: "formal occasions" },
],
Type_of_causual : [
  { label: "sneakers" },
  { label: "loafers" },
  { label: "boat shoes" },
  { label: "espadrilles" },
  { label: "moccasins" },
  { label: "derby shoes" },        
],
Season: [
  { label: "Spring" },
  { label: "summer" },
  { label: "fall" },
  { label: "snow" },        
  { label: "year-round" },        
],


//---------------------- KIDS WEAR-Boys Footwear----------------------------
//--------------------------------Sandals-------------------------------------

Number_of_pairs: [
  { label: "1 pairs" },    
  { label: "2 pairs" },    
  { label: "3 pairs" },    
],
Size: [
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],
Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Base_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "leather" },
  { label: "PU" },
  { label: "Foam" },
],
Strap_material : [
  { label: "leather" },
  { label: "synthetic leather" },
  { label: "fabric" },
  { label: "nylon" },
  { label: "rubber" },
  { label: "PU" },
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],

// --------------------------------Sports shoes----------------------------------

Number_of_pairs: [
  { label: "1 pairs" },    
  { label: "2 pairs" },    
  { label: "3 pairs" },    
],
Size: [
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],
Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Type: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "football" },
  { label: "tennis" },
  { label: "hiking" },        
  { label: "shoes" },        
  { label: "gym shoes" },        
],
Sub_type:[
  { label: "Trail Running shoes" },
  { label: "Cross Training" },
  { label: "Racing Flats" },
  { label: "track spikes" },
  { label: "cycling shoes" },
  { label: "wrestling shoes" },
  { label: "golf shoes" },
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Outer_material : [
  { label: "Leather" },
  { label: "Synthetic Leather" },
  { label: "fabric" },
  { label: "rubber" },
  { label: "suede" },
  { label: "gore-tex" },
  { label: "textile" },          
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],


// ---------------------Girls footwear Flats & bellies---------------------------


Size: [
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],
Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Heel_type: [
  { label: "stiletto" },
  { label: "Block" },
  { label: "wedge" },
  { label: "kitten" },
  { label: "platform" },
  { label: "cone" },        
  { label: "spool" },        
  { label: "louis" },        
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],
Length: [
  { label: "1 years" },  
  { label: "2 years" },  
  { label: "3 years" },  
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],

//----------------------------Sports shoes------------------------

Number_of_pairs: [
  { label: "1 pair" },    
  { label: "2 pair" },    
  { label: "3 pair" },    
],
Size: [
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Type: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "football" },
  { label: "tennis" },
  { label: "hiking" },        
  { label: "shoes" },        
  { label: "gym shoes" },        
],
Sub_type: [
  { label: "Trail Running shoes" },
  { label: "Cross Training" },
  { label: "Racing Flats" },
  { label: "track spikes" },
  { label: "cycling shoes" },
  { label: "wrestling shoes" },        
  { label: "golf shoes" },              
],
Outer_material : [
  { label: "Leather" },
  { label: "Synthetic Leather" },
  { label: "mesh" },
  { label: "canvas" },
  { label: "suede" },
  { label: "rubber" },
  { label: "knit" },          
  { label: "textile" },          
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],
Heel_design: [
  { label: "classic pump" },
  { label: "chuncky" },
  { label: "wedge" },
  { label: "kitten" },
  { label: "spool" },
  { label: "Louis" },        
  { label: "cone" },               
],
Heel_type: [
  { label: "stiletto" },
  { label: "Block" },
  { label: "wedge" },
  { label: "kitten" },
  { label: "platform" },
  { label: "cone" },        
  { label: "spool" },        
  { label: "louis" },        
],

//-------------------------------Character shoes------------------------------------

Number_of_pairs: [
  { label: "1 pair" },    
  { label: "2 pair" },    
  { label: "3 pair" },    
],
Size: [
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],
Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Base_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "leather" },
  { label: "PU" },
  { label: "Foam" },
],
Length: [
  { label: "1 years" },  
  { label: "2 years" },  
  { label: "3 years" },  
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },    
],

//-----------------------------------Sports shoes--------------------------

Number_of_pairs: [
  { label: "1 pair" },    
  { label: "2 pair" },    
  { label: "3 pair" },    
],

Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Type: [
  { label: "running" },
  { label: "basketball" },
  { label: "soccer" },
  { label: "football" },
  { label: "tennis" },
  { label: "hiking" },        
  { label: "shoes" },        
  { label: "gym shoes" },        
],
Sub_type: [
  { label: "Trail Running shoes" },
  { label: "Cross Training" },
  { label: "Racing Flats" },
  { label: "track spikes" },
  { label: "cycling shoes" },
  { label: "wrestling shoes" },        
  { label: "golf shoes" },              
],
Outer_material : [
  { label: "Leather" },
  { label: "Synthetic Leather" },
  { label: "mesh" },
  { label: "canvas" },
  { label: "suede" },
  { label: "rubber" },
  { label: "knit" },          
  { label: "textile" },          
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],
Heel_design: [
  { label: "classic pump" },
  { label: "chuncky" },
  { label: "wedge" },
  { label: "kitten" },
  { label: "spool" },
  { label: "Louis" },        
  { label: "cone" },               
],
Heel_type: [
  { label: "stiletto" },
  { label: "Block" },
  { label: "wedge" },
  { label: "kitten" },
  { label: "platform" },
  { label: "cone" },        
  { label: "spool" },        
  { label: "louis" },        
],

//-------------------------------Character shoes-----------------------------

Number_of_pairs: [
  { label: "1 pair" },    
  { label: "2 pair" },    
  { label: "3 pair" },    
],

Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Base_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "leather" },
  { label: "PU" },
  { label: "Foam" },
],
Length: [  
  { label: "3 years" },  
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },        
],

//-----------------------------------Infant footwear-------------------------

Number_of_pairs: [
  { label: "1 pair" },    
  { label: "2 pair" },    
  { label: "3 pair" },    
],
Size: [
  { label: "1 Month" },    
  { label: "2 Month" },    
  { label: "3 Month" },    
  { label: "4 Month" },    
  { label: "5 Month" },    
  { label: "6 Month" },    
  { label: "7 Month" },    
  { label: "8 Month" },    
  { label: "9 Month" },    
  { label: "10 Month" },    
  { label: "11 Month" },    
  { label: "1 year" },    
  { label: "2 year" },    
  { label: "3 year" },    
  { label: "4 years" },    
  { label: "5 years" },    
  { label: "6 years" },    
  { label: "7 years" },    
  { label: "8 years" },    
  { label: "9 years" },    
  { label: "10 years" },    
  { label: "11 years" },    
  { label: "12 years" },    
  { label: "13 years" },    
  { label: "14 years" },    
  { label: "15 years" },    
  { label: "16 years" },],
Ideal_for: [
  { label: "Everyday wear" },
  { label: "Sports and athletics" },
  { label: "Formal events" },
  { label: "Outdoor activities" },
  { label: " Water activities" },        
],
Closure_type : [
  { label: "velcro" },
  { label: "buckle" },
  { label: "slip-on" },
  { label: "elastic" },
  { label: "zipper" },
  { label: "lace up" },
],
Type: [
  { label: "soft-soled" },
  { label: "crib" },
  { label: "booties" },
  { label: "pre-walker" },
  { label: "sandal style" },       
],
Sub_type: [
  { label: "Booties" },
  { label: "Crib shoes" },
  { label: "Soft-soled shoes" },
  { label: "Pre-walkers" },
  { label: "Sandals" },             
],
Outer_material : [
  { label: "Leather" },
  { label: "Synthetic Leather" },
  { label: "canvas" },
  { label: "textile" },
  { label: "mesh" },
  { label: "suede" },         
],
Sole_material: [
  { label: "rubber" },
  { label: "EVA" },
  { label: "PU" },
  { label: "TPR" },
],
Heel_design: [
  { label: "Flat heel" },
  { label: "soft sole" },
  { label: "elastic heel" },
  { label: "no heel" },               
],
Insole_material: [
  { label: "soft fabric" },
  { label: "cotton" },
  { label: "memory foam" },
  { label: "EVA" },               
  { label: "Leather" },               
  { label: "textile" },               
],
Character: [
  { label: "low heel" },
  { label: "straps" },
  { label: "sturdy construction" },
  { label: "variety of styles" },                              
],
Length: [  
  { label: "1 Month" },  
  { label: "2 Month" },    
  { label: "3 Month" },    
  { label: "4 Month" },    
  { label: "5 Month" },    
  { label: "6 Month" },    
  { label: "7 Month" },    
  { label: "8 Month" },    
  { label: "9 Month" },    
  { label: "10 Month" },    
  { label: "11 Month" },    
  { label: "12 Month" },    
  { label: "13 Month" },    
  { label: "14 Month" },    
  { label: "15 Month" },    
  { label: "16 Month" },    
  { label: "17 Month" },    
  { label: "18 Month" },    
  { label: "19 Month" },    
  { label: "20 Month" },    
  { label: "21 Month" },    
  { label: "22 Month" },    
  { label: "23 Month" },    
  { label: "24 Month" },         
],
Warranty: [  
  { label: "1 Month" },  
  { label: "2 Month" },    
  { label: "3 Month" },    
  { label: "4 Month" },    
  { label: "5 Month" },    
  { label: "6 Month" },  
  { label: "1 Year" },            
],
};

const MessurentsSlice = createSlice({
  name: "Messurents",
  initialState,
});

export const {} = MessurentsSlice.actions;
export default MessurentsSlice.reducer;
