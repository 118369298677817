import React from 'react'
import { FaArrowRight } from "react-icons/fa6";
import { AiFillExclamationCircle } from "react-icons/ai";

const Dashbord_Reports = () => {
  return (
    <>
      <div className='reportmaincontainer'>
      <div style={{display:"flex",gap:'3vh'}}>
            <div><img src="/assets/images/service.png" height="50px" width="auto" /></div>
          <div>
            <h6 className='needhelptext'>Need help with Account Management Service?</h6>
            <div style={{display:"flex",alignItems:"center"}}>
              <p style={{fontSize:"13px"}}>Starts at</p>
              <div className='monthtext'>2500/month</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",borderTop:".5px solid #ccc",paddingTop:"15px" }}>
        <AiFillExclamationCircle />
          <h6 style={{fontSize:"10px"}}>E-VANIJYA BRIDDHI PRIVATE LIMITED</h6>
          <FaArrowRight />
        </div>
      </div>  
    </>
  )
}

export default Dashbord_Reports