import React, { useState } from "react";
import { Drawer, Radio, Space } from "antd";
import { VscThreeBars } from "react-icons/vsc";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "react-bootstrap";
import { IoPerson } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const Mobile_Header = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="mob-sticky">
      <div className="header-wrapper">
        <span className="existingtext">
          Existing Seller? Explore our product recommendations with Dhamaka Selection
        </span>
        <button className="loginbtn_container" onClick={() => navigate("/login")}>
          Login
        </button>
      </div>
      <div className="row-wrapper">
        <div className="row w-100 h-100 justify-content-around mx-auto align-items-center">
          {/* Left column */}
          <div className="col-7">
            <div className="d-flex justify-content-end text-white align-items-center">
              <div className="profile-image">
                <span>
                  <img src="/assets/images/ew10.png" alt="Profile" />
                </span>
              </div>
            </div>
          </div>
          {/* Right column */}
          <div className="col-5">
            <Space>
              <Radio.Group value={placement} onChange={(e) => setPlacement(e.target.value)}>
                <Radio className="d-none" style={{ visibility: "hidden" }} value="right"></Radio>
              </Radio.Group>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <h6 className="d-flex justify-content-center align-items-center text-white">
                  {/* <span>
                    <VscThreeBars onClick={showDrawer} className="threebars text-dark" />
                  </span> */}
                  <span className="start-selling-btn" onClick={() => navigate("/registation")}>
                    Start Selling
                  </span>
                </h6>
              </div>
            </Space>
            <Drawer
              placement={placement}
              closable={false}
              onClose={onClose}
              open={open}
              key={placement}
              width={300}
              className={`custom-drawer ${open ? "custom-drawer-enter" : "custom-drawer-leave"}`}
            >
              <div>
                <div className="profile d-flex align-items-center justify-content-between">
                  <span>
                    <img src="/assets/images/ew10.png" alt="Profile" />
                  </span>{" "}
                  <IoIosClose className="text-dark fs-3" onClick={onClose} />
                </div>
                <div className="menu-list mt-4">
                  <div className="menu-item text-center d-flex align-items-center">
                    <Dropdown className="w-100">
                      <DropdownToggle className="text-dark w-100 justify-content-between border-0 text-dark d-flex align-items-center bg-white">
                        <span>Sell Online</span>
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        <DropdownItem>Create Account</DropdownItem>
                        <DropdownItem>List Products</DropdownItem>
                        <DropdownItem>Storage & Shipping</DropdownItem>
                        <DropdownItem>Receive Payments</DropdownItem>
                        <DropdownItem>Grow Faster</DropdownItem>
                        <DropdownItem>Seller App</DropdownItem>
                        <DropdownItem>Help & Support</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="menu-item text-center d-flex align-items-center">
                    <Dropdown className="w-100">
                      <DropdownToggle className="text-dark w-100 justify-content-between border-0 text-dark d-flex align-items-center bg-white">
                        <span>Fees and Commission</span>
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        <DropdownItem>Create Account</DropdownItem>
                        <DropdownItem>List Products</DropdownItem>
                        <DropdownItem>Storage & Shipping</DropdownItem>
                        <DropdownItem>Receive Payments</DropdownItem>
                        <DropdownItem>Grow Faster</DropdownItem>
                        <DropdownItem>Seller App</DropdownItem>
                        <DropdownItem>Help & Support</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="menu-item text-center d-flex align-items-center">
                    <Dropdown className="w-100">
                      <DropdownToggle className="text-dark w-100 justify-content-between border-0 text-dark d-flex align-items-center bg-white">
                        <span>Grow</span>{" "}
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        <DropdownItem>Create Account</DropdownItem>
                        <DropdownItem>List Products</DropdownItem>
                        <DropdownItem>Storage & Shipping</DropdownItem>
                        <DropdownItem>Receive Payments</DropdownItem>
                        <DropdownItem>Grow Faster</DropdownItem>
                        <DropdownItem>Seller App</DropdownItem>
                        <DropdownItem>Help & Support</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="menu-item text-center d-flex align-items-center">
                    <Dropdown className="w-100">
                      <DropdownToggle className="text-dark justify-content-between w-100 border-0 text-dark d-flex align-items-center bg-white">
                        <span>Learn</span>{" "}
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        <DropdownItem>Create Account</DropdownItem>
                        <DropdownItem>List Products</DropdownItem>
                        <DropdownItem>Storage & Shipping</DropdownItem>
                        <DropdownItem>Receive Payments</DropdownItem>
                        <DropdownItem>Grow Faster</DropdownItem>
                        <DropdownItem>Seller App</DropdownItem>
                        <DropdownItem>Help & Support</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="menu-item text-center d-flex align-items-center">
                    <Dropdown className="w-100">
                      <DropdownToggle className="text-dark w-100 justify-content-between border-0 text-dark d-flex align-items-center bg-white">
                        <span>Shopsy</span>
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        <DropdownItem>Create Account</DropdownItem>
                        <DropdownItem>List Products</DropdownItem>
                        <DropdownItem>Storage & Shipping</DropdownItem>
                        <DropdownItem>Receive Payments</DropdownItem>
                        <DropdownItem>Grow Faster</DropdownItem>
                        <DropdownItem>Seller App</DropdownItem>
                        <DropdownItem>Help & Support</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile_Header;
