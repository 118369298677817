import React from "react";
import { Steps } from "antd";
import { useSelector } from "react-redux";

function StepComponent() {
  const { seller_Registation_status, seller_details } = useSelector(
    (state) => state.authentication
  );
  return (
    <Steps
      className="step mb-4"
      current={seller_details === "" ? 0 : seller_Registation_status}
      items={[
        {
          title: "EMAIL ID & PASSWORD",
        },
        {
          title: "GST & SHOP DETAILS",
        },
        {
          title: "ONBOARDING DASHBOARD",
        },
      ]}
    />
  );
}

export default StepComponent;
