import React from "react";
import { useSelector } from "react-redux";
import Step_one from "./components/Step_one";
import Step_two from "./components/Step_two";
import Step_three from "./components/Step_three";

const Seller_Registation = () => {
  const { seller_Registation_status } = useSelector(
    (state) => state.authentication
  );
  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <Step_one />;
      case 1:
        return <Step_two />;
      case 2:
        return <Step_three />;
      default:
        return <Step_one />;
        break;
    }
  };
  return (
    <>

      {renderSwitch(seller_Registation_status)}
    </>
  );
};

export default Seller_Registation;
