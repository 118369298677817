import React, { useState } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { AudioOutlined, DownOutlined } from "@ant-design/icons";
import { IoSearch } from "react-icons/io5";
import {
  Input,
  Tooltip,
  Dropdown,
  Space,
  Button,
  Flex,
  Radio,
  ConfigProvider,
} from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { setReset_data } from "../../redux/product/productSlice";

const Product_Listing_Management = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { producttotal } = useSelector((store) => store.product);

  const items = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  const text = <span>prompt text</span>;

  const columns = [
    { field: "name", headerName: "Product Details", width: 300 },

    {
      field: "category",
      headerName: "Category",
      width: 170,
    },
    {
      field: "CategoryTag",
      headerName: "Category Tag",
      width: 170,
    },
    // {
    //   field: "Quality",
    //   headerName: "Listing Quality",
    //   width: 150,
    // },
    // {
    //   field: "Action",
    //   headerName: "Additional Info",
    //   width: 200,
    // },
  ];

  return (
    <>
      <div className="listing_header">
        <div className="listing_header_left">
          <div className="list_header_left_top">
            <p>
              Home
              <span>
                <MdOutlineChevronRight />
              </span>
            </p>
            <span className="header_name">Listings Management</span>
          </div>
        </div>
        <div className="listing_header_right">
          <Input
            placeholder="Enter your username"
            size="large"
            suffix={
              <Tooltip title="">
                <IoSearch
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              </Tooltip>
            }
            className="header-search"
          />
          <Button
            onClick={() => {
              dispatch(setReset_data());
              navigate("/seller/new-product");
            }}
          >
            <Space>Add New Listing</Space>
          </Button>
        </div>
      </div>
      <div className="main_screen">
        <div className="radio_laptop">
          <div className="">
            <Radio.Group defaultValue="a" size="large">
              <Radio.Button value="a" className="radio_buttons_listing">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">{producttotal.length}</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Active Listings</span>
              </Radio.Button>
              <Radio.Button value="b" className="radio_buttons_listing">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">0</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Ready for Activation</span>
              </Radio.Button>
              <Radio.Button value="c" className="radio_buttons_listing">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">0</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Blocked Listings</span>
              </Radio.Button>
              <Radio.Button value="d" className="radio_buttons_listing">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">0</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Inactive Listings</span>
              </Radio.Button>
              <Radio.Button value="e" className="radio_buttons_listing ">
                <div className="rd_btn_lst_top">
                  <span className="lst_count">0</span>
                  <Tooltip placement="rightTop" title={text}>
                    <FaInfoCircle className="list_info_icon" />
                  </Tooltip>
                </div>
                <span className="rd_btn_lst_bottom">Archived Listings</span>
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {/* <div className="fillering_div">
          <div className="downloade_btn">
            <Dropdown
              className="header_dropdown"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Download <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
          <div className="filter_btn">
            <Dropdown
              className="header_dropdown"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Sort By <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              className="header_dropdown"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Customize Columns <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div> */}
        {/* <div className="product_filtering">
          <div className="product_filtering_left">
            <FaFilter />
          </div>
          <div className="product_filtering_right">
            <Dropdown
              className="header_dropdown margin_class"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Category <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              className="header_dropdown margin_class"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Brand <DownOutlined />
                </Space>
              </Button>
            </Dropdown>

            <Dropdown
              className="header_dropdown margin_class"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Stock <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              className="header_dropdown margin_class"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Listing Price <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Dropdown
              className="header_dropdown margin_class"
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Listing Quality <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div> */}
        <div className="product_filtering_grid mt-4">
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={producttotal}
              columns={columns}
              getRowId={(row) => row._id}
              rowHeight={100}
              getRowHeight={() => "auto"}
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 30, 50, 100]}
              checkboxSelection
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product_Listing_Management;
