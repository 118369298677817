
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subcategorytotal: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
    : [],
  subcategoryShow: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true).sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
    : [],

  mobileimage: "",
  desktopimage: "",
  isSubCatmobileImageLoading: true,
  isSubDeskImageLoading: true,
  isLoading: true,
  subCategoryLoading: true,
  deleteSubCatLoading: true,
  checkSlugurl: true,
};

export const getSubCategory = createAsyncThunk(
  "SubCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/allforadmin`;
      const resp = await axios(url);

      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const subCategoryPost = createAsyncThunk(
  "subCategory/subCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

export const subCategoryImages = createAsyncThunk(
  "category/categoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/subcategoryimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategoryimages Not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "subcategory/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/subcategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const subCategoryUpdate = createAsyncThunk(
  "subCategory/subCategoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/${formData.subcatid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);
export const subCategoryDelete = createAsyncThunk(
  "subCategory/subCategoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      }
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

const SubCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {
    updateSubcatMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.isSubCatmobileImageLoading = false;
    },
    updateSubcatDeskImage(state, action) {
      state.desktopimage = action.payload;
      state.isSubDeskImageLoading = false;
    },
    removeSubcatImage(state, action) {
      state.desktopimage = "";
      state.mobileimage = "";
      state.isSubDeskImageLoading = true;
    },
    deleteSubcategory(state, action) {
      state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.subcategorytotal = action.payload.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        state.subcategoryShow = state.subcategorytotal.filter((data) => data.status === true);
        localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
        state.isLoading = false;
        state.subCategoryLoading = false;
      })
      .addCase(getSubCategory.rejected, (state) => {
        state.isLoading = true;
      });
    builder
      .addCase(subCategoryPost.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory]
          state.subcategoryShow = state.subcategorytotal.filter((data) => data.status === true).sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
        }
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryPost.rejected, (state) => {
        state.subCategoryLoading = true;
      });
    builder
      .addCase(subCategoryUpdate.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.subcategory._id);
          state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory].sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          state.subcategoryShow = state.subcategorytotal.filter((data) => data.status === true);
          localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
        }
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryUpdate.rejected, (state) => {
        state.subCategoryLoading = true;
      });
    builder
      .addCase(subCategoryDelete.pending, (state) => {
        state.deleteSubCatLoading = true;
      })
      .addCase(subCategoryDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.id).sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          state.subcategoryShow = state.subcategorytotal.filter((data) => data.status === true);
          localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
        }
        state.deleteSubCatLoading = false;
      })
      .addCase(subCategoryDelete.rejected, (state) => {
        state.deleteSubCatLoading = true;
      });
    builder
      .addCase(subCategoryImages.pending, (state) => {
        state.isSubDeskImageLoading = true;
      })
      .addCase(subCategoryImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
          state.mobileimage = action.payload.mobileimages;

        }
        state.isSubDeskImageLoading = false;
        state.isSubCatmobileImageLoading = false;
      })
      .addCase(subCategoryImages.rejected, (state) => {
        state.isSubDeskImageLoading = true;
      });

  },

});
export const { updateSubcatMobileImage,
  updateSubcatDeskImage,
  deleteSubcategory, removeSubcatImage } = SubCategorySlice.actions;
export default SubCategorySlice.reducer;