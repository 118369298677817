import React, { useState, useEffect } from "react";
import { Button, Flex, Form, Select, Col, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../redux/product/productSlice";
const { Option } = Select;

const ClothingSuperCategory = ({ buttonPress, getPropDetails, resetield }) => {
  const dispatch = useDispatch();
  const { product_basic_details } = useSelector((store) => store.product);
  const {
    romanTypeMessurements,
    sleevestype,
    top_shapes,
    top_types,
    bottom_styles_with_kurti,
    Dupattaavailable,
    pritsandPatterns,
    dress_designs,
    hemline_styles,
    length_styles,
    necktype,
    occasion_styles,
    embellishment_types,
    wave_patterns,
    wave_types,
    pattern_coverage,
    sleeve_styles,
    no_of_item,
    saree_types,
    saree_border_styles,
    fabric_types,
    Blouseavailable,
    fabric_care_instructions,
    fastening_types,
    transparency_levels,
    main_trends,
  } = useSelector((store) => store.Messurents);

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);

  const [sleevLength, setsleevLength] = useState("");
  const [sleevLengtherror, setsleevLengtherror] = useState("");
  const [topShape, settopShape] = useState("");
  const [topShapeerror, settopShapeerror] = useState("");
  const [type, settype] = useState("");
  const [typeerror, settypeerror] = useState("");
  const [bottomtype, setbottomtype] = useState("");
  const [Bottomtypeerror, setBottomtypeerror] = useState("");
  const [dupatta, setdupatta] = useState("");
  const [dupattaerror, setdupattaerror] = useState("");
  const [topPattern, settopPattern] = useState("");
  const [topPatternerror, settopPatternerror] = useState("");
  const [topDesignstyle, settopDesignstyle] = useState("");
  const [topDesignstyleerror, settopDesignstyleerror] = useState("");
  const [hemline, sethemline] = useState("");
  const [hemlineerror, sethemlineerror] = useState("");
  const [TopLength, setTopLength] = useState("");
  const [TopLengtherror, setTopLengtherror] = useState("");
  const [Neck, setNeck] = useState("");
  const [Neckerror, setNeckerror] = useState("");
  const [PrintOrPatternType, setPrintOrPatternType] = useState("");
  const [PrintOrPatternTypeerror, setPrintOrPatternTypeerror] = useState("");
  const [BottomPattern, setBottomPattern] = useState("");
  const [BottomPatternerror, setBottomPatternerror] = useState("");
  const [Waistband, setWaistband] = useState("");
  const [Waistbanderror, setWaistbanderror] = useState("");
  const [Occasion, setOccasion] = useState("");
  const [Occasionerror, setOccasionerror] = useState("");
  const [Ornamentation, setOrnamentation] = useState("");
  const [Ornamentationerror, setOrnamentationerror] = useState("");
  const [Wavepattern, setWavepattern] = useState("");
  const [Wavepatternerror, setWavepatternerror] = useState("");
  const [Wavetype, setWavetype] = useState("");
  const [Wavetypeerror, setWavetypeerror] = useState("");
  const [PatternCoverage, setPatternCoverage] = useState("");
  const [PatternCoverageerror, setPatternCoverageerror] = useState("");
  const [DupattaPattern, setDupattaPattern] = useState("");
  const [DupattaPatternerror, setDupattaPatternerror] = useState("");
  const [Sleevstyle, setSleevstyle] = useState("");
  const [Sleevstyleerror, setSleevstyleerror] = useState("");
  const [DupattaBorder, setDupattaBorder] = useState("");
  const [DupattaBordererror, setDupattaBordererror] = useState("");
  const [NumberOfItems, setNumberOfItems] = useState("");
  const [NumberOfItemserror, setNumberOfItemserror] = useState("");
  const [PackageContainer, setPackageContainer] = useState("");
  const [PackageContainererror, setPackageContainererror] = useState("");

  const [transpirancy, settranspirancy] = useState("");
  const [transpirancyerror, settranspirancyerror] = useState("");
  const [closur, setclosur] = useState("");
  const [closurerror, setclosurerror] = useState("");
  const [MainTrend, setMainTrend] = useState("");
  const [MainTrenderror, setMainTrenderror] = useState("");

  const [sizeFit, setsizeFit] = useState("");
  const [sizeFiterror, setsizeFiterror] = useState("");
  const [DesignDetails, setDesignDetails] = useState("");
  const [DesignDetailserror, setDesignDetailserror] = useState("");
  const [Border, setBorder] = useState("");
  const [Bordererror, setBordererror] = useState("");
  const [BlouseFabric, setBlouseFabric] = useState("");
  const [BlouseFabricerror, setBlouseFabricerror] = useState("");
  const [Blouse, setBlouse] = useState("");
  const [Blouseerror, setBlouseerror] = useState("");
  const [Fabric, setFabric] = useState("");
  const [Fabricerror, setFabricerror] = useState("");
  const [WashCare, setWashCare] = useState("");
  const [WashCareerror, setWashCareerror] = useState("");

  //start
  const [multipackset, setmultipackset] = useState("");
  const [multipackseterror, setmultipackseterror] = useState("");
  const [sustainable, setsustainable] = useState("");
  const [sustainableerror, setsustainableerror] = useState("");
  const [Collar, setCollar] = useState("");
  const [Collarerror, setCollarerror] = useState("");
  const [NumberofPockets, setNumberofPockets] = useState("");
  const [NumberofPocketserror, setNumberofPocketserror] = useState("");
  const [SlitDetail, setSlitDetail] = useState("");
  const [SlitDetailerror, setSlitDetailerror] = useState("");
  const [distress, setdistress] = useState("");
  const [distresserror, setdistresserror] = useState("");
  const [waistRise, setwaistRise] = useState("");
  const [waistRiseerror, setwaistRiseerror] = useState("");
  const [fade, setfade] = useState("");
  const [fadeerror, setfadeerror] = useState("");
  const [shade, setshade] = useState("");
  const [shadeerror, setshadeerror] = useState("");
  const [strech, setstrech] = useState("");
  const [strecherror, setstrecherror] = useState("");
  const [reversible, setreversible] = useState("");
  const [reversibleerror, setreversibleerror] = useState("");
  const [waistband, setwaistband] = useState("");
  const [waistbanderror, setwaistbanderror] = useState("");
  const [BottomFabric, setBottomFabric] = useState("");
  const [BottomFabricerror, setBottomFabricerror] = useState("");
  const [BottomClosure, setBottomClosure] = useState("");
  const [BottomClosureerror, setBottomClosureerror] = useState("");
  const [topStitch, settopStitch] = useState("");
  const [topStitcherror, settopStitcherror] = useState("");
  const [bottomStitch, setbottomStitch] = useState("");
  const [bottomStitcherror, setbottomStitcherror] = useState("");
  const [BrandFitName, setBrandFitName] = useState("");
  const [BrandFitNameerror, setBrandFitNameerror] = useState("");
  const [Placket, setPlacket] = useState("");
  const [Placketerror, setPlacketerror] = useState("");
  const [PlacketLength, setPlacketLength] = useState("");
  const [PlacketLengtherror, setPlacketLengtherror] = useState("");
  const [Placket_type, setPlacket_type] = useState("");
  const [Placket_typeerror, setPlacket_typeerror] = useState("");
  const [cuff, setcuff] = useState("");
  const [cufferror, setcufferror] = useState("");
  const [surfaces_styling, setsurfaces_styling] = useState("");
  const [surfaces_stylingerror, setsurfaces_stylingerror] = useState("");
  const [features, setfeatures] = useState("");
  const [featureserror, setfeatureserror] = useState("");
  const [border_Fabric, setborder_Fabric] = useState("");
  const [border_Fabricerror, setborder_Fabricerror] = useState("");
  const [tyepe_of_distress, settyepe_of_distress] = useState("");
  const [tyepe_of_distresserror, settyepe_of_distresserror] = useState("");
  const [fly_Type, setfly_Type] = useState("");
  const [fly_Typeerror, setfly_Typeerror] = useState("");
  const [assorted, setassorted] = useState("");
  const [assortederror, setassortederror] = useState("");
  const [knighr_or_woven, setknighr_or_woven] = useState("");
  const [knighr_or_wovenerror, setknighr_or_wovenerror] = useState("");
  const [bottom_length, setbottom_length] = useState("");
  const [bottom_lengtherror, setbottom_lengtherror] = useState("");
  const [bottom_border_fabric, setbottom_border_fabric] = useState("");
  const [bottom_border_fabricerror, setbottom_border_fabricerror] =
    useState("");
  const [type_of_pleat, settype_of_pleat] = useState("");
  const [type_of_pleaterror, settype_of_pleaterror] = useState("");
  const [front_Style, setfront_Style] = useState("");
  const [front_Styleerror, setfront_Styleerror] = useState("");
  const [padding, setpadding] = useState("");
  const [paddingerror, setpaddingerror] = useState("");
  const [seam, setseam] = useState("");
  const [seamerror, setseamerror] = useState("");
  const [colourfamily, setcolourfamily] = useState("");
  const [colourfamilyerror, setcolourfamilyerror] = useState("");
  const [hexa_Code, sethexa_Code] = useState("");
  const [hexa_Codeerror, sethexa_Codeerror] = useState("");
  const [finish, setfinish] = useState("");
  const [finisherror, setfinisherror] = useState("");
  const [formulation, setformulation] = useState("");
  const [formulationerror, setformulationerror] = useState("");
  const [preferance, setpreferance] = useState("");
  const [preferanceerror, setpreferanceerror] = useState("");
  const [concern, setconcern] = useState("");
  const [concernerror, setconcernerror] = useState("");
  const [key_ingredient, setkey_ingredient] = useState("");
  const [key_ingredienterror, setkey_ingredienterror] = useState("");
  const [skin_type, setskin_type] = useState("");
  const [skin_typeerror, setskin_typeerror] = useState("");
  const [stone_Type, setstone_Type] = useState("");
  const [stone_Typeerror, setstone_Typeerror] = useState("");
  const [puriety, setpuriety] = useState("");
  const [purietyerror, setpurietyerror] = useState("");

  const [topDesign, settopDesign] = useState("");
  const [topDesignerror, settopDesignerror] = useState("");
  const [bottomDesign, setbottomDesign] = useState("");
  const [bottomDesignerror, setbottomDesignerror] = useState("");
  const [blouseBorder, setblouseBorder] = useState("");
  const [blouseBordererror, setblouseBordererror] = useState("");
  const [dupattaFabric, setdupattaFabric] = useState("");
  const [dupattaFabricerror, setdupattaFabricerror] = useState("");

  useEffect(() => {
    if (product_basic_details !== "") {
      const getdata = async () => {
        const formData = {
          superCategoryId: product_basic_details.superCategoryId,
          categoryId: product_basic_details.categoryId,
          SubCategoryId: product_basic_details.SubCategoryId,
          CategoryTagId: product_basic_details.CategoryTagId,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          if (filterShow.length === 0) {
            const singleObject = {};
            getPropDetails(singleObject, true);
          }
          if (filterShow.length === 0) {
            const singleObject = {};
            getPropDetails(singleObject, true);
          }
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        }else {
          const singleObject = {};
          getPropDetails(singleObject, true);
        }
      };
      getdata();
    }
  }, [product_basic_details]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (sleevLength === "") {
        setsleevLengtherror("Required");
      } else {
        setsleevLengtherror("");
      }
      if (topShape === "") {
        settopShapeerror("Required");
      } else {
        settopShapeerror("");
      }
      if (type === "") {
        settypeerror("Required");
      } else {
        settypeerror("");
      }
      if (bottomtype === "") {
        setBottomtypeerror("Required");
      } else {
        setBottomtypeerror("");
      }
      if (dupatta === "") {
        setdupattaerror("Required");
      } else {
        setdupattaerror("");
      }
      if (topPattern === "") {
        settopPatternerror("Required");
      } else {
        settopPatternerror("");
      }
      if (topDesignstyle === "") {
        settopDesignstyleerror("Required");
      } else {
        settopDesignstyleerror("");
      }
      if (hemline === "") {
        sethemlineerror("Required");
      } else {
        sethemlineerror("");
      }
      if (TopLength === "") {
        setTopLengtherror("Required");
      } else {
        setTopLengtherror("");
      }
      if (Neck === "") {
        setNeckerror("Required");
      } else {
        setNeckerror("");
      }
      if (PrintOrPatternType === "") {
        setPrintOrPatternTypeerror("Required");
      } else {
        setPrintOrPatternTypeerror("");
      }
      if (BottomPattern === "") {
        setBottomPatternerror("Required");
      } else {
        setBottomPatternerror("");
      }
      if (Waistband === "") {
        setWaistbanderror("Required");
      } else {
        setWaistbanderror("");
      }
      if (Occasion === "") {
        setOccasionerror("Required");
      } else {
        setOccasionerror("");
      }
      if (Ornamentation === "") {
        setOrnamentationerror("Required");
      } else {
        setOrnamentationerror("");
      }
      if (Wavepattern === "") {
        setWavepatternerror("Required");
      } else {
        setWavepatternerror("");
      }
      if (Wavetype === "") {
        setWavetypeerror("Required");
      } else {
        setWavetypeerror("");
      }
      if (PatternCoverage === "") {
        setPatternCoverageerror("Required");
      } else {
        setPatternCoverageerror("");
      }
      if (DupattaPattern === "") {
        setDupattaPatternerror("Required");
      } else {
        setDupattaPatternerror("");
      }
      if (Sleevstyle === "") {
        setSleevstyleerror("Required");
      } else {
        setSleevstyleerror("");
      }
      if (DupattaBorder === "") {
        setDupattaBordererror("Required");
      } else {
        setDupattaBordererror("");
      }
      if (NumberOfItems === "") {
        setNumberOfItemserror("Required");
      } else {
        setNumberOfItemserror("");
      }
      if (PackageContainer === "") {
        setPackageContainererror("Required");
      } else {
        setPackageContainererror("");
      }

      if (sizeFit === "") {
        setsizeFiterror("Required");
      } else {
        setsizeFiterror("");
      }

      if (Border === "") {
        setBordererror("Required");
      } else {
        setBordererror("");
      }
      if (BlouseFabric === "") {
        setBlouseFabricerror("Required");
      } else {
        setBlouseFabricerror("");
      }
      if (Blouse === "") {
        setBlouseerror("Required");
      } else {
        setBlouseerror("");
      }
      if (Fabric === "") {
        setFabricerror("Required");
      } else {
        setFabricerror("");
      }
      if (WashCare === "") {
        setWashCareerror("Required");
      } else {
        setWashCareerror("");
      }
      if (DesignDetails === "") {
        setDesignDetailserror("Required");
      } else {
        setDesignDetailserror("");
      }

      if (closur === "") {
        setclosurerror("Required");
      } else {
        setclosurerror("");
      }
      if (MainTrend === "") {
        setMainTrenderror("Required");
      } else {
        setMainTrenderror("");
      }

      if (transpirancy === "") {
        settranspirancyerror("Required");
      } else {
        settranspirancyerror("");
      }
      if (multipackset === "") {
        setmultipackseterror("Required");
      } else {
        setmultipackseterror("");
      }
      if (sustainable === "") {
        setsustainableerror("Required");
      } else {
        setsustainableerror("");
      }
      if (Collar === "") {
        setCollarerror("Required");
      } else {
        setCollarerror("");
      }
      if (NumberofPockets === "") {
        setNumberofPocketserror("Required");
      } else {
        setNumberofPocketserror("");
      }
      if (SlitDetail === "") {
        setSlitDetailerror("Required");
      } else {
        setSlitDetailerror("");
      }
      if (distress === "") {
        setdistresserror("Required");
      } else {
        setdistresserror("");
      }
      if (waistRise === "") {
        setwaistRiseerror("Required");
      } else {
        setwaistRiseerror("");
      }
      if (waistRise === "") {
        setwaistRiseerror("Required");
      } else {
        setwaistRiseerror("");
      }
      if (fade === "") {
        setfadeerror("Required");
      } else {
        setfadeerror("");
      }
      if (shade === "") {
        setshadeerror("Required");
      } else {
        setshadeerror("");
      }
      if (strech === "") {
        setstrecherror("Required");
      } else {
        setstrecherror("");
      }
      if (reversible === "") {
        setreversibleerror("Required");
      } else {
        setreversibleerror("");
      }
      if (waistband === "") {
        setwaistbanderror("Required");
      } else {
        setwaistbanderror("");
      }
      if (BottomFabric === "") {
        setBottomFabricerror("Required");
      } else {
        setBottomFabricerror("");
      }

      if (BottomClosure === "") {
        setBottomClosureerror("Required");
      } else {
        setBottomClosureerror("");
      }
      if (topStitch === "") {
        settopStitcherror("Required");
      } else {
        settopStitcherror("");
      }
      if (waistband === "") {
        setwaistbanderror("Required");
      } else {
        setwaistbanderror("");
      }
      if (bottomStitch === "") {
        setbottomStitcherror("Required");
      } else {
        setbottomStitcherror("");
      }

      if (BrandFitName === "") {
        setBrandFitNameerror("Required");
      } else {
        setBrandFitNameerror("");
      }
      if (Placket === "") {
        setPlacketerror("Required");
      } else {
        setPlacketerror("");
      }
      if (PlacketLength === "") {
        setPlacketLengtherror("Required");
      } else {
        setPlacketLengtherror("");
      }
      if (Placket_type === "") {
        setPlacket_typeerror("Required");
      } else {
        setPlacket_typeerror("");
      }

      if (cuff === "") {
        setcufferror("Required");
      } else {
        setcufferror("");
      }
      if (surfaces_styling === "") {
        setsurfaces_stylingerror("Required");
      } else {
        setsurfaces_stylingerror("");
      }
      if (features === "") {
        setfeatureserror("Required");
      } else {
        setfeatureserror("");
      }
      if (border_Fabric === "") {
        setborder_Fabricerror("Required");
      } else {
        setborder_Fabricerror("");
      }

      if (tyepe_of_distress === "") {
        settyepe_of_distresserror("Required");
      } else {
        settyepe_of_distresserror("");
      }
      if (fly_Type === "") {
        setfly_Typeerror("Required");
      } else {
        setfly_Typeerror("");
      }
      if (assorted === "") {
        setassortederror("Required");
      } else {
        setassortederror("");
      }
      if (knighr_or_woven === "") {
        setknighr_or_wovenerror("Required");
      } else {
        setknighr_or_wovenerror("");
      }

      if (bottom_length === "") {
        setbottom_lengtherror("Required");
      } else {
        setbottom_lengtherror("");
      }
      if (bottom_border_fabric === "") {
        setbottom_border_fabricerror("Required");
      } else {
        setbottom_border_fabricerror("");
      }
      if (type_of_pleat === "") {
        settype_of_pleaterror("Required");
      } else {
        settype_of_pleaterror("");
      }
      if (front_Style === "") {
        setfront_Styleerror("Required");
      } else {
        setfront_Styleerror("");
      }
      if (padding === "") {
        setpaddingerror("Required");
      } else {
        setpaddingerror("");
      }
      if (Placket === "") {
        setPlacketerror("Required");
      } else {
        setPlacketerror("");
      }
      if (seam === "") {
        setseamerror("Required");
      } else {
        setseamerror("");
      }
      if (colourfamily === "") {
        setcolourfamilyerror("Required");
      } else {
        setcolourfamilyerror("");
      }
      if (hexa_Code === "") {
        sethexa_Codeerror("Required");
      } else {
        sethexa_Codeerror("");
      }
      if (finish === "") {
        setfinisherror("Required");
      } else {
        setfinisherror("");
      }
      if (formulation === "") {
        setformulationerror("Required");
      } else {
        setformulationerror("");
      }
      if (preferance === "") {
        setpreferanceerror("Required");
      } else {
        setpreferanceerror("");
      }

      if (concern === "") {
        setconcernerror("Required");
      } else {
        setconcernerror("");
      }
      if (key_ingredient === "") {
        setkey_ingredienterror("Required");
      } else {
        setkey_ingredienterror("");
      }
      if (skin_type === "") {
        setskin_typeerror("Required");
      } else {
        setskin_typeerror("");
      }
      if (stone_Type === "") {
        setstone_Typeerror("Required");
      } else {
        setstone_Typeerror("");
      }
      if (puriety === "") {
        setpurietyerror("Required");
      } else {
        setpurietyerror("");
      }
      if (topDesign === "") {
        settopDesignerror("Required");
      } else {
        settopDesignerror("");
      }
      if (bottomDesign === "") {
        setbottomDesignerror("Required");
      } else {
        setbottomDesignerror("");
      }
      if (blouseBorder === "") {
        setblouseBordererror("Required");
      } else {
        setblouseBordererror("");
      }
      if (dupattaFabric === "") {
        setdupattaFabricerror("Required");
      } else {
        setdupattaFabricerror("");
      }
      if (distinctfieldValidate.every((field) => field.value !== "")) {
        let finalarray = [];
        for (let index = 0; index < distinctfieldValidate.length; index++) {
          let element = distinctfieldValidate[index];
          let obj = { [element.field]: element.value };
          finalarray = [...finalarray, obj];
        }
        finalarray = finalarray;

        const singleObject = finalarray.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});
        getPropDetails(singleObject, true);
      } else {
        console.log("fieldas are empty");
      }
    }
  }, [
    buttonPress,
    distinctfieldValidate,
    topDesign,
    bottomDesign,
    blouseBorder,
    dupattaFabric,
    sleevLength,
    topShape,
    type,
    bottomtype,
    dupatta,
    topPattern,
    topDesignstyle,
    hemline,
    TopLength,
    Neck,
    PrintOrPatternType,
    BottomPattern,
    Waistband,
    Occasion,
    Ornamentation,
    Wavepattern,
    Wavetype,
    PatternCoverage,
    DupattaPattern,
    Sleevstyle,
    DupattaBorder,
    NumberOfItems,
    PackageContainer,
    sizeFit,
    Border,
    BlouseFabric,
    Blouse,
    Fabric,
    WashCare,
    DesignDetails,
    closur,
    MainTrend,
    transpirancy,
    multipackset,
    sustainable,
    Collar,
    NumberofPockets,
    SlitDetail,
    distress,
    waistRise,
    fade,
    shade,
    strech,
    reversible,
    waistband,
    BottomFabric,
    BottomClosure,
    topStitch,
    bottomStitch,
    BrandFitName,
    Placket,
    PlacketLength,
    Placket_type,
    cuff,
    surfaces_styling,
    features,
    border_Fabric,
    tyepe_of_distress,
    fly_Type,
    assorted,
    knighr_or_woven,
    bottom_length,
    bottom_border_fabric,
    type_of_pleat,
    front_Style,
    padding,
    seam,
    colourfamily,
    hexa_Code,
    finish,
    formulation,
    preferance,
    concern,
    key_ingredient,
    skin_type,
    stone_Type,
    puriety,
  ]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Flex wrap="wrap">
          {distinctfield.includes("SleeveLength") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sleev Length"
                hasFeedback
                validateStatus={sleevLengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sleev Length"
                  value={sleevLength}
                  onChange={(value) => {
                    setsleevLength(value);
                    updateValues("SleeveLength", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sleevestype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sleevLength === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("PrintorPatternType") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Print Or PatternType"
                hasFeedback
                validateStatus={PrintOrPatternTypeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Print Or PatternType"
                  value={PrintOrPatternType}
                  onChange={(value) => {
                    setPrintOrPatternType(value);
                    updateValues("PrintorPatternType", value);
                  }}
                  style={{ width: 240 }}
                >
                  {pritsandPatterns.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={PrintOrPatternType === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Shape") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Top Shap"
                hasFeedback
                validateStatus={topShapeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Shap"
                  onChange={(value) => {
                    settopShape(value);
                    updateValues("Shape", value);
                  }}
                  value={topShape}
                  style={{ width: 240 }}
                >
                  {top_shapes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={topShape === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Top Type"
                hasFeedback
                validateStatus={typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Type"
                  onChange={(value) => {
                    settype(value);
                    updateValues("Type", value);
                  }}
                  value={type}
                  style={{ width: 240 }}
                >
                  {top_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("BottomType") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Bottom Type"
                hasFeedback
                validateStatus={Bottomtypeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Bottom Type"
                  onChange={(value) => {
                    setbottomtype(value);
                    updateValues("BottomType", value);
                  }}
                  value={bottomtype}
                  style={{ width: 240 }}
                >
                  {bottom_styles_with_kurti.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bottomtype === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Dupatta") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Dupatta"
                hasFeedback
                validateStatus={dupattaerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dupatta"
                  onChange={(value) => {
                    setdupatta(value);
                    updateValues("Dupatta", value);
                  }}
                  value={dupatta}
                  style={{ width: 240 }}
                >
                  {Dupattaavailable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dupatta === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("TopPattern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Top Pattern Type"
                hasFeedback
                validateStatus={topPatternerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Pattern Type"
                  onChange={(value) => {
                    settopPattern(value);
                    updateValues("TopPattern", value);
                  }}
                  value={topPattern}
                  style={{ width: 240 }}
                >
                  {pritsandPatterns.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={topPattern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("DesignStyling") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Top Design"
                hasFeedback
                validateStatus={topDesignstyleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Design"
                  onChange={(value) => {
                    settopDesignstyle(value);
                    updateValues("DesignStyling", value);
                  }}
                  value={topDesignstyle}
                  style={{ width: 240 }}
                >
                  {dress_designs.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={topDesignstyle === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Hemline") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hemline"
                hasFeedback
                validateStatus={hemlineerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Hemline"
                  onChange={(value) => {
                    sethemline(value);
                    updateValues("Hemline", value);
                  }}
                  value={hemline}
                  style={{ width: 240 }}
                >
                  {hemline_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hemline === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Top Length"
                hasFeedback
                validateStatus={TopLengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Length"
                  onChange={(value) => {
                    setTopLength(value);
                    updateValues("Length", value);
                  }}
                  value={TopLength}
                  style={{ width: 240 }}
                >
                  {length_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={TopLength === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Neck") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Neck"
                hasFeedback
                validateStatus={Neckerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Neck"
                  onChange={(value) => {
                    setNeck(value);
                    updateValues("Neck", value);
                  }}
                  value={Neck}
                  style={{ width: 240 }}
                >
                  {necktype.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Neck === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("BottomPattern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Bottom Pattern Type"
                hasFeedback
                validateStatus={BottomPatternerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Bottom Pattern Type"
                  onChange={(value) => {
                    setBottomPattern(value);
                    updateValues("BottomPattern", value);
                  }}
                  value={BottomPattern}
                  style={{ width: 240 }}
                >
                  {pritsandPatterns.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={BottomPattern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("waistband") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Waistband"
                hasFeedback
                validateStatus={Waistbanderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Waistband"
                  onChange={(value) => {
                    setWaistband(value);
                    updateValues("waistband", value);
                  }}
                  value={Waistband}
                  style={{ width: 240 }}
                >
                  {romanTypeMessurements.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Waistband === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Occasion") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Occasion"
                hasFeedback
                validateStatus={Occasionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Occasion"
                  onChange={(value) => {
                    setOccasion(value);
                    updateValues("Occasion", value);
                  }}
                  value={Occasion}
                  style={{ width: 240 }}
                >
                  {occasion_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Occasion === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Ornamentation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ornamentation"
                hasFeedback
                validateStatus={Ornamentationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ornamentation"
                  onChange={(value) => {
                    setOrnamentation(value);
                    updateValues("Ornamentation", value);
                  }}
                  value={Ornamentation}
                  style={{ width: 240 }}
                >
                  {embellishment_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Ornamentation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("WeavePattern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wave Pattern"
                hasFeedback
                validateStatus={Wavepatternerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wave Pattern"
                  onChange={(value) => {
                    setWavepattern(value);
                    updateValues("WeavePattern", value);
                  }}
                  value={Wavepattern}
                  style={{ width: 240 }}
                >
                  {wave_patterns.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Wavepattern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("WeaveType") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wave type"
                hasFeedback
                validateStatus={Wavetypeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wave type"
                  onChange={(value) => {
                    setWavetype(value);
                    updateValues("WeaveType", value);
                  }}
                  value={Wavetype}
                  style={{ width: 240 }}
                >
                  {wave_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Wavetype === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("patternCoverage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pattern Coverage"
                hasFeedback
                validateStatus={PatternCoverageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Pattern Coverage"
                  onChange={(value) => {
                    setPatternCoverage(value);
                    updateValues("patternCoverage", value);
                  }}
                  value={PatternCoverage}
                  style={{ width: 240 }}
                >
                  {pattern_coverage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={PatternCoverage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("DupattaPattern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dupatta Pattern"
                hasFeedback
                validateStatus={DupattaPatternerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dupatta Pattern"
                  onChange={(value) => {
                    setDupattaPattern(value);
                    updateValues("DupattaPattern", value);
                  }}
                  value={DupattaPattern}
                  style={{ width: 240 }}
                >
                  {pritsandPatterns.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={DupattaPattern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("SleeveStyling") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sleeve Style"
                hasFeedback
                validateStatus={Sleevstyleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sleeve Style"
                  onChange={(value) => {
                    setSleevstyle(value);
                    updateValues("SleeveStyling", value);
                  }}
                  value={Sleevstyle}
                  style={{ width: 240 }}
                >
                  {sleeve_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Sleevstyle === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("DupattaBorder") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dupatta Border"
                hasFeedback
                validateStatus={DupattaBordererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dupatta Border"
                  onChange={(value) => {
                    setDupattaBorder(value);
                    updateValues("DupattaBorder", value);
                  }}
                  value={DupattaBorder}
                  style={{ width: 240 }}
                >
                  {saree_border_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={DupattaBorder === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("NumberofItems") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number Of Items"
                hasFeedback
                validateStatus={NumberOfItemserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number Of Items"
                  onChange={(value) => {
                    setNumberOfItems(value);
                    updateValues("NumberofItems", value);
                  }}
                  value={NumberOfItems}
                  style={{ width: 240 }}
                >
                  {no_of_item.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={NumberOfItems === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Border") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Saree Border"
                hasFeedback
                validateStatus={Bordererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Saree Border"
                  onChange={(value) => {
                    setBorder(value);
                    updateValues("Border", value);
                  }}
                  value={Border}
                  style={{ width: 240 }}
                >
                  {saree_border_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Border === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("BlouseFabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blouse Fabric"
                hasFeedback
                validateStatus={BlouseFabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Blouse Fabric"
                  onChange={(value) => {
                    setBlouseFabric(value);
                    updateValues("BlouseFabric", value);
                  }}
                  value={BlouseFabric}
                  style={{ width: 240 }}
                >
                  {fabric_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={BlouseFabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Blouse") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blouse Availability"
                hasFeedback
                validateStatus={Blouseerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Blouse Availability"
                  onChange={(value) => {
                    setBlouse(value);
                    updateValues("Blouse", value);
                  }}
                  value={Blouse}
                  style={{ width: 240 }}
                >
                  {Blouseavailable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Blouse === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Fabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Saree Fabric"
                hasFeedback
                validateStatus={Fabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Saree Fabric"
                  onChange={(value) => {
                    setFabric(value);
                    updateValues("Fabric", value);
                  }}
                  value={Fabric}
                  style={{ width: 240 }}
                >
                  {fabric_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Fabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("washcare") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wash Care"
                hasFeedback
                validateStatus={WashCareerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wash Care"
                  onChange={(value) => {
                    setWashCare(value);
                    updateValues("washcare", value);
                  }}
                  value={WashCare}
                  style={{ width: 240 }}
                >
                  {fabric_care_instructions.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={WashCare === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Transparency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Transpirancy"
                hasFeedback
                validateStatus={transpirancyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Transpirancy"
                  onChange={(value) => {
                    settranspirancy(value);
                    updateValues("Transparency", value);
                  }}
                  value={transpirancy}
                  style={{ width: 240 }}
                >
                  {transparency_levels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={transpirancy === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("topClosure") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Closur"
                hasFeedback
                validateStatus={closurerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Closur"
                  onChange={(value) => {
                    setclosur(value);
                    updateValues("topClosure", value);
                  }}
                  value={closur}
                  style={{ width: 240 }}
                >
                  {fastening_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={closur === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("MainTrend") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Main Trend"
                hasFeedback
                validateStatus={MainTrenderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Main Trend"
                  onChange={(value) => {
                    setMainTrend(value);
                    updateValues("MainTrend", value);
                  }}
                  value={MainTrend}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={MainTrend === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("multipackset") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="MultiPack"
                hasFeedback
                validateStatus={multipackseterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select MultiPack"
                  onChange={(value) => {
                    setmultipackset(value);
                    updateValues("multipackset", value);
                  }}
                  value={multipackset}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={multipackset === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sustainable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sustainable"
                hasFeedback
                validateStatus={sustainableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sustainable"
                  onChange={(value) => {
                    setsustainable(value);
                    updateValues("sustainable", value);
                  }}
                  value={sustainable}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sustainable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Collar") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Collar"
                hasFeedback
                validateStatus={Collarerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Collar"
                  onChange={(value) => {
                    setCollar(value);
                    updateValues("Collar", value);
                  }}
                  value={Collar}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Collar === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("NumberofPockets") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="NumberofPockets"
                hasFeedback
                validateStatus={NumberofPocketserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select NumberofPockets"
                  onChange={(value) => {
                    setNumberofPockets(value);
                    updateValues("NumberofPockets", value);
                  }}
                  value={NumberofPockets}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={NumberofPockets === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("SlitDetail") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="SlitDetail"
                hasFeedback
                validateStatus={SlitDetailerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select SlitDetail"
                  onChange={(value) => {
                    setSlitDetail(value);
                    updateValues("SlitDetail", value);
                  }}
                  value={SlitDetail}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={SlitDetail === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("distress") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Distress"
                hasFeedback
                validateStatus={distresserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Distress"
                  onChange={(value) => {
                    setdistress(value);
                    updateValues("distress", value);
                  }}
                  value={distress}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={distress === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("waistRise") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="WaistRise"
                hasFeedback
                validateStatus={waistRiseerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select WaistRise"
                  onChange={(value) => {
                    setwaistRise(value);
                    updateValues("waistRise", value);
                  }}
                  value={waistRise}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={waistRise === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fade") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Fade"
                hasFeedback
                validateStatus={fadeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Fade"
                  onChange={(value) => {
                    setfade(value);
                    updateValues("fade", value);
                  }}
                  value={fade}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fade === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shade") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shade"
                hasFeedback
                validateStatus={shadeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Shade"
                  onChange={(value) => {
                    setshade(value);
                    updateValues("shade", value);
                  }}
                  value={shade}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shade === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("strech") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Strech"
                hasFeedback
                validateStatus={strecherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Strech"
                  onChange={(value) => {
                    setstrech(value);
                    updateValues("strech", value);
                  }}
                  value={strech}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={strech === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("reversible") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Reversible"
                hasFeedback
                validateStatus={reversibleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Reversible"
                  onChange={(value) => {
                    setreversible(value);
                    updateValues("reversible", value);
                  }}
                  value={reversible}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={reversible === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("waistband") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Waistband"
                hasFeedback
                validateStatus={waistbanderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Waistband"
                  onChange={(value) => {
                    setwaistband(value);
                    updateValues("waistband", value);
                  }}
                  value={waistband}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={waistband === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("BottomFabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="BottomFabric"
                hasFeedback
                validateStatus={BottomFabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select BottomFabric"
                  onChange={(value) => {
                    setBottomFabric(value);
                    updateValues("BottomFabric", value);
                  }}
                  value={BottomFabric}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={BottomFabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("BottomClosure") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="BottomClosure"
                hasFeedback
                validateStatus={BottomClosureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select BottomClosure"
                  onChange={(value) => {
                    setBottomClosure(value);
                    updateValues("BottomClosure", value);
                  }}
                  value={BottomClosure}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={BottomClosure === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("topStitch") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="TopStitch"
                hasFeedback
                validateStatus={topStitcherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select TopStitch"
                  onChange={(value) => {
                    settopStitch(value);
                    updateValues("topStitch", value);
                  }}
                  value={topStitch}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={topStitch === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("bottomStitch") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="bottomStitch"
                hasFeedback
                validateStatus={bottomStitcherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select bottomStitch"
                  onChange={(value) => {
                    setbottomStitch(value);
                    updateValues("bottomStitch", value);
                  }}
                  value={bottomStitch}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bottomStitch === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("BrandFitName") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="BrandFitName"
                hasFeedback
                validateStatus={BrandFitNameerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select BrandFitName"
                  onChange={(value) => {
                    setBrandFitName(value);
                    updateValues("BrandFitName", value);
                  }}
                  value={BrandFitName}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={BrandFitName === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Placket") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Placket"
                hasFeedback
                validateStatus={Placketerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Placket"
                  onChange={(value) => {
                    setPlacket(value);
                    updateValues("Placket", value);
                  }}
                  value={Placket}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Placket === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("PlacketLength") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="PlacketLength"
                hasFeedback
                validateStatus={PlacketLengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select PlacketLength"
                  onChange={(value) => {
                    setPlacketLength(value);
                    updateValues("PlacketLength", value);
                  }}
                  value={PlacketLength}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={PlacketLength === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("Placket_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Placket type"
                hasFeedback
                validateStatus={Placket_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Placket type"
                  onChange={(value) => {
                    setPlacket_type(value);
                    updateValues("Placket_type", value);
                  }}
                  value={Placket_type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={Placket_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cuff") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cuff"
                hasFeedback
                validateStatus={cufferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cuff"
                  onChange={(value) => {
                    setcuff(value);
                    updateValues("cuff", value);
                  }}
                  value={cuff}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cuff === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("surfaces_styling") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="surfaces Styling"
                hasFeedback
                validateStatus={surfaces_stylingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select surfaces Styling"
                  onChange={(value) => {
                    setsurfaces_styling(value);
                    updateValues("surfaces_styling", value);
                  }}
                  value={surfaces_styling}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={surfaces_styling === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Features"
                hasFeedback
                validateStatus={featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Features"
                  onChange={(value) => {
                    setfeatures(value);
                    updateValues("features", value);
                  }}
                  value={features}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("border_Fabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Border Fabric"
                hasFeedback
                validateStatus={border_Fabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Border Fabric"
                  onChange={(value) => {
                    setborder_Fabric(value);
                    updateValues("border_Fabric", value);
                  }}
                  value={border_Fabric}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={border_Fabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tyepe_of_distress") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of distress"
                hasFeedback
                validateStatus={tyepe_of_distresserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Type of distress"
                  onChange={(value) => {
                    settyepe_of_distress(value);
                    updateValues("tyepe_of_distress", value);
                  }}
                  value={tyepe_of_distress}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tyepe_of_distress === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fly_Type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Fly Type"
                hasFeedback
                validateStatus={fly_Typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Fly Type"
                  onChange={(value) => {
                    setfly_Type(value);
                    updateValues("fly_Type", value);
                  }}
                  value={fly_Type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fly_Type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("assorted") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Assorted"
                hasFeedback
                validateStatus={assortederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Assorted"
                  onChange={(value) => {
                    setassorted(value);
                    updateValues("assorted", value);
                  }}
                  value={assorted}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={assorted === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("knighr_or_woven") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="knight or Woven"
                hasFeedback
                validateStatus={knighr_or_wovenerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select knight or woven"
                  onChange={(value) => {
                    setknighr_or_woven(value);
                    updateValues("knighr_or_woven", value);
                  }}
                  value={knighr_or_woven}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={knighr_or_woven === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("bottom_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Bottom length"
                hasFeedback
                validateStatus={bottom_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Bottom length"
                  onChange={(value) => {
                    setbottom_length(value);
                    updateValues("bottom_length", value);
                  }}
                  value={bottom_length}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bottom_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("bottom_border_fabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Bottom border fabric"
                hasFeedback
                validateStatus={bottom_border_fabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Bottom border fabric"
                  onChange={(value) => {
                    setbottom_border_fabric(value);
                    updateValues("bottom_border_fabric", value);
                  }}
                  value={bottom_border_fabric}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bottom_border_fabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_pleat") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="type of pleat"
                hasFeedback
                validateStatus={type_of_pleaterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select type of pleat"
                  onChange={(value) => {
                    settype_of_pleat(value);
                    updateValues("type_of_pleat", value);
                  }}
                  value={type_of_pleat}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_pleat === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("front_Style") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Front Style"
                hasFeedback
                validateStatus={front_Styleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Front Style"
                  onChange={(value) => {
                    setfront_Style(value);
                    updateValues("front_Style", value);
                  }}
                  value={front_Style}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={front_Style === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("padding") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Padding"
                hasFeedback
                validateStatus={paddingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Padding"
                  onChange={(value) => {
                    setpadding(value);
                    updateValues("padding", value);
                  }}
                  value={padding}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={padding === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("seam") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Seam"
                hasFeedback
                validateStatus={seamerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Seam"
                  onChange={(value) => {
                    setseam(value);
                    updateValues("seam", value);
                  }}
                  value={seam}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={seam === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("colourfamily") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Colourfamily"
                hasFeedback
                validateStatus={colourfamilyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Colourfamily"
                  onChange={(value) => {
                    setcolourfamily(value);
                    updateValues("colourfamily", value);
                  }}
                  value={colourfamily}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={colourfamily === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hexa_Code") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hexa Code"
                hasFeedback
                validateStatus={hexa_Codeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Hexa Code"
                  onChange={(value) => {
                    sethexa_Code(value);
                    updateValues("hexa_Code", value);
                  }}
                  value={hexa_Code}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hexa_Code === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("finish") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Finish"
                hasFeedback
                validateStatus={finisherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Finish"
                  onChange={(value) => {
                    setfinish(value);
                    updateValues("finish", value);
                  }}
                  value={finish}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={finish === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("formulation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Formulation"
                hasFeedback
                validateStatus={formulationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Formulation"
                  onChange={(value) => {
                    setformulation(value);
                    updateValues("formulation", value);
                  }}
                  value={formulation}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={formulation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("preferance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Preferance"
                hasFeedback
                validateStatus={preferanceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Preferance"
                  onChange={(value) => {
                    setpreferance(value);
                    updateValues("preferance", value);
                  }}
                  value={preferance}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={preferance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("concern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Concern"
                hasFeedback
                validateStatus={concernerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Concern"
                  onChange={(value) => {
                    setconcern(value);
                    updateValues("concern", value);
                  }}
                  value={concern}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={concern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("key_ingredient") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="key ingredient"
                hasFeedback
                validateStatus={key_ingredienterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select key ingredient"
                  onChange={(value) => {
                    setkey_ingredient(value);
                    updateValues("key_ingredient", value);
                  }}
                  value={key_ingredient}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={key_ingredient === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("skin_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Skin type"
                hasFeedback
                validateStatus={skin_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Skin type"
                  onChange={(value) => {
                    setskin_type(value);
                    updateValues("skin_type", value);
                  }}
                  value={skin_type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={skin_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stone_Type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stone Type"
                hasFeedback
                validateStatus={stone_Typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Stone Type"
                  onChange={(value) => {
                    setstone_Type(value);
                    updateValues("stone_Type", value);
                  }}
                  value={stone_Type}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stone_Type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("puriety") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Puriety"
                hasFeedback
                validateStatus={purietyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Puriety"
                  onChange={(value) => {
                    setpuriety(value);
                    updateValues("puriety", value);
                  }}
                  value={puriety}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={puriety === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dupattaFabric") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dupatta Fabric"
                hasFeedback
                validateStatus={dupattaFabricerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dupatta Fabric"
                  onChange={(value) => {
                    setdupattaFabric(value);
                    updateValues("dupattaFabric", value);
                  }}
                  value={dupattaFabric}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dupattaFabric === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blouseBorder") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blouse Borderc"
                hasFeedback
                validateStatus={blouseBordererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Blouse Borderc"
                  onChange={(value) => {
                    setblouseBorder(value);
                    updateValues("blouseBorder", value);
                  }}
                  value={blouseBorder}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blouseBorder === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("topDesign") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Top Design"
                hasFeedback
                validateStatus={topDesignerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Design"
                  onChange={(value) => {
                    settopDesign(value);
                    updateValues("topDesign", value);
                  }}
                  value={topDesign}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={topDesign === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("bottomDesign") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Top Design"
                hasFeedback
                validateStatus={bottomDesignerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Top Design"
                  onChange={(value) => {
                    setbottomDesign(value);
                    updateValues("bottomDesign", value);
                  }}
                  value={bottomDesign}
                  style={{ width: 240 }}
                >
                  {main_trends.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bottomDesign === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
      </Row>
    </>
  );
};

export default ClothingSuperCategory;
