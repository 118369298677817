import React from "react";
import { useSelector, useDispatch } from "react-redux";

export const Dashobrd_Home = () => {
  const { producttotal } = useSelector((store) => store.product);
  const {
    booked_orders,
    packed_orders,
    dispached_orders,
    out_for_delivery_orders,
    deliveried_orders,
    cancelled_orders,
    total_seller_earn,
    ews_commission,
  } = useSelector((store) => store.order);
  return (
    <>
      <div className="sellerfullfilledorder">
        <div className="innerseller">
          <div className="fulfilled_orders">
            <div>
              <h4>Seller Earnings</h4>
            </div>
            <div className="mt-2">
              <p>Last Updated at 11:27 PM </p>
            </div>
          </div>
          <div className="Orders">
            <div>
              <p className="innerorder">{total_seller_earn}</p>
              <p>Seller Earnings</p>
            </div>
            <div>
              <p className="innerorder">{ews_commission}</p>
              <p>EWS Commission</p>
            </div>
          </div>
        </div>
        <div className="innerseller">
          <div className="fulfilled_orders">
            <div>
              <h4>Seller Orders</h4>
            </div>
            <div className="mt-2">
              <p>Last Updated at 11:27 PM </p>
            </div>
          </div>
          <div className="Orders">
            <div>
              <p className="innerorder">{booked_orders.length}</p>
              <p>New Orders</p>
            </div>
            <div>
              <p className="innerorder">
                {booked_orders.length +
                  packed_orders.length +
                  dispached_orders.length +
                  out_for_delivery_orders.length}
              </p>
              <p>Pending Orders</p>
            </div>
            <div>
              <p className="innerorder">{deliveried_orders.length}</p>
              <p>Completed</p>
            </div>
            <div>
              {" "}
              <p className="innerorder">{cancelled_orders.length}</p>
              <p>Canceled</p>
            </div>
          </div>
        </div>

        {/* -----------Growth center----------- */}
        <div className="growth_center">
          <div className="innergrowth_center">
            <div className="innercontents" style={{ marginTop: "5px" }}>
              <div className="newgrowthcenter">
                <div className="growthnewone">
                  <div>Growth Central</div>
                  <div>
                    <button className="subnew2">New</button>
                  </div>
                </div>
                {/* <div>
                  <p className="view_personal">
                    View Personalized Growth Opportunities
                  </p>
                </div> */}
              </div>
              <div
                className="threegrowthcentral"
                style={{ paddingLeft: "3px", marginTop: "10px" }}
              >
                <div className="growthcentral_sub">
                  <div className="containerwidthgrowth">
                    <div className="buttonsubgrowth">
                      <p className="innnergrowth">
                        Insights for Products in Cart
                      </p>
                      <button className="subnew">New</button>
                    </div>
                   
                    <div>
                      <h6 className="secondinner">
                        These items are in the buyers Cart. Correct the pricing
                        to increase your sales
                      </h6>
                    </div>
                  </div>
                  <div className="numbers mt-2">
                    <p>0</p>
                    <p>Listings</p>
                  </div>
                </div>
                {/* <hr/> */}
              </div>
              <div
                className="threegrowthcentral"
                style={{ borderTop: "1px solid #ccc", paddingTop: "10px" }}
              >
                <div className="growthcentral_sub">
                  <div className="containerwidthgrowth">
                    <div className="buttonsubgrowth">
                      <p className="innnergrowth">Price Recommendations</p>
                    </div>
                    <div>
                      <h6 className="secondinner">
                        Optimise the prices of your products to improve your
                        orders by 60%
                      </h6>
                    </div>
                  </div>
                  <div className="numbers mt-2">
                    <p>0</p>
                    <p>Listings</p>
                  </div>
                </div>
                {/* <hr/> */}
              </div>
              <div
                className="threegrowthcentral"
                style={{ borderTop: "1px solid #ccc", paddingTop: "10px" }}
              >
                <div className="growthcentral_sub">
                  <div className="containerwidthgrowth">
                    <div className="buttonsubgrowth">
                      <p className="innnergrowth">
                        Suggestions to improve sale conversions
                      </p>
                    </div>
                    <div>
                      <h6 className="secondinner">
                        Optimise the prices of your products to improve your
                        orders by 60%
                      </h6>
                    </div>
                  </div>
                  <div className="numbers mt-2">
                    <p>0</p>
                    <p>Listings</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="innerseller">
              <div className="fulfilled_orders">
                <div>
                  <h4>Returns and SPF Claims</h4>
                </div>
                <div className="mt-2">
                  <p>Last updated at 11:27 PM </p>
                </div>
              </div>
              <div className="Orders1">
                <div>
                  <p className="innerorder1">0</p>
                  <p>Total Returns</p>
                </div>
                <div>
                  <p className="innerorder1">0</p>
                  <p>Completed</p>
                </div>
                <div>
                  <p className="innerorder1">0</p>
                  <p>Completed</p>
                </div>
                <div>
                  <p className="innerorder1">0</p>
                  <p>SPF Claimed</p>
                </div>
                <div>
                  {" "}
                  <p className="innerorder1">0</p>
                  <p>SettledClaim</p>
                </div>
              </div>
            </div>
            <div className="innerseller mb-4">
              <div className="fulfilled_orders">
                <div>
                  <h4>Listings</h4>
                </div>
                <div className="mt-2">
                  <p>Last updated at 11:27 PM </p>
                </div>
              </div>
              <div className="Orders1">
                <div>
                  <p className="innerorder1">{producttotal.length}</p>
                  <p>Active</p>
                </div>
                <div>
                  <p className="innerorder1">0</p>
                  <p>Ready Activ.</p>
                </div>
                <div>
                  <p className="innerorder1">0</p>
                  <p>Inactive</p>
                </div>
                <div>
                  {" "}
                  <p className="innerorder1">0</p>
                  <p>Out of Stack</p>
                </div>
                <div>
                  {" "}
                  <p className="innerorder1">0</p>
                  <p>Out of Stack</p>
                </div>
              </div>
            </div>

            {/* <div className="payments">
              <div>
                <h2 className="colorpayments paymentscontent">Payments</h2>
              </div>
              <hr />
              <div className="next_payment">
                <div className="inner_next_payment">
                  <h3 className="colorpayments">NEXT PAYMENT</h3>
                  <p>Due On Aug 23,2021</p>
                </div>
                <div>
                  <h2 className="colorpayments rupees">&#8377;28,029.03</h2>
                </div>
              </div>
              <hr />
              <div className="prepaid">
                <p>Prepaid</p>
                <p>&#8377;5,806</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
