import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Home_Laptop_Version from "./Home_Laptop_Version";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { get_seller_earn } from "../../redux/order/OrderSlice";

const Main_Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(get_seller_earn());
  }, []);
  useEffect(() => {
    if (isAuth === false) {
      navigate("/login");
    } 
  }, [isAuth]);

  return (
    <>
      <Home_Laptop_Version />
      <Outlet />
    </>
  );
};

export default Main_Layout;
