import React from "react";
import sell2 from "../../assets/ewssell1.png";
import sell3 from "../../assets/ewssell2.png";
// import sell4 from "../../assets/ewssell2.png";
import Image from "../../assets/girl2.png";

const All_Sections = () => {

  return (
    <div className="hero-section">
      <div className="hero-section2">
        <div className="w-50 h-100 d-flex align-items-center mx-5">
          <h1 style={{ fontFamily: "Roboto" }}>
            Join the EWS family and <br />
            sell everything at 0% commission
          </h1>
        </div>
      </div>
      <div className="thousand">
        <div className="card mx-auto my-4 rounded-1 border-0">
          <div className="card-body d-flex flex-wrap justify-content-around align-items-center">
            <div className="stat-item text-center">
              <h4 style={{ color: "#149b4c" }}>20k+</h4>
              <p className="text-muted">EWS Customers</p>
            </div>
            <div className="divider"></div>
            <div className="stat-item text-center">
              <h4 style={{ color: "#149b4c" }}>24×7</h4>
              <p className="text-muted">Online Business</p>
            </div>
            <div className="divider"></div>
            <div className="stat-item text-center">
              <h4 style={{ color: "#149b4c" }}>7</h4>
              <p className="text-muted">Days* payment</p>
            </div>
            <div className="divider"></div>
            <div className="stat-item text-center">
              <h4 style={{ color: "#149b4c" }}>19k+</h4>
              <p className="text-muted">Pincodes served</p>
            </div>
          </div>
        </div>
      </div>

      <div className="second">
        <div className="row w-100 justify-content-center">
          <div className="col-lg-9 col-12">
            <div className=" ">
              {/* <h1 className="whydotext">
                Why do{" "}
                <span style={{ color: "#503CEB" }}>
                  sellers love selling on EWShopping?
                </span>
              </h1> */}
              <h4 className="accesstext">
                Why do{" "}
                <b style={{ color: "#027CD5", fontWeight: "500" }}>
                  {" "}
                  sellers love selling on EWShopping?
                </b>
              </h4>
            </div>
            <div className="desc">
              <p>
                EWShopping is the one-stop shopping destination for 20 Thousand+
                customers across India. Experience the rapid growth on
                EWShopping and join our community of successful sellers.
              </p>
            </div>
            <div className="commision d-flex flex-wrap  mt-2">
              <div>
                <div className="rowancard">
                  <div className="">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="cardcontainer"
                    >
                      <div className="inner w-100">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src="\assets\images\challenge_9601666.svg"
                            className="imgbox"
                            alt="opportunity"
                          />
                          <h6 className="fullfilmenttext">Opportunity</h6>
                        </div>
                        <div>
                          <p className="worriedtext">
                            Make your business profitable without having to pay
                            any commission on all the EWShopping exclusive
                            verticals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="cardcontainer">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="\assets\images\productivity_5965756.svg"
                          alt="Ease of Doing Business"
                          className="imgbox"
                        />
                        <h6 className="fullfilmenttext">
                          Ease of Doing Business
                        </h6>
                      </div>
                      <div>
                        <p className="worriedtext">
                          Create your EWShopping seller account in under 10
                          minutes with just 1 product and a valid GSTIN number.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="cardcontainer">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="/assets/images/Growth.png"
                          alt="growth"
                          className="imgbox"
                        />
                        <h6 className="fullfilmenttext">Growth</h6>
                      </div>
                      <div>
                        <p className="worriedtext">
                          Sellers see an average 2.8X spike in growth, 2.3X more
                          visibility, and up to 5X growth in The Big Billion
                          Days Sale.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="cardcontainer">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="\assets\images\management-service_11253192.svg"
                          alt="additional"
                          className="imgbox"
                        />
                        <h6 className="fullfilmenttext">Additional Support</h6>
                      </div>
                      <div>
                        <p className="worriedtext">
                          Account management services, exclusive training
                          programs, business insights, catalogue/photoshoot
                          support, and more.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-12 img">
            <div className="">
              <img
                src={Image}
                className="img-fluid"
                alt="shopping-girl"
                id="aunty"
              />
              <div
                style={{ position: "relative", bottom: "280px" }}
                className=""
              >
                <img
                  src="assets\gif\ZUiY.gif"
                  style={{ width: "200px" ,marginLeft:'20px'}}
                  alt="Animated GIF"
                  id="ani"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fourth ">
        <div className="four">
          <div className="">
            {/* <h1 className="whydotext">
            <span style={{ color: "#503CEB" }}>Your Journey</span> on EWShopping
          </h1> */}
            <h4 style={{ color: "#027CD5" }} className="accesstext">
              Your Journey{" "}
              <b style={{ color: "#000", fontWeight: "500" }}> on EWShopping</b>
            </h4>
          </div>
          <div className="">
            <p className="text-secondary1">
              20 Thousand+ sellers across India trust EWShopping to be their
              number 1 destination to sell products online and also a low cost
              of doing business platform. Get ready to grow your online business
              with limitless opportunities on EWShopping !
            </p>
          </div>

          <div className="fourthcardcontainer">
            <div className="fourthgrid">
              <div className="">
                <div className="card border-0 h-100">
                  <img src={sell2} alt="create" className="cardimg " />
                  <div className="card-text">
                    <h6>Create</h6>
                    <p className="text-secondary">
                      Register in just 10 mins with your contact details, GSTIN
                      & bank details.
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="card border-0 h-100">
                  <img src={sell3} alt="list" className="cardimg" />
                  <div className="card-text">
                    <h6>List</h6>
                    <p className="text-secondary">
                      List at least 1 Product to start selling on EWShopping!
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="card border-0 h-100">
                  <img src="/assets/images/order.png" alt="orders" className="cardimg" />
                  <div className="card-text">
                    <h6>Orders</h6>
                    <p className="text-secondary">
                      Receive orders from over 22 thousand+ EWShopping
                      customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="card border-0 h-100">
                  <img
                    src="/assets/images/shipment.png"
                    alt="shipment"
                    className="cardimg"
                  />
                  <div className="card-text">
                    <h6>Shipment</h6>
                    <p className="text-secondary">
                      EWShopping ensures stress free delivery of your products
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="card border-0 h-100">
                  <img
                    src="/assets/images/paymentsa.png"
                    alt="payment"
                    className="cardimg"
                  />
                  <div className="card-text">
                    <h6>Payment</h6>
                    <p className="text-secondary">
                      Receive payment 7 days* from the date of dispatch of your
                      order
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" d-flex justify-content-center ">
          <button className="btn btn-outline-primary btncontainer">
            Download Launch Kit
          </button>
        </div> */}
      </div>
      <div className="fityh">
        <div className="">
          <h4
            style={{ color: "#027CD5", fontWeight: "500" }}
            className="accesstext"
          >
            Access our tools to grow faster{" "}
            <b style={{ color: "#000", fontWeight: "500" }}> on EWShopping</b>
          </h4>
        </div>
        <div className="">
          <p className="text-secondary1">
            We understand that your online business may require additional
            support from time to time, and we've got you covered. With your
            EWShopping account, you gain access to a range of tools designed to
            help grow your online business.
          </p>
        </div>
        <div className="p-2">
          <div className="accesscontainer">
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="\assets\images\shpingseller.svg"
                    alt="fulfil"
                    className="imgbox"
                  />
                  <h6 className="fullfilmenttext">Fullfilment by EWShopping</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Worried about storing, packing, shipping, and delivering
                    your products? Let EWShopping do it all for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src="\assets\images\ads.svg" alt="ewsads" className="imgbox" />
                  <h6 className="fullfilmenttext">EWShopping Ads</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Curious how your products will stand out from your
                    competitors and gain maximum visibility?
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src="\assets\images\shopping.svg" alt="shopping" className="imgbox" />
                  <h6 className="fullfilmenttext">Shopping Festivals</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Get access to India’s biggest shopping festivals, The Big
                    Billion Day, Big Diwali Sale, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src="\assets\images\book.svg" alt="learning" className="imgbox" />
                  <h6 className="fullfilmenttext">Learning Center</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Personalised learning modules, exclusive webinars, tutorial
                    videos, and more to help sell better faster.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src="/assets/images/account.png"  alt="Account" className="imgbox" />
                  <h6 className="fullfilmenttext">Account Management</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Improve product selection, product pricing, business
                    insights, & more with our expert in-house account managers.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="cardcontainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="\assets\images\booking_2664669.svg"
                    alt="img"
                    className="imgbox"
                  />
                  <h6 className="fullfilmenttext">Mobile App</h6>
                </div>
                <div>
                  <p className="worriedtext">
                    Manage your online seller account 24x7 with EWS Seller Hub
                    App. Compatible with all Android devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All_Sections;
