import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryTagPost,
  CategoryTagImages,
  validateSlugUrl,
  removecatTagImage,
} from "../../redux/categoryTags/CategoryTagsSlice";
import { useToasts } from "react-toast-notifications";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { PostField } from "../../redux/fieldStatus/FieldStatusSlice";

const AddCategoryTags = () => {
  const { mobileimage, desktopimage, isCatTagmobileImageLoading } = useSelector(
    (store) => store.categorytag
  );

  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [messurementType, setmessurementType] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [messurementTypeError, setmessurementTypeError] = useState("");

  const { addToast } = useToasts();

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercat(superCatShow[0].name);
      setSupercatid(superCatShow[0]._id);
    }
    if (categoryShow.length === 1) {
      setCategory(categoryShow[0].name);
      setCategoryid(categoryShow[0]._id);
    }
    if (subcategoryShow.length === 1) {
      setSubcategory(categoryShow[0].name);
      setSubcategoryid(categoryShow[0]._id);
    }
  }, [superCatShow, categoryShow, subcategoryShow]);

  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" && desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }

      if (supercat === "Clothing") {
        if (messurementType === "") {
          setmessurementTypeError("Required");
        } else {
          setmessurementTypeError("");
        }
      }
    }
  }, [
    buttonPress,
    supercatid,
    checkslugUrl,
    mobileimage,
    desktopimage,
    categoryid,
    subcategoryid,
    messurementType,
  ]);

  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const typeofMessurements = [
    { name: "Roman Type" },
    { name: "Regular Type" },
    { name: "Inner Type" },
    { name: "Sleeper Type" },
    { name: "Years Type" },
    { name: "Onesize" },
    { name: "Pack" },
  ];

  const verifyslugurl = async (e) => {
    setcategroyError("");
    if (categoryid) {
      const value = e.target.value;
      setNames(value);
      setMetatitle(value);
      setMetakeyword(value);
      setMetadesc(value);
      let newSlugUrl = `${category}-${value}`;
      newSlugUrl = newSlugUrl
        .trim()
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
        .replace("--", "-")
        .replace("---", "-")
        .replace("----", "-")
        .replace("/", "-")
        .replace("//", "-")
        .replace("///", "-");
      const subcatSlug = newSlugUrl
        .trim()
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
        .replace("--", "-")
        .replace("---", "-")
        .replace("----", "-")
        .replace("/", "-")
        .replace("//", "-")
        .replace("///", "-");
      setSlugUrl(subcatSlug);

      if (subcatSlug !== "") {
        let responce = await dispatch(validateSlugUrl(subcatSlug));
        if (responce.payload.success) {
          setError("Category Tag alredy exist");
          setSlugUrlError("Required");
          setErrorcolor("red");
        } else if (subcatSlug.length <= 2) {
          setErrorcolor("red");
          setError("minimum 3 letters");
          setSlugUrlError("Required");
          setCheckSlugUrl("");
        } else if (subcatSlug.length >= 3) {
          setErrorcolor("");
          setError("");
          setSlugUrlError("");
          setCheckSlugUrl(subcatSlug);
        }
      }
    } else {
      setcategroyError("Required");
      setError("Please Select Category");
    }
  };

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    setSupercatid(value);
    const textValue = superCatShow.find(
      (indSup) => indSup._id === evt.target.value
    );
    if (textValue) {
      setSupercat(textValue.name);
    }
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    setCategoryid(value);
    const textValue = catbySupercat.find(
      (indCat) => indCat._id === evt.target.value
    );
    if (textValue) {
      setCategory(textValue.name);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" && desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }

    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (supercat === "Clothing") {
      if (messurementType === "") {
        setmessurementTypeError("Required");
      } else {
        setmessurementTypeError("");
      }
    }
    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      desktopimage !== "" &&
      mobileimage !== ""
    ) {
      if (supercat === "Clothing" && messurementType !== "") {
        const formData = {
          name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
          slugUrl: checkslugUrl,
          superCategory: supercat,
          superCategoryId: supercatid,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          metaTitle: metatitle,
          metaKeyword: metakeyword,
          metaDesc: metadesc,
          mobileImage: mobileimage,
          desktopImage: desktopimage,
          messurementType: messurementType,
        };
        const data = await dispatch(CategoryTagPost(formData));
        if (data.payload.success === true) {
          const categoryTag = data.payload.ctegoryTags;
          const finalfieldarray = [];
          const tag_formdata = {
            superCategoryId: supercatid,
            superCategory: supercat,
            category: category,
            categoryId: categoryid,
            SubCategory: subcategory,
            SubCategoryId: subcategoryid,
            CategoryTag: categoryTag.name,
            CategoryTagId: categoryTag._id,
            selectedFields: finalfieldarray,
          };
          console.log(tag_formdata, "tag_formdata");

          const field_data = await dispatch(PostField(tag_formdata));
          alert("Category tag Added");

          if (superCatShow.length > 1) {
            setSupercatid("");
            setSupercat("");
          }
          if (categoryShow.length > 1) {
            setCategory("");
            setCategoryid("");
          }
          if (subcategoryShow.length > 1) {
            setSubcategory("");
            setSubcategoryid("");
          }
          setSupercat("");
          setSupercatid("");
          setCategory("");
          setCategoryid("");
          setSubcategory("");
          setSubcategoryid("");
          setNames("");
          setSlugUrl("");
          setCheckSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setmessurementType("");
          setsupcategroyError("");
          setcategroyError("");
          setSubcategroyError("");
          setSlugUrlError("");
          setimageError("");
          setmessurementTypeError("");
          setbuttonPress(false);
          dispatch(removecatTagImage());
        } else {
          setbuttonLoading(false);
          setSlugUrlError("Required");
          alert("Sub-Category Not Added");

          //   appearance: "error",
          //   autoDismiss: true,
          //   autoDismissTimeout: 4000,
          //   placement: "bottom-right",
          //   style: { zIndex: 99999 },
          // });
        }
      } else {
        const formData = {
          name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
          slugUrl: checkslugUrl,
          superCategory: supercat,
          superCategoryId: supercatid,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          metaTitle: metatitle,
          metaKeyword: metakeyword,
          metaDesc: metadesc,
          mobileImage: mobileimage,
          desktopImage: desktopimage,
        };
        const data = await dispatch(CategoryTagPost(formData));

        if (data.payload.success === true) {
          // addToast("Sub-Category Added", {
          //   appearance: "success",
          //   autoDismiss: true,
          //   autoDismissTimeout: 2000,
          //   placement: "bottom-right",
          //   style: { zIndex: 99999 },
          // });
          const categoryTag = data.payload.ctegoryTags;
          const finalfieldarray = [];
          const tag_formdata = {
            superCategoryId: supercatid,
            superCategory: supercat,
            category: category,
            categoryId: categoryid,
            SubCategory: subcategory,
            SubCategoryId: subcategoryid,
            CategoryTag: categoryTag.name,
            CategoryTagId: categoryTag._id,
            selectedFields: finalfieldarray,
          };

          const field_data = await dispatch(PostField(tag_formdata));
          alert("Category tag Added");
          if (superCatShow.length > 1) {
            setSupercatid("");
            setSupercat("");
          }
          if (categoryShow.length > 1) {
            setCategory("");
            setCategory("");
          }
          if (subcategoryShow.length > 1) {
            setSubcategory("");
            setSubcategoryid("");
          }
          setNames("");
          setSlugUrl("");
          setCheckSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setsupcategroyError("");
          setSlugUrlError("");
          setimageError("");
          setbuttonPress(false);
          // dispatch(removecatTagImage());
        } else {
          setbuttonLoading(false);
          setSlugUrlError("Required");
          alert("Sub-Category Not Added");
          // addToast(`${data.payload.massage}`, {
          //   appearance: "error",
          //   autoDismiss: true,
          //   autoDismissTimeout: 4000,
          //   placement: "bottom-right",
          //   style: { zIndex: 99999 },
          // });
        }
      }
    } else {
      setbuttonLoading(false);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercatid
    );
    setCatbySupercat(catbysupercat);
  }, [supercatid, categoryShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    setSubcategoryid(value);
    const textValue = subCat.find(
      (indSubCat) => indSubCat._id === evt.target.value
    );
    if (textValue) {
      setSubcategory(textValue.name);
    }
  };
  const selectMessurement = (evt) => {
    const { value } = evt.target;
    setmessurementType(value);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const dispatch = useDispatch();

  const imagesChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(CategoryTagImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Category-Tag</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Super Category Name
                            </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Super Category{" "}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Super Category"
                              onChange={(e) => {
                                selectsuperCategory(e);
                              }}
                              value={supercatid}
                              // value={superCatShow._id}
                              name={superCatShow.name}
                              className={
                                supcategroyError !== "" ? "error_class" : ""
                              }
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {superCatShow.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={index._id}
                                  name={test.name}
                                  selected={supercatid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    {categoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Category Name
                            </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Category"
                              onChange={(e) => {
                                selectCategory(e);
                              }}
                              value={categoryid}
                              name={catbySupercat.name}
                              className={
                                categroyError !== "" ? "error_class" : ""
                              }
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {catbySupercat.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={index}
                                  name={test.name}
                                  selected={categoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <br />
                  <Row className="g-2  m-2 ">
                    {subcategoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                            htmlFor="fName"
                            className="text-sm font-medium text-default-900"
                            style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                          >
                            Sub Category
                          </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Sub Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label=" Select Sub Category"
                              onChange={(e) => {
                                selectSubCategory(e);
                              }}
                              // value={subCat._id}
                              value={subcategoryid}
                              name={subCat.name}
                              className={
                                subcategroyError !== "" ? "error_class" : ""
                              }
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {subCat.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={index}
                                  name={test.name}
                                  selected={subcategoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                    {supercat === "Clothing" && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                            htmlFor="fName"
                            className="text-sm font-medium text-default-900"
                            style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                          >
                            Messurement<span style={{ color: "red" }}>*</span>
                          </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Messurement
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Messurement"
                              style={{ height: "38px" }}
                              onChange={(e) => {
                                selectMessurement(e);
                              }}
                              value={messurementType}
                              // value={typeofMessurements.name}
                              name={typeofMessurements.name}
                              className={
                                messurementTypeError !== "" ? "error_class" : ""
                              }
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {typeofMessurements.map((test, index) => (
                                <MenuItem
                                  key={test.name}
                                  value={test.name}
                                  name={test.name}
                                  selected={messurementType === test.name}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Name *
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Name"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="text"
                            value={names}
                            onChange={(e) => verifyslugurl(e)}
                            className={slugUrlError !== "" ? "error_class" : ""}
                            // className={classes.textfield}
                          />
                          <p style={{ color: errorcolor }} className="mt-2">
                            {error}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Sub-Category Images</Form.Label>
                      <Form.Control
                        type="file"
                        className={imageError !== "" ? "error_class" : ""}
                        onChange={imagesChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 291px * 200px
                      </p>
                      <div>
                        {isCatTagmobileImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "#000066" }}
                        >
                          Meta Title *
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Title"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metatitle}
                            onChange={(e) => setMetatitle(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Meta Keyword *
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Keyword"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metakeyword}
                            onChange={(e) => setMetakeyword(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Meta Descriptrion *
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Descriptrion"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metadesc}
                            onChange={(e) => setMetadesc(e.target.value)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="card-footer ">
                  {buttonLoading ? (
                    <button
                      className="btn btn-primary"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Category Tag
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Category Tag
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategoryTags;
